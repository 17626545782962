//layout .l-{className}
html.js-menuOpened {
  height: 100%;
  overflow: hidden;
}

.l- {
  &header {
    position: fixed;
    // position: relative;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: $primary;
    box-shadow: 6px 6px 6px rgba(38, 10, 10, 0.3);
    // margin-bottom: 72px;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      z-index: 999;
      background-color: $primary;
      pointer-events: none;

      @include min-lg {
        background-color: transparent;
      }
    }

    &-layout {
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      @include min-lg{
        justify-content: space-between;
      }
    }

    &-logo {
      display: inline-block;
      position: relative;
      z-index: 1001;

      &-img {
        width: 120px;
        height: 42px;
      }
    }

    &-hamburger {
      width: 30px;
      height: 18px;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 1rem;
      z-index: 1001;

      @include min-lg {
        display: none;
      }

      &-bar {
        width: 100%;
        height: 1px;
        background-color: $white;
        position: absolute;
        transition: top 0.3s ease-out, transform 0.3s ease-out,
          opacity 0.3s ease-out;

        &__top {
          top: 0;
          left: 0;
        }

        &__middle {
          left: 0;
          top: 50%;
        }

        &__bottom {
          left: 0;
          top: 100%;
        }
      }

      &.js-menuOpened {
        .l-header-hamburger-bar {
          &__top {
            top: 50%;
            transform: rotate(45deg);
          }

          &__middle {
            opacity: 0;
          }

          &__bottom {
            top: 50%;
            transform: rotate(-45deg);
          }
        }
      }
    }

    &-menu {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $third;
      padding: 90px 0 15px 0;
      // overflow-x: hidden;
      // overflow-y: scroll;
      overflow: hidden;
      z-index: 990;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

      &.js-menuOpened {
        opacity: 1;
        visibility: visible;
      }

      @include min-lg {
        position: static;
        //   width: auto;
        padding: 0;
        background-color: transparent;
        overflow: visible;
        opacity: 1;
        visibility: visible;
      }

      &-container {
        padding: 0 15px;
      }

      &-layout {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 0;
        //   justify-content: center;
        justify-content: space-between;

        @include min-lg {
          flex-direction: row;
        }
      }

      &-item {}

      &-link {
        //   justify-content: space-between;
        align-items: center;
        color: $primary;
        text-decoration: none;
        position: relative;
        line-height: 36px;
        font-size: $font-20;
        padding: 1rem 0;

        @include min-lg {
          padding: 0;
        }

        &:hover {
          text-decoration: none;
          color: $primary;
          opacity: 0.8;
        }

        @include min-lg {
          line-height: 72px;
          border-bottom: none;
          color: $white;

          &:hover {
            color: $white;
          }
        }

        i {
          width: 20px;
          text-align: center;
        }

        &__hover {
          &--underline {
            display: flex;

            @include min-lg {
              margin: 0 1rem;
              display: inline-flex;

              &:before {
                content: "";
                display: block;
                width: 0;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $white;
                transition: all 0.25s ease-in-out;
                bottom: 25%;
              }

              &:hover,
              &:focus {
                &:before {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    &-icon-bottle {
      width: 20px;
    }

    &-goTop {
      position: fixed;
      bottom: 2%;
      right: 1rem;
      width: 40px;
      // display: none;
      opacity: 0;
      transition: all 0.3s;

      &.js-show {
        // display: block;
        opacity: 1;
      }
    }
  }
}