@charset "UTF-8";
@keyframes fireWork {
  0% {
    transform: translate(-50%, -50%) scale(0); }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1; }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0; } }

.u-bg-white {
  background-color: #fff; }

.u-bg-black {
  background-color: #000; }

.u-bg-gray-100 {
  background-color: #f5f5f5; }

.u-bg-gray-200 {
  background-color: #eeeeee; }

.u-bg-gray-300 {
  background-color: #e0e0e0; }

.u-bg-gray-400 {
  background-color: #bdbdbd; }

.u-bg-gray-500 {
  background-color: #9e9e9e; }

.u-bg-gray-600 {
  background-color: #757575; }

.u-bg-gray-700 {
  background-color: #616161; }

.u-bg-gray-800 {
  background-color: #424242; }

.u-bg-gray-900 {
  background-color: #212121; }

.u-bg-primary {
  background-color: #821115; }

.u-bg-third {
  background-color: #FFF1DF; }

.u-font-14 {
  font-size: 0.875rem; }

.u-font-16 {
  font-size: 1rem; }

.u-font-18 {
  font-size: 1.125rem; }

.u-font-20 {
  font-size: 1.25rem; }

.u-font-22 {
  font-size: 1.375rem; }

.u-font-24 {
  font-size: 1.5rem; }

.u-font-26 {
  font-size: 1.625rem; }

.u-font-28 {
  font-size: 1.75rem; }

.u-font-30 {
  font-size: 1.875rem; }

.u-font-32 {
  font-size: 2rem; }

.u-font-34 {
  font-size: 2.125rem; }

.u-font-36 {
  font-size: 2.25rem; }

.u-font-38 {
  font-size: 2.375rem; }

.u-font-40 {
  font-size: 2.5rem; }

.u-font-42 {
  font-size: 2.625rem; }

.u-font-44 {
  font-size: 2.75rem; }

.u-font-46 {
  font-size: 2.875rem; }

.u-font-48 {
  font-size: 3rem; }

.u-font-50 {
  font-size: 3.125rem; }

.u-font-52 {
  font-size: 3.25rem; }

.u-text-white {
  color: #fff; }

.u-text-black {
  color: #000; }

.u-text-gray-100 {
  color: #f5f5f5; }

.u-text-gray-200 {
  color: #eeeeee; }

.u-text-gray-300 {
  color: #e0e0e0; }

.u-text-gray-400 {
  color: #bdbdbd; }

.u-text-gray-500 {
  color: #9e9e9e; }

.u-text-gray-600 {
  color: #757575; }

.u-text-gray-700 {
  color: #616161; }

.u-text-gray-800 {
  color: #424242; }

.u-text-gray-900 {
  color: #212121; }

.u-text-primary {
  color: #821115; }

.u-text-secondary {
  color: #961117; }

.u-text-gold01 {
  color: #F7E3B8; }

.u-line-height-10 {
  line-height: 1; }

.u-line-height-11 {
  line-height: 1.1; }

.u-line-height-12 {
  line-height: 1.2; }

.u-line-height-13 {
  line-height: 1.3; }

.u-line-height-14 {
  line-height: 1.4; }

.u-line-height-15 {
  line-height: 1.5; }

.u-line-height-16 {
  line-height: 1.6; }

.u-line-height-17 {
  line-height: 1.7; }

.u-line-height-18 {
  line-height: 1.8; }

.u-line-height-19 {
  line-height: 1.9; }

.u-line-height-20 {
  line-height: 2; }

.u-font-weight-100 {
  font-weight: 100; }

.u-font-weight-200 {
  font-weight: 200; }

.u-font-weight-300 {
  font-weight: 300; }

.u-font-weight-400 {
  font-weight: 400; }

.u-font-weight-500 {
  font-weight: 500; }

.u-font-weight-600 {
  font-weight: 600; }

.u-font-weight-700 {
  font-weight: 700; }

.u-font-weight-800 {
  font-weight: 800; }

.u-font-weight-900 {
  font-weight: 900; }

.u-m-000 {
  margin: 0rem !important; }

.u-mt-000,
.u-my-000 {
  margin-top: 0rem !important; }

.u-mr-000,
.u-mx-000 {
  margin-right: 0rem !important; }

.u-mb-000,
.u-my-000 {
  margin-bottom: 0rem !important; }

.u-ml-000,
.u-mx-000 {
  margin-left: 0rem !important; }

.u-m-025 {
  margin: 0.25rem !important; }

.u-mt-025,
.u-my-025 {
  margin-top: 0.25rem !important; }

.u-mr-025,
.u-mx-025 {
  margin-right: 0.25rem !important; }

.u-mb-025,
.u-my-025 {
  margin-bottom: 0.25rem !important; }

.u-ml-025,
.u-mx-025 {
  margin-left: 0.25rem !important; }

.u-m-050 {
  margin: 0.5rem !important; }

.u-mt-050,
.u-my-050 {
  margin-top: 0.5rem !important; }

.u-mr-050,
.u-mx-050 {
  margin-right: 0.5rem !important; }

.u-mb-050,
.u-my-050 {
  margin-bottom: 0.5rem !important; }

.u-ml-050,
.u-mx-050 {
  margin-left: 0.5rem !important; }

.u-m-075 {
  margin: 0.75rem !important; }

.u-mt-075,
.u-my-075 {
  margin-top: 0.75rem !important; }

.u-mr-075,
.u-mx-075 {
  margin-right: 0.75rem !important; }

.u-mb-075,
.u-my-075 {
  margin-bottom: 0.75rem !important; }

.u-ml-075,
.u-mx-075 {
  margin-left: 0.75rem !important; }

.u-m-100 {
  margin: 1rem !important; }

.u-mt-100,
.u-my-100 {
  margin-top: 1rem !important; }

.u-mr-100,
.u-mx-100 {
  margin-right: 1rem !important; }

.u-mb-100,
.u-my-100 {
  margin-bottom: 1rem !important; }

.u-ml-100,
.u-mx-100 {
  margin-left: 1rem !important; }

.u-m-125 {
  margin: 1.25rem !important; }

.u-mt-125,
.u-my-125 {
  margin-top: 1.25rem !important; }

.u-mr-125,
.u-mx-125 {
  margin-right: 1.25rem !important; }

.u-mb-125,
.u-my-125 {
  margin-bottom: 1.25rem !important; }

.u-ml-125,
.u-mx-125 {
  margin-left: 1.25rem !important; }

.u-m-150 {
  margin: 1.5rem !important; }

.u-mt-150,
.u-my-150 {
  margin-top: 1.5rem !important; }

.u-mr-150,
.u-mx-150 {
  margin-right: 1.5rem !important; }

.u-mb-150,
.u-my-150 {
  margin-bottom: 1.5rem !important; }

.u-ml-150,
.u-mx-150 {
  margin-left: 1.5rem !important; }

.u-m-175 {
  margin: 1.75rem !important; }

.u-mt-175,
.u-my-175 {
  margin-top: 1.75rem !important; }

.u-mr-175,
.u-mx-175 {
  margin-right: 1.75rem !important; }

.u-mb-175,
.u-my-175 {
  margin-bottom: 1.75rem !important; }

.u-ml-175,
.u-mx-175 {
  margin-left: 1.75rem !important; }

.u-m-200 {
  margin: 2rem !important; }

.u-mt-200,
.u-my-200 {
  margin-top: 2rem !important; }

.u-mr-200,
.u-mx-200 {
  margin-right: 2rem !important; }

.u-mb-200,
.u-my-200 {
  margin-bottom: 2rem !important; }

.u-ml-200,
.u-mx-200 {
  margin-left: 2rem !important; }

.u-m-225 {
  margin: 2.25rem !important; }

.u-mt-225,
.u-my-225 {
  margin-top: 2.25rem !important; }

.u-mr-225,
.u-mx-225 {
  margin-right: 2.25rem !important; }

.u-mb-225,
.u-my-225 {
  margin-bottom: 2.25rem !important; }

.u-ml-225,
.u-mx-225 {
  margin-left: 2.25rem !important; }

.u-m-250 {
  margin: 2.5rem !important; }

.u-mt-250,
.u-my-250 {
  margin-top: 2.5rem !important; }

.u-mr-250,
.u-mx-250 {
  margin-right: 2.5rem !important; }

.u-mb-250,
.u-my-250 {
  margin-bottom: 2.5rem !important; }

.u-ml-250,
.u-mx-250 {
  margin-left: 2.5rem !important; }

.u-m-275 {
  margin: 2.75rem !important; }

.u-mt-275,
.u-my-275 {
  margin-top: 2.75rem !important; }

.u-mr-275,
.u-mx-275 {
  margin-right: 2.75rem !important; }

.u-mb-275,
.u-my-275 {
  margin-bottom: 2.75rem !important; }

.u-ml-275,
.u-mx-275 {
  margin-left: 2.75rem !important; }

.u-m-300 {
  margin: 3rem !important; }

.u-mt-300,
.u-my-300 {
  margin-top: 3rem !important; }

.u-mr-300,
.u-mx-300 {
  margin-right: 3rem !important; }

.u-mb-300,
.u-my-300 {
  margin-bottom: 3rem !important; }

.u-ml-300,
.u-mx-300 {
  margin-left: 3rem !important; }

.u-m-325 {
  margin: 3.25rem !important; }

.u-mt-325,
.u-my-325 {
  margin-top: 3.25rem !important; }

.u-mr-325,
.u-mx-325 {
  margin-right: 3.25rem !important; }

.u-mb-325,
.u-my-325 {
  margin-bottom: 3.25rem !important; }

.u-ml-325,
.u-mx-325 {
  margin-left: 3.25rem !important; }

.u-m-350 {
  margin: 3.5rem !important; }

.u-mt-350,
.u-my-350 {
  margin-top: 3.5rem !important; }

.u-mr-350,
.u-mx-350 {
  margin-right: 3.5rem !important; }

.u-mb-350,
.u-my-350 {
  margin-bottom: 3.5rem !important; }

.u-ml-350,
.u-mx-350 {
  margin-left: 3.5rem !important; }

.u-m-375 {
  margin: 3.75rem !important; }

.u-mt-375,
.u-my-375 {
  margin-top: 3.75rem !important; }

.u-mr-375,
.u-mx-375 {
  margin-right: 3.75rem !important; }

.u-mb-375,
.u-my-375 {
  margin-bottom: 3.75rem !important; }

.u-ml-375,
.u-mx-375 {
  margin-left: 3.75rem !important; }

.u-m-400 {
  margin: 4rem !important; }

.u-mt-400,
.u-my-400 {
  margin-top: 4rem !important; }

.u-mr-400,
.u-mx-400 {
  margin-right: 4rem !important; }

.u-mb-400,
.u-my-400 {
  margin-bottom: 4rem !important; }

.u-ml-400,
.u-mx-400 {
  margin-left: 4rem !important; }

.u-m-425 {
  margin: 4.25rem !important; }

.u-mt-425,
.u-my-425 {
  margin-top: 4.25rem !important; }

.u-mr-425,
.u-mx-425 {
  margin-right: 4.25rem !important; }

.u-mb-425,
.u-my-425 {
  margin-bottom: 4.25rem !important; }

.u-ml-425,
.u-mx-425 {
  margin-left: 4.25rem !important; }

.u-m-450 {
  margin: 4.5rem !important; }

.u-mt-450,
.u-my-450 {
  margin-top: 4.5rem !important; }

.u-mr-450,
.u-mx-450 {
  margin-right: 4.5rem !important; }

.u-mb-450,
.u-my-450 {
  margin-bottom: 4.5rem !important; }

.u-ml-450,
.u-mx-450 {
  margin-left: 4.5rem !important; }

.u-m-475 {
  margin: 4.75rem !important; }

.u-mt-475,
.u-my-475 {
  margin-top: 4.75rem !important; }

.u-mr-475,
.u-mx-475 {
  margin-right: 4.75rem !important; }

.u-mb-475,
.u-my-475 {
  margin-bottom: 4.75rem !important; }

.u-ml-475,
.u-mx-475 {
  margin-left: 4.75rem !important; }

.u-m-500 {
  margin: 5rem !important; }

.u-mt-500,
.u-my-500 {
  margin-top: 5rem !important; }

.u-mr-500,
.u-mx-500 {
  margin-right: 5rem !important; }

.u-mb-500,
.u-my-500 {
  margin-bottom: 5rem !important; }

.u-ml-500,
.u-mx-500 {
  margin-left: 5rem !important; }

.u-m-525 {
  margin: 5.25rem !important; }

.u-mt-525,
.u-my-525 {
  margin-top: 5.25rem !important; }

.u-mr-525,
.u-mx-525 {
  margin-right: 5.25rem !important; }

.u-mb-525,
.u-my-525 {
  margin-bottom: 5.25rem !important; }

.u-ml-525,
.u-mx-525 {
  margin-left: 5.25rem !important; }

.u-m-550 {
  margin: 5.5rem !important; }

.u-mt-550,
.u-my-550 {
  margin-top: 5.5rem !important; }

.u-mr-550,
.u-mx-550 {
  margin-right: 5.5rem !important; }

.u-mb-550,
.u-my-550 {
  margin-bottom: 5.5rem !important; }

.u-ml-550,
.u-mx-550 {
  margin-left: 5.5rem !important; }

.u-m-575 {
  margin: 5.75rem !important; }

.u-mt-575,
.u-my-575 {
  margin-top: 5.75rem !important; }

.u-mr-575,
.u-mx-575 {
  margin-right: 5.75rem !important; }

.u-mb-575,
.u-my-575 {
  margin-bottom: 5.75rem !important; }

.u-ml-575,
.u-mx-575 {
  margin-left: 5.75rem !important; }

.u-m-600 {
  margin: 6rem !important; }

.u-mt-600,
.u-my-600 {
  margin-top: 6rem !important; }

.u-mr-600,
.u-mx-600 {
  margin-right: 6rem !important; }

.u-mb-600,
.u-my-600 {
  margin-bottom: 6rem !important; }

.u-ml-600,
.u-mx-600 {
  margin-left: 6rem !important; }

.u-m-625 {
  margin: 6.25rem !important; }

.u-mt-625,
.u-my-625 {
  margin-top: 6.25rem !important; }

.u-mr-625,
.u-mx-625 {
  margin-right: 6.25rem !important; }

.u-mb-625,
.u-my-625 {
  margin-bottom: 6.25rem !important; }

.u-ml-625,
.u-mx-625 {
  margin-left: 6.25rem !important; }

.u-m-650 {
  margin: 6.5rem !important; }

.u-mt-650,
.u-my-650 {
  margin-top: 6.5rem !important; }

.u-mr-650,
.u-mx-650 {
  margin-right: 6.5rem !important; }

.u-mb-650,
.u-my-650 {
  margin-bottom: 6.5rem !important; }

.u-ml-650,
.u-mx-650 {
  margin-left: 6.5rem !important; }

.u-m-675 {
  margin: 6.75rem !important; }

.u-mt-675,
.u-my-675 {
  margin-top: 6.75rem !important; }

.u-mr-675,
.u-mx-675 {
  margin-right: 6.75rem !important; }

.u-mb-675,
.u-my-675 {
  margin-bottom: 6.75rem !important; }

.u-ml-675,
.u-mx-675 {
  margin-left: 6.75rem !important; }

.u-m-700 {
  margin: 7rem !important; }

.u-mt-700,
.u-my-700 {
  margin-top: 7rem !important; }

.u-mr-700,
.u-mx-700 {
  margin-right: 7rem !important; }

.u-mb-700,
.u-my-700 {
  margin-bottom: 7rem !important; }

.u-ml-700,
.u-mx-700 {
  margin-left: 7rem !important; }

.u-m-725 {
  margin: 7.25rem !important; }

.u-mt-725,
.u-my-725 {
  margin-top: 7.25rem !important; }

.u-mr-725,
.u-mx-725 {
  margin-right: 7.25rem !important; }

.u-mb-725,
.u-my-725 {
  margin-bottom: 7.25rem !important; }

.u-ml-725,
.u-mx-725 {
  margin-left: 7.25rem !important; }

.u-m-750 {
  margin: 7.5rem !important; }

.u-mt-750,
.u-my-750 {
  margin-top: 7.5rem !important; }

.u-mr-750,
.u-mx-750 {
  margin-right: 7.5rem !important; }

.u-mb-750,
.u-my-750 {
  margin-bottom: 7.5rem !important; }

.u-ml-750,
.u-mx-750 {
  margin-left: 7.5rem !important; }

.u-m-775 {
  margin: 7.75rem !important; }

.u-mt-775,
.u-my-775 {
  margin-top: 7.75rem !important; }

.u-mr-775,
.u-mx-775 {
  margin-right: 7.75rem !important; }

.u-mb-775,
.u-my-775 {
  margin-bottom: 7.75rem !important; }

.u-ml-775,
.u-mx-775 {
  margin-left: 7.75rem !important; }

.u-m-800 {
  margin: 8rem !important; }

.u-mt-800,
.u-my-800 {
  margin-top: 8rem !important; }

.u-mr-800,
.u-mx-800 {
  margin-right: 8rem !important; }

.u-mb-800,
.u-my-800 {
  margin-bottom: 8rem !important; }

.u-ml-800,
.u-mx-800 {
  margin-left: 8rem !important; }

.u-p-000 {
  padding: 0rem !important; }

.u-pt-000,
.u-py-000 {
  padding-top: 0rem !important; }

.u-pr-000,
.u-px-000 {
  padding-right: 0rem !important; }

.u-pb-000,
.u-py-000 {
  padding-bottom: 0rem !important; }

.u-pl-000,
.u-px-000 {
  padding-left: 0rem !important; }

.u-p-025 {
  padding: 0.25rem !important; }

.u-pt-025,
.u-py-025 {
  padding-top: 0.25rem !important; }

.u-pr-025,
.u-px-025 {
  padding-right: 0.25rem !important; }

.u-pb-025,
.u-py-025 {
  padding-bottom: 0.25rem !important; }

.u-pl-025,
.u-px-025 {
  padding-left: 0.25rem !important; }

.u-p-050 {
  padding: 0.5rem !important; }

.u-pt-050,
.u-py-050 {
  padding-top: 0.5rem !important; }

.u-pr-050,
.u-px-050 {
  padding-right: 0.5rem !important; }

.u-pb-050,
.u-py-050 {
  padding-bottom: 0.5rem !important; }

.u-pl-050,
.u-px-050 {
  padding-left: 0.5rem !important; }

.u-p-075 {
  padding: 0.75rem !important; }

.u-pt-075,
.u-py-075 {
  padding-top: 0.75rem !important; }

.u-pr-075,
.u-px-075 {
  padding-right: 0.75rem !important; }

.u-pb-075,
.u-py-075 {
  padding-bottom: 0.75rem !important; }

.u-pl-075,
.u-px-075 {
  padding-left: 0.75rem !important; }

.u-p-100 {
  padding: 1rem !important; }

.u-pt-100,
.u-py-100 {
  padding-top: 1rem !important; }

.u-pr-100,
.u-px-100 {
  padding-right: 1rem !important; }

.u-pb-100,
.u-py-100 {
  padding-bottom: 1rem !important; }

.u-pl-100,
.u-px-100 {
  padding-left: 1rem !important; }

.u-p-125 {
  padding: 1.25rem !important; }

.u-pt-125,
.u-py-125 {
  padding-top: 1.25rem !important; }

.u-pr-125,
.u-px-125 {
  padding-right: 1.25rem !important; }

.u-pb-125,
.u-py-125 {
  padding-bottom: 1.25rem !important; }

.u-pl-125,
.u-px-125 {
  padding-left: 1.25rem !important; }

.u-p-150 {
  padding: 1.5rem !important; }

.u-pt-150,
.u-py-150 {
  padding-top: 1.5rem !important; }

.u-pr-150,
.u-px-150 {
  padding-right: 1.5rem !important; }

.u-pb-150,
.u-py-150 {
  padding-bottom: 1.5rem !important; }

.u-pl-150,
.u-px-150 {
  padding-left: 1.5rem !important; }

.u-p-175 {
  padding: 1.75rem !important; }

.u-pt-175,
.u-py-175 {
  padding-top: 1.75rem !important; }

.u-pr-175,
.u-px-175 {
  padding-right: 1.75rem !important; }

.u-pb-175,
.u-py-175 {
  padding-bottom: 1.75rem !important; }

.u-pl-175,
.u-px-175 {
  padding-left: 1.75rem !important; }

.u-p-200 {
  padding: 2rem !important; }

.u-pt-200,
.u-py-200 {
  padding-top: 2rem !important; }

.u-pr-200,
.u-px-200 {
  padding-right: 2rem !important; }

.u-pb-200,
.u-py-200 {
  padding-bottom: 2rem !important; }

.u-pl-200,
.u-px-200 {
  padding-left: 2rem !important; }

.u-p-225 {
  padding: 2.25rem !important; }

.u-pt-225,
.u-py-225 {
  padding-top: 2.25rem !important; }

.u-pr-225,
.u-px-225 {
  padding-right: 2.25rem !important; }

.u-pb-225,
.u-py-225 {
  padding-bottom: 2.25rem !important; }

.u-pl-225,
.u-px-225 {
  padding-left: 2.25rem !important; }

.u-p-250 {
  padding: 2.5rem !important; }

.u-pt-250,
.u-py-250 {
  padding-top: 2.5rem !important; }

.u-pr-250,
.u-px-250 {
  padding-right: 2.5rem !important; }

.u-pb-250,
.u-py-250 {
  padding-bottom: 2.5rem !important; }

.u-pl-250,
.u-px-250 {
  padding-left: 2.5rem !important; }

.u-p-275 {
  padding: 2.75rem !important; }

.u-pt-275,
.u-py-275 {
  padding-top: 2.75rem !important; }

.u-pr-275,
.u-px-275 {
  padding-right: 2.75rem !important; }

.u-pb-275,
.u-py-275 {
  padding-bottom: 2.75rem !important; }

.u-pl-275,
.u-px-275 {
  padding-left: 2.75rem !important; }

.u-p-300 {
  padding: 3rem !important; }

.u-pt-300,
.u-py-300 {
  padding-top: 3rem !important; }

.u-pr-300,
.u-px-300 {
  padding-right: 3rem !important; }

.u-pb-300,
.u-py-300 {
  padding-bottom: 3rem !important; }

.u-pl-300,
.u-px-300 {
  padding-left: 3rem !important; }

.u-p-325 {
  padding: 3.25rem !important; }

.u-pt-325,
.u-py-325 {
  padding-top: 3.25rem !important; }

.u-pr-325,
.u-px-325 {
  padding-right: 3.25rem !important; }

.u-pb-325,
.u-py-325 {
  padding-bottom: 3.25rem !important; }

.u-pl-325,
.u-px-325 {
  padding-left: 3.25rem !important; }

.u-p-350 {
  padding: 3.5rem !important; }

.u-pt-350,
.u-py-350 {
  padding-top: 3.5rem !important; }

.u-pr-350,
.u-px-350 {
  padding-right: 3.5rem !important; }

.u-pb-350,
.u-py-350 {
  padding-bottom: 3.5rem !important; }

.u-pl-350,
.u-px-350 {
  padding-left: 3.5rem !important; }

.u-p-375 {
  padding: 3.75rem !important; }

.u-pt-375,
.u-py-375 {
  padding-top: 3.75rem !important; }

.u-pr-375,
.u-px-375 {
  padding-right: 3.75rem !important; }

.u-pb-375,
.u-py-375 {
  padding-bottom: 3.75rem !important; }

.u-pl-375,
.u-px-375 {
  padding-left: 3.75rem !important; }

.u-p-400 {
  padding: 4rem !important; }

.u-pt-400,
.u-py-400 {
  padding-top: 4rem !important; }

.u-pr-400,
.u-px-400 {
  padding-right: 4rem !important; }

.u-pb-400,
.u-py-400 {
  padding-bottom: 4rem !important; }

.u-pl-400,
.u-px-400 {
  padding-left: 4rem !important; }

.u-p-425 {
  padding: 4.25rem !important; }

.u-pt-425,
.u-py-425 {
  padding-top: 4.25rem !important; }

.u-pr-425,
.u-px-425 {
  padding-right: 4.25rem !important; }

.u-pb-425,
.u-py-425 {
  padding-bottom: 4.25rem !important; }

.u-pl-425,
.u-px-425 {
  padding-left: 4.25rem !important; }

.u-p-450 {
  padding: 4.5rem !important; }

.u-pt-450,
.u-py-450 {
  padding-top: 4.5rem !important; }

.u-pr-450,
.u-px-450 {
  padding-right: 4.5rem !important; }

.u-pb-450,
.u-py-450 {
  padding-bottom: 4.5rem !important; }

.u-pl-450,
.u-px-450 {
  padding-left: 4.5rem !important; }

.u-p-475 {
  padding: 4.75rem !important; }

.u-pt-475,
.u-py-475 {
  padding-top: 4.75rem !important; }

.u-pr-475,
.u-px-475 {
  padding-right: 4.75rem !important; }

.u-pb-475,
.u-py-475 {
  padding-bottom: 4.75rem !important; }

.u-pl-475,
.u-px-475 {
  padding-left: 4.75rem !important; }

.u-p-500 {
  padding: 5rem !important; }

.u-pt-500,
.u-py-500 {
  padding-top: 5rem !important; }

.u-pr-500,
.u-px-500 {
  padding-right: 5rem !important; }

.u-pb-500,
.u-py-500 {
  padding-bottom: 5rem !important; }

.u-pl-500,
.u-px-500 {
  padding-left: 5rem !important; }

.u-p-525 {
  padding: 5.25rem !important; }

.u-pt-525,
.u-py-525 {
  padding-top: 5.25rem !important; }

.u-pr-525,
.u-px-525 {
  padding-right: 5.25rem !important; }

.u-pb-525,
.u-py-525 {
  padding-bottom: 5.25rem !important; }

.u-pl-525,
.u-px-525 {
  padding-left: 5.25rem !important; }

.u-p-550 {
  padding: 5.5rem !important; }

.u-pt-550,
.u-py-550 {
  padding-top: 5.5rem !important; }

.u-pr-550,
.u-px-550 {
  padding-right: 5.5rem !important; }

.u-pb-550,
.u-py-550 {
  padding-bottom: 5.5rem !important; }

.u-pl-550,
.u-px-550 {
  padding-left: 5.5rem !important; }

.u-p-575 {
  padding: 5.75rem !important; }

.u-pt-575,
.u-py-575 {
  padding-top: 5.75rem !important; }

.u-pr-575,
.u-px-575 {
  padding-right: 5.75rem !important; }

.u-pb-575,
.u-py-575 {
  padding-bottom: 5.75rem !important; }

.u-pl-575,
.u-px-575 {
  padding-left: 5.75rem !important; }

.u-p-600 {
  padding: 6rem !important; }

.u-pt-600,
.u-py-600 {
  padding-top: 6rem !important; }

.u-pr-600,
.u-px-600 {
  padding-right: 6rem !important; }

.u-pb-600,
.u-py-600 {
  padding-bottom: 6rem !important; }

.u-pl-600,
.u-px-600 {
  padding-left: 6rem !important; }

.u-p-625 {
  padding: 6.25rem !important; }

.u-pt-625,
.u-py-625 {
  padding-top: 6.25rem !important; }

.u-pr-625,
.u-px-625 {
  padding-right: 6.25rem !important; }

.u-pb-625,
.u-py-625 {
  padding-bottom: 6.25rem !important; }

.u-pl-625,
.u-px-625 {
  padding-left: 6.25rem !important; }

.u-p-650 {
  padding: 6.5rem !important; }

.u-pt-650,
.u-py-650 {
  padding-top: 6.5rem !important; }

.u-pr-650,
.u-px-650 {
  padding-right: 6.5rem !important; }

.u-pb-650,
.u-py-650 {
  padding-bottom: 6.5rem !important; }

.u-pl-650,
.u-px-650 {
  padding-left: 6.5rem !important; }

.u-p-675 {
  padding: 6.75rem !important; }

.u-pt-675,
.u-py-675 {
  padding-top: 6.75rem !important; }

.u-pr-675,
.u-px-675 {
  padding-right: 6.75rem !important; }

.u-pb-675,
.u-py-675 {
  padding-bottom: 6.75rem !important; }

.u-pl-675,
.u-px-675 {
  padding-left: 6.75rem !important; }

.u-p-700 {
  padding: 7rem !important; }

.u-pt-700,
.u-py-700 {
  padding-top: 7rem !important; }

.u-pr-700,
.u-px-700 {
  padding-right: 7rem !important; }

.u-pb-700,
.u-py-700 {
  padding-bottom: 7rem !important; }

.u-pl-700,
.u-px-700 {
  padding-left: 7rem !important; }

.u-p-725 {
  padding: 7.25rem !important; }

.u-pt-725,
.u-py-725 {
  padding-top: 7.25rem !important; }

.u-pr-725,
.u-px-725 {
  padding-right: 7.25rem !important; }

.u-pb-725,
.u-py-725 {
  padding-bottom: 7.25rem !important; }

.u-pl-725,
.u-px-725 {
  padding-left: 7.25rem !important; }

.u-p-750 {
  padding: 7.5rem !important; }

.u-pt-750,
.u-py-750 {
  padding-top: 7.5rem !important; }

.u-pr-750,
.u-px-750 {
  padding-right: 7.5rem !important; }

.u-pb-750,
.u-py-750 {
  padding-bottom: 7.5rem !important; }

.u-pl-750,
.u-px-750 {
  padding-left: 7.5rem !important; }

.u-p-775 {
  padding: 7.75rem !important; }

.u-pt-775,
.u-py-775 {
  padding-top: 7.75rem !important; }

.u-pr-775,
.u-px-775 {
  padding-right: 7.75rem !important; }

.u-pb-775,
.u-py-775 {
  padding-bottom: 7.75rem !important; }

.u-pl-775,
.u-px-775 {
  padding-left: 7.75rem !important; }

.u-p-800 {
  padding: 8rem !important; }

.u-pt-800,
.u-py-800 {
  padding-top: 8rem !important; }

.u-pr-800,
.u-px-800 {
  padding-right: 8rem !important; }

.u-pb-800,
.u-py-800 {
  padding-bottom: 8rem !important; }

.u-pl-800,
.u-px-800 {
  padding-left: 8rem !important; }

@media (min-width: 576px) {
  .u-m-sm-000 {
    margin: 0rem !important; }
  .u-mt-sm-000,
  .u-my-sm-000 {
    margin-top: 0rem !important; }
  .u-mr-sm-000,
  .u-mx-sm-000 {
    margin-right: 0rem !important; }
  .u-mb-sm-000,
  .u-my-sm-000 {
    margin-bottom: 0rem !important; }
  .u-ml-sm-000,
  .u-mx-sm-000 {
    margin-left: 0rem !important; }
  .u-m-sm-025 {
    margin: 0.25rem !important; }
  .u-mt-sm-025,
  .u-my-sm-025 {
    margin-top: 0.25rem !important; }
  .u-mr-sm-025,
  .u-mx-sm-025 {
    margin-right: 0.25rem !important; }
  .u-mb-sm-025,
  .u-my-sm-025 {
    margin-bottom: 0.25rem !important; }
  .u-ml-sm-025,
  .u-mx-sm-025 {
    margin-left: 0.25rem !important; }
  .u-m-sm-050 {
    margin: 0.5rem !important; }
  .u-mt-sm-050,
  .u-my-sm-050 {
    margin-top: 0.5rem !important; }
  .u-mr-sm-050,
  .u-mx-sm-050 {
    margin-right: 0.5rem !important; }
  .u-mb-sm-050,
  .u-my-sm-050 {
    margin-bottom: 0.5rem !important; }
  .u-ml-sm-050,
  .u-mx-sm-050 {
    margin-left: 0.5rem !important; }
  .u-m-sm-075 {
    margin: 0.75rem !important; }
  .u-mt-sm-075,
  .u-my-sm-075 {
    margin-top: 0.75rem !important; }
  .u-mr-sm-075,
  .u-mx-sm-075 {
    margin-right: 0.75rem !important; }
  .u-mb-sm-075,
  .u-my-sm-075 {
    margin-bottom: 0.75rem !important; }
  .u-ml-sm-075,
  .u-mx-sm-075 {
    margin-left: 0.75rem !important; }
  .u-m-sm-100 {
    margin: 1rem !important; }
  .u-mt-sm-100,
  .u-my-sm-100 {
    margin-top: 1rem !important; }
  .u-mr-sm-100,
  .u-mx-sm-100 {
    margin-right: 1rem !important; }
  .u-mb-sm-100,
  .u-my-sm-100 {
    margin-bottom: 1rem !important; }
  .u-ml-sm-100,
  .u-mx-sm-100 {
    margin-left: 1rem !important; }
  .u-m-sm-125 {
    margin: 1.25rem !important; }
  .u-mt-sm-125,
  .u-my-sm-125 {
    margin-top: 1.25rem !important; }
  .u-mr-sm-125,
  .u-mx-sm-125 {
    margin-right: 1.25rem !important; }
  .u-mb-sm-125,
  .u-my-sm-125 {
    margin-bottom: 1.25rem !important; }
  .u-ml-sm-125,
  .u-mx-sm-125 {
    margin-left: 1.25rem !important; }
  .u-m-sm-150 {
    margin: 1.5rem !important; }
  .u-mt-sm-150,
  .u-my-sm-150 {
    margin-top: 1.5rem !important; }
  .u-mr-sm-150,
  .u-mx-sm-150 {
    margin-right: 1.5rem !important; }
  .u-mb-sm-150,
  .u-my-sm-150 {
    margin-bottom: 1.5rem !important; }
  .u-ml-sm-150,
  .u-mx-sm-150 {
    margin-left: 1.5rem !important; }
  .u-m-sm-175 {
    margin: 1.75rem !important; }
  .u-mt-sm-175,
  .u-my-sm-175 {
    margin-top: 1.75rem !important; }
  .u-mr-sm-175,
  .u-mx-sm-175 {
    margin-right: 1.75rem !important; }
  .u-mb-sm-175,
  .u-my-sm-175 {
    margin-bottom: 1.75rem !important; }
  .u-ml-sm-175,
  .u-mx-sm-175 {
    margin-left: 1.75rem !important; }
  .u-m-sm-200 {
    margin: 2rem !important; }
  .u-mt-sm-200,
  .u-my-sm-200 {
    margin-top: 2rem !important; }
  .u-mr-sm-200,
  .u-mx-sm-200 {
    margin-right: 2rem !important; }
  .u-mb-sm-200,
  .u-my-sm-200 {
    margin-bottom: 2rem !important; }
  .u-ml-sm-200,
  .u-mx-sm-200 {
    margin-left: 2rem !important; }
  .u-m-sm-225 {
    margin: 2.25rem !important; }
  .u-mt-sm-225,
  .u-my-sm-225 {
    margin-top: 2.25rem !important; }
  .u-mr-sm-225,
  .u-mx-sm-225 {
    margin-right: 2.25rem !important; }
  .u-mb-sm-225,
  .u-my-sm-225 {
    margin-bottom: 2.25rem !important; }
  .u-ml-sm-225,
  .u-mx-sm-225 {
    margin-left: 2.25rem !important; }
  .u-m-sm-250 {
    margin: 2.5rem !important; }
  .u-mt-sm-250,
  .u-my-sm-250 {
    margin-top: 2.5rem !important; }
  .u-mr-sm-250,
  .u-mx-sm-250 {
    margin-right: 2.5rem !important; }
  .u-mb-sm-250,
  .u-my-sm-250 {
    margin-bottom: 2.5rem !important; }
  .u-ml-sm-250,
  .u-mx-sm-250 {
    margin-left: 2.5rem !important; }
  .u-m-sm-275 {
    margin: 2.75rem !important; }
  .u-mt-sm-275,
  .u-my-sm-275 {
    margin-top: 2.75rem !important; }
  .u-mr-sm-275,
  .u-mx-sm-275 {
    margin-right: 2.75rem !important; }
  .u-mb-sm-275,
  .u-my-sm-275 {
    margin-bottom: 2.75rem !important; }
  .u-ml-sm-275,
  .u-mx-sm-275 {
    margin-left: 2.75rem !important; }
  .u-m-sm-300 {
    margin: 3rem !important; }
  .u-mt-sm-300,
  .u-my-sm-300 {
    margin-top: 3rem !important; }
  .u-mr-sm-300,
  .u-mx-sm-300 {
    margin-right: 3rem !important; }
  .u-mb-sm-300,
  .u-my-sm-300 {
    margin-bottom: 3rem !important; }
  .u-ml-sm-300,
  .u-mx-sm-300 {
    margin-left: 3rem !important; }
  .u-m-sm-325 {
    margin: 3.25rem !important; }
  .u-mt-sm-325,
  .u-my-sm-325 {
    margin-top: 3.25rem !important; }
  .u-mr-sm-325,
  .u-mx-sm-325 {
    margin-right: 3.25rem !important; }
  .u-mb-sm-325,
  .u-my-sm-325 {
    margin-bottom: 3.25rem !important; }
  .u-ml-sm-325,
  .u-mx-sm-325 {
    margin-left: 3.25rem !important; }
  .u-m-sm-350 {
    margin: 3.5rem !important; }
  .u-mt-sm-350,
  .u-my-sm-350 {
    margin-top: 3.5rem !important; }
  .u-mr-sm-350,
  .u-mx-sm-350 {
    margin-right: 3.5rem !important; }
  .u-mb-sm-350,
  .u-my-sm-350 {
    margin-bottom: 3.5rem !important; }
  .u-ml-sm-350,
  .u-mx-sm-350 {
    margin-left: 3.5rem !important; }
  .u-m-sm-375 {
    margin: 3.75rem !important; }
  .u-mt-sm-375,
  .u-my-sm-375 {
    margin-top: 3.75rem !important; }
  .u-mr-sm-375,
  .u-mx-sm-375 {
    margin-right: 3.75rem !important; }
  .u-mb-sm-375,
  .u-my-sm-375 {
    margin-bottom: 3.75rem !important; }
  .u-ml-sm-375,
  .u-mx-sm-375 {
    margin-left: 3.75rem !important; }
  .u-m-sm-400 {
    margin: 4rem !important; }
  .u-mt-sm-400,
  .u-my-sm-400 {
    margin-top: 4rem !important; }
  .u-mr-sm-400,
  .u-mx-sm-400 {
    margin-right: 4rem !important; }
  .u-mb-sm-400,
  .u-my-sm-400 {
    margin-bottom: 4rem !important; }
  .u-ml-sm-400,
  .u-mx-sm-400 {
    margin-left: 4rem !important; }
  .u-m-sm-425 {
    margin: 4.25rem !important; }
  .u-mt-sm-425,
  .u-my-sm-425 {
    margin-top: 4.25rem !important; }
  .u-mr-sm-425,
  .u-mx-sm-425 {
    margin-right: 4.25rem !important; }
  .u-mb-sm-425,
  .u-my-sm-425 {
    margin-bottom: 4.25rem !important; }
  .u-ml-sm-425,
  .u-mx-sm-425 {
    margin-left: 4.25rem !important; }
  .u-m-sm-450 {
    margin: 4.5rem !important; }
  .u-mt-sm-450,
  .u-my-sm-450 {
    margin-top: 4.5rem !important; }
  .u-mr-sm-450,
  .u-mx-sm-450 {
    margin-right: 4.5rem !important; }
  .u-mb-sm-450,
  .u-my-sm-450 {
    margin-bottom: 4.5rem !important; }
  .u-ml-sm-450,
  .u-mx-sm-450 {
    margin-left: 4.5rem !important; }
  .u-m-sm-475 {
    margin: 4.75rem !important; }
  .u-mt-sm-475,
  .u-my-sm-475 {
    margin-top: 4.75rem !important; }
  .u-mr-sm-475,
  .u-mx-sm-475 {
    margin-right: 4.75rem !important; }
  .u-mb-sm-475,
  .u-my-sm-475 {
    margin-bottom: 4.75rem !important; }
  .u-ml-sm-475,
  .u-mx-sm-475 {
    margin-left: 4.75rem !important; }
  .u-m-sm-500 {
    margin: 5rem !important; }
  .u-mt-sm-500,
  .u-my-sm-500 {
    margin-top: 5rem !important; }
  .u-mr-sm-500,
  .u-mx-sm-500 {
    margin-right: 5rem !important; }
  .u-mb-sm-500,
  .u-my-sm-500 {
    margin-bottom: 5rem !important; }
  .u-ml-sm-500,
  .u-mx-sm-500 {
    margin-left: 5rem !important; }
  .u-m-sm-525 {
    margin: 5.25rem !important; }
  .u-mt-sm-525,
  .u-my-sm-525 {
    margin-top: 5.25rem !important; }
  .u-mr-sm-525,
  .u-mx-sm-525 {
    margin-right: 5.25rem !important; }
  .u-mb-sm-525,
  .u-my-sm-525 {
    margin-bottom: 5.25rem !important; }
  .u-ml-sm-525,
  .u-mx-sm-525 {
    margin-left: 5.25rem !important; }
  .u-m-sm-550 {
    margin: 5.5rem !important; }
  .u-mt-sm-550,
  .u-my-sm-550 {
    margin-top: 5.5rem !important; }
  .u-mr-sm-550,
  .u-mx-sm-550 {
    margin-right: 5.5rem !important; }
  .u-mb-sm-550,
  .u-my-sm-550 {
    margin-bottom: 5.5rem !important; }
  .u-ml-sm-550,
  .u-mx-sm-550 {
    margin-left: 5.5rem !important; }
  .u-m-sm-575 {
    margin: 5.75rem !important; }
  .u-mt-sm-575,
  .u-my-sm-575 {
    margin-top: 5.75rem !important; }
  .u-mr-sm-575,
  .u-mx-sm-575 {
    margin-right: 5.75rem !important; }
  .u-mb-sm-575,
  .u-my-sm-575 {
    margin-bottom: 5.75rem !important; }
  .u-ml-sm-575,
  .u-mx-sm-575 {
    margin-left: 5.75rem !important; }
  .u-m-sm-600 {
    margin: 6rem !important; }
  .u-mt-sm-600,
  .u-my-sm-600 {
    margin-top: 6rem !important; }
  .u-mr-sm-600,
  .u-mx-sm-600 {
    margin-right: 6rem !important; }
  .u-mb-sm-600,
  .u-my-sm-600 {
    margin-bottom: 6rem !important; }
  .u-ml-sm-600,
  .u-mx-sm-600 {
    margin-left: 6rem !important; }
  .u-m-sm-625 {
    margin: 6.25rem !important; }
  .u-mt-sm-625,
  .u-my-sm-625 {
    margin-top: 6.25rem !important; }
  .u-mr-sm-625,
  .u-mx-sm-625 {
    margin-right: 6.25rem !important; }
  .u-mb-sm-625,
  .u-my-sm-625 {
    margin-bottom: 6.25rem !important; }
  .u-ml-sm-625,
  .u-mx-sm-625 {
    margin-left: 6.25rem !important; }
  .u-m-sm-650 {
    margin: 6.5rem !important; }
  .u-mt-sm-650,
  .u-my-sm-650 {
    margin-top: 6.5rem !important; }
  .u-mr-sm-650,
  .u-mx-sm-650 {
    margin-right: 6.5rem !important; }
  .u-mb-sm-650,
  .u-my-sm-650 {
    margin-bottom: 6.5rem !important; }
  .u-ml-sm-650,
  .u-mx-sm-650 {
    margin-left: 6.5rem !important; }
  .u-m-sm-675 {
    margin: 6.75rem !important; }
  .u-mt-sm-675,
  .u-my-sm-675 {
    margin-top: 6.75rem !important; }
  .u-mr-sm-675,
  .u-mx-sm-675 {
    margin-right: 6.75rem !important; }
  .u-mb-sm-675,
  .u-my-sm-675 {
    margin-bottom: 6.75rem !important; }
  .u-ml-sm-675,
  .u-mx-sm-675 {
    margin-left: 6.75rem !important; }
  .u-m-sm-700 {
    margin: 7rem !important; }
  .u-mt-sm-700,
  .u-my-sm-700 {
    margin-top: 7rem !important; }
  .u-mr-sm-700,
  .u-mx-sm-700 {
    margin-right: 7rem !important; }
  .u-mb-sm-700,
  .u-my-sm-700 {
    margin-bottom: 7rem !important; }
  .u-ml-sm-700,
  .u-mx-sm-700 {
    margin-left: 7rem !important; }
  .u-m-sm-725 {
    margin: 7.25rem !important; }
  .u-mt-sm-725,
  .u-my-sm-725 {
    margin-top: 7.25rem !important; }
  .u-mr-sm-725,
  .u-mx-sm-725 {
    margin-right: 7.25rem !important; }
  .u-mb-sm-725,
  .u-my-sm-725 {
    margin-bottom: 7.25rem !important; }
  .u-ml-sm-725,
  .u-mx-sm-725 {
    margin-left: 7.25rem !important; }
  .u-m-sm-750 {
    margin: 7.5rem !important; }
  .u-mt-sm-750,
  .u-my-sm-750 {
    margin-top: 7.5rem !important; }
  .u-mr-sm-750,
  .u-mx-sm-750 {
    margin-right: 7.5rem !important; }
  .u-mb-sm-750,
  .u-my-sm-750 {
    margin-bottom: 7.5rem !important; }
  .u-ml-sm-750,
  .u-mx-sm-750 {
    margin-left: 7.5rem !important; }
  .u-m-sm-775 {
    margin: 7.75rem !important; }
  .u-mt-sm-775,
  .u-my-sm-775 {
    margin-top: 7.75rem !important; }
  .u-mr-sm-775,
  .u-mx-sm-775 {
    margin-right: 7.75rem !important; }
  .u-mb-sm-775,
  .u-my-sm-775 {
    margin-bottom: 7.75rem !important; }
  .u-ml-sm-775,
  .u-mx-sm-775 {
    margin-left: 7.75rem !important; }
  .u-m-sm-800 {
    margin: 8rem !important; }
  .u-mt-sm-800,
  .u-my-sm-800 {
    margin-top: 8rem !important; }
  .u-mr-sm-800,
  .u-mx-sm-800 {
    margin-right: 8rem !important; }
  .u-mb-sm-800,
  .u-my-sm-800 {
    margin-bottom: 8rem !important; }
  .u-ml-sm-800,
  .u-mx-sm-800 {
    margin-left: 8rem !important; }
  .u-p-sm-000 {
    padding: 0rem !important; }
  .u-pt-sm-000,
  .u-py-sm-000 {
    padding-top: 0rem !important; }
  .u-pr-sm-000,
  .u-px-sm-000 {
    padding-right: 0rem !important; }
  .u-pb-sm-000,
  .u-py-sm-000 {
    padding-bottom: 0rem !important; }
  .u-pl-sm-000,
  .u-px-sm-000 {
    padding-left: 0rem !important; }
  .u-p-sm-025 {
    padding: 0.25rem !important; }
  .u-pt-sm-025,
  .u-py-sm-025 {
    padding-top: 0.25rem !important; }
  .u-pr-sm-025,
  .u-px-sm-025 {
    padding-right: 0.25rem !important; }
  .u-pb-sm-025,
  .u-py-sm-025 {
    padding-bottom: 0.25rem !important; }
  .u-pl-sm-025,
  .u-px-sm-025 {
    padding-left: 0.25rem !important; }
  .u-p-sm-050 {
    padding: 0.5rem !important; }
  .u-pt-sm-050,
  .u-py-sm-050 {
    padding-top: 0.5rem !important; }
  .u-pr-sm-050,
  .u-px-sm-050 {
    padding-right: 0.5rem !important; }
  .u-pb-sm-050,
  .u-py-sm-050 {
    padding-bottom: 0.5rem !important; }
  .u-pl-sm-050,
  .u-px-sm-050 {
    padding-left: 0.5rem !important; }
  .u-p-sm-075 {
    padding: 0.75rem !important; }
  .u-pt-sm-075,
  .u-py-sm-075 {
    padding-top: 0.75rem !important; }
  .u-pr-sm-075,
  .u-px-sm-075 {
    padding-right: 0.75rem !important; }
  .u-pb-sm-075,
  .u-py-sm-075 {
    padding-bottom: 0.75rem !important; }
  .u-pl-sm-075,
  .u-px-sm-075 {
    padding-left: 0.75rem !important; }
  .u-p-sm-100 {
    padding: 1rem !important; }
  .u-pt-sm-100,
  .u-py-sm-100 {
    padding-top: 1rem !important; }
  .u-pr-sm-100,
  .u-px-sm-100 {
    padding-right: 1rem !important; }
  .u-pb-sm-100,
  .u-py-sm-100 {
    padding-bottom: 1rem !important; }
  .u-pl-sm-100,
  .u-px-sm-100 {
    padding-left: 1rem !important; }
  .u-p-sm-125 {
    padding: 1.25rem !important; }
  .u-pt-sm-125,
  .u-py-sm-125 {
    padding-top: 1.25rem !important; }
  .u-pr-sm-125,
  .u-px-sm-125 {
    padding-right: 1.25rem !important; }
  .u-pb-sm-125,
  .u-py-sm-125 {
    padding-bottom: 1.25rem !important; }
  .u-pl-sm-125,
  .u-px-sm-125 {
    padding-left: 1.25rem !important; }
  .u-p-sm-150 {
    padding: 1.5rem !important; }
  .u-pt-sm-150,
  .u-py-sm-150 {
    padding-top: 1.5rem !important; }
  .u-pr-sm-150,
  .u-px-sm-150 {
    padding-right: 1.5rem !important; }
  .u-pb-sm-150,
  .u-py-sm-150 {
    padding-bottom: 1.5rem !important; }
  .u-pl-sm-150,
  .u-px-sm-150 {
    padding-left: 1.5rem !important; }
  .u-p-sm-175 {
    padding: 1.75rem !important; }
  .u-pt-sm-175,
  .u-py-sm-175 {
    padding-top: 1.75rem !important; }
  .u-pr-sm-175,
  .u-px-sm-175 {
    padding-right: 1.75rem !important; }
  .u-pb-sm-175,
  .u-py-sm-175 {
    padding-bottom: 1.75rem !important; }
  .u-pl-sm-175,
  .u-px-sm-175 {
    padding-left: 1.75rem !important; }
  .u-p-sm-200 {
    padding: 2rem !important; }
  .u-pt-sm-200,
  .u-py-sm-200 {
    padding-top: 2rem !important; }
  .u-pr-sm-200,
  .u-px-sm-200 {
    padding-right: 2rem !important; }
  .u-pb-sm-200,
  .u-py-sm-200 {
    padding-bottom: 2rem !important; }
  .u-pl-sm-200,
  .u-px-sm-200 {
    padding-left: 2rem !important; }
  .u-p-sm-225 {
    padding: 2.25rem !important; }
  .u-pt-sm-225,
  .u-py-sm-225 {
    padding-top: 2.25rem !important; }
  .u-pr-sm-225,
  .u-px-sm-225 {
    padding-right: 2.25rem !important; }
  .u-pb-sm-225,
  .u-py-sm-225 {
    padding-bottom: 2.25rem !important; }
  .u-pl-sm-225,
  .u-px-sm-225 {
    padding-left: 2.25rem !important; }
  .u-p-sm-250 {
    padding: 2.5rem !important; }
  .u-pt-sm-250,
  .u-py-sm-250 {
    padding-top: 2.5rem !important; }
  .u-pr-sm-250,
  .u-px-sm-250 {
    padding-right: 2.5rem !important; }
  .u-pb-sm-250,
  .u-py-sm-250 {
    padding-bottom: 2.5rem !important; }
  .u-pl-sm-250,
  .u-px-sm-250 {
    padding-left: 2.5rem !important; }
  .u-p-sm-275 {
    padding: 2.75rem !important; }
  .u-pt-sm-275,
  .u-py-sm-275 {
    padding-top: 2.75rem !important; }
  .u-pr-sm-275,
  .u-px-sm-275 {
    padding-right: 2.75rem !important; }
  .u-pb-sm-275,
  .u-py-sm-275 {
    padding-bottom: 2.75rem !important; }
  .u-pl-sm-275,
  .u-px-sm-275 {
    padding-left: 2.75rem !important; }
  .u-p-sm-300 {
    padding: 3rem !important; }
  .u-pt-sm-300,
  .u-py-sm-300 {
    padding-top: 3rem !important; }
  .u-pr-sm-300,
  .u-px-sm-300 {
    padding-right: 3rem !important; }
  .u-pb-sm-300,
  .u-py-sm-300 {
    padding-bottom: 3rem !important; }
  .u-pl-sm-300,
  .u-px-sm-300 {
    padding-left: 3rem !important; }
  .u-p-sm-325 {
    padding: 3.25rem !important; }
  .u-pt-sm-325,
  .u-py-sm-325 {
    padding-top: 3.25rem !important; }
  .u-pr-sm-325,
  .u-px-sm-325 {
    padding-right: 3.25rem !important; }
  .u-pb-sm-325,
  .u-py-sm-325 {
    padding-bottom: 3.25rem !important; }
  .u-pl-sm-325,
  .u-px-sm-325 {
    padding-left: 3.25rem !important; }
  .u-p-sm-350 {
    padding: 3.5rem !important; }
  .u-pt-sm-350,
  .u-py-sm-350 {
    padding-top: 3.5rem !important; }
  .u-pr-sm-350,
  .u-px-sm-350 {
    padding-right: 3.5rem !important; }
  .u-pb-sm-350,
  .u-py-sm-350 {
    padding-bottom: 3.5rem !important; }
  .u-pl-sm-350,
  .u-px-sm-350 {
    padding-left: 3.5rem !important; }
  .u-p-sm-375 {
    padding: 3.75rem !important; }
  .u-pt-sm-375,
  .u-py-sm-375 {
    padding-top: 3.75rem !important; }
  .u-pr-sm-375,
  .u-px-sm-375 {
    padding-right: 3.75rem !important; }
  .u-pb-sm-375,
  .u-py-sm-375 {
    padding-bottom: 3.75rem !important; }
  .u-pl-sm-375,
  .u-px-sm-375 {
    padding-left: 3.75rem !important; }
  .u-p-sm-400 {
    padding: 4rem !important; }
  .u-pt-sm-400,
  .u-py-sm-400 {
    padding-top: 4rem !important; }
  .u-pr-sm-400,
  .u-px-sm-400 {
    padding-right: 4rem !important; }
  .u-pb-sm-400,
  .u-py-sm-400 {
    padding-bottom: 4rem !important; }
  .u-pl-sm-400,
  .u-px-sm-400 {
    padding-left: 4rem !important; }
  .u-p-sm-425 {
    padding: 4.25rem !important; }
  .u-pt-sm-425,
  .u-py-sm-425 {
    padding-top: 4.25rem !important; }
  .u-pr-sm-425,
  .u-px-sm-425 {
    padding-right: 4.25rem !important; }
  .u-pb-sm-425,
  .u-py-sm-425 {
    padding-bottom: 4.25rem !important; }
  .u-pl-sm-425,
  .u-px-sm-425 {
    padding-left: 4.25rem !important; }
  .u-p-sm-450 {
    padding: 4.5rem !important; }
  .u-pt-sm-450,
  .u-py-sm-450 {
    padding-top: 4.5rem !important; }
  .u-pr-sm-450,
  .u-px-sm-450 {
    padding-right: 4.5rem !important; }
  .u-pb-sm-450,
  .u-py-sm-450 {
    padding-bottom: 4.5rem !important; }
  .u-pl-sm-450,
  .u-px-sm-450 {
    padding-left: 4.5rem !important; }
  .u-p-sm-475 {
    padding: 4.75rem !important; }
  .u-pt-sm-475,
  .u-py-sm-475 {
    padding-top: 4.75rem !important; }
  .u-pr-sm-475,
  .u-px-sm-475 {
    padding-right: 4.75rem !important; }
  .u-pb-sm-475,
  .u-py-sm-475 {
    padding-bottom: 4.75rem !important; }
  .u-pl-sm-475,
  .u-px-sm-475 {
    padding-left: 4.75rem !important; }
  .u-p-sm-500 {
    padding: 5rem !important; }
  .u-pt-sm-500,
  .u-py-sm-500 {
    padding-top: 5rem !important; }
  .u-pr-sm-500,
  .u-px-sm-500 {
    padding-right: 5rem !important; }
  .u-pb-sm-500,
  .u-py-sm-500 {
    padding-bottom: 5rem !important; }
  .u-pl-sm-500,
  .u-px-sm-500 {
    padding-left: 5rem !important; }
  .u-p-sm-525 {
    padding: 5.25rem !important; }
  .u-pt-sm-525,
  .u-py-sm-525 {
    padding-top: 5.25rem !important; }
  .u-pr-sm-525,
  .u-px-sm-525 {
    padding-right: 5.25rem !important; }
  .u-pb-sm-525,
  .u-py-sm-525 {
    padding-bottom: 5.25rem !important; }
  .u-pl-sm-525,
  .u-px-sm-525 {
    padding-left: 5.25rem !important; }
  .u-p-sm-550 {
    padding: 5.5rem !important; }
  .u-pt-sm-550,
  .u-py-sm-550 {
    padding-top: 5.5rem !important; }
  .u-pr-sm-550,
  .u-px-sm-550 {
    padding-right: 5.5rem !important; }
  .u-pb-sm-550,
  .u-py-sm-550 {
    padding-bottom: 5.5rem !important; }
  .u-pl-sm-550,
  .u-px-sm-550 {
    padding-left: 5.5rem !important; }
  .u-p-sm-575 {
    padding: 5.75rem !important; }
  .u-pt-sm-575,
  .u-py-sm-575 {
    padding-top: 5.75rem !important; }
  .u-pr-sm-575,
  .u-px-sm-575 {
    padding-right: 5.75rem !important; }
  .u-pb-sm-575,
  .u-py-sm-575 {
    padding-bottom: 5.75rem !important; }
  .u-pl-sm-575,
  .u-px-sm-575 {
    padding-left: 5.75rem !important; }
  .u-p-sm-600 {
    padding: 6rem !important; }
  .u-pt-sm-600,
  .u-py-sm-600 {
    padding-top: 6rem !important; }
  .u-pr-sm-600,
  .u-px-sm-600 {
    padding-right: 6rem !important; }
  .u-pb-sm-600,
  .u-py-sm-600 {
    padding-bottom: 6rem !important; }
  .u-pl-sm-600,
  .u-px-sm-600 {
    padding-left: 6rem !important; }
  .u-p-sm-625 {
    padding: 6.25rem !important; }
  .u-pt-sm-625,
  .u-py-sm-625 {
    padding-top: 6.25rem !important; }
  .u-pr-sm-625,
  .u-px-sm-625 {
    padding-right: 6.25rem !important; }
  .u-pb-sm-625,
  .u-py-sm-625 {
    padding-bottom: 6.25rem !important; }
  .u-pl-sm-625,
  .u-px-sm-625 {
    padding-left: 6.25rem !important; }
  .u-p-sm-650 {
    padding: 6.5rem !important; }
  .u-pt-sm-650,
  .u-py-sm-650 {
    padding-top: 6.5rem !important; }
  .u-pr-sm-650,
  .u-px-sm-650 {
    padding-right: 6.5rem !important; }
  .u-pb-sm-650,
  .u-py-sm-650 {
    padding-bottom: 6.5rem !important; }
  .u-pl-sm-650,
  .u-px-sm-650 {
    padding-left: 6.5rem !important; }
  .u-p-sm-675 {
    padding: 6.75rem !important; }
  .u-pt-sm-675,
  .u-py-sm-675 {
    padding-top: 6.75rem !important; }
  .u-pr-sm-675,
  .u-px-sm-675 {
    padding-right: 6.75rem !important; }
  .u-pb-sm-675,
  .u-py-sm-675 {
    padding-bottom: 6.75rem !important; }
  .u-pl-sm-675,
  .u-px-sm-675 {
    padding-left: 6.75rem !important; }
  .u-p-sm-700 {
    padding: 7rem !important; }
  .u-pt-sm-700,
  .u-py-sm-700 {
    padding-top: 7rem !important; }
  .u-pr-sm-700,
  .u-px-sm-700 {
    padding-right: 7rem !important; }
  .u-pb-sm-700,
  .u-py-sm-700 {
    padding-bottom: 7rem !important; }
  .u-pl-sm-700,
  .u-px-sm-700 {
    padding-left: 7rem !important; }
  .u-p-sm-725 {
    padding: 7.25rem !important; }
  .u-pt-sm-725,
  .u-py-sm-725 {
    padding-top: 7.25rem !important; }
  .u-pr-sm-725,
  .u-px-sm-725 {
    padding-right: 7.25rem !important; }
  .u-pb-sm-725,
  .u-py-sm-725 {
    padding-bottom: 7.25rem !important; }
  .u-pl-sm-725,
  .u-px-sm-725 {
    padding-left: 7.25rem !important; }
  .u-p-sm-750 {
    padding: 7.5rem !important; }
  .u-pt-sm-750,
  .u-py-sm-750 {
    padding-top: 7.5rem !important; }
  .u-pr-sm-750,
  .u-px-sm-750 {
    padding-right: 7.5rem !important; }
  .u-pb-sm-750,
  .u-py-sm-750 {
    padding-bottom: 7.5rem !important; }
  .u-pl-sm-750,
  .u-px-sm-750 {
    padding-left: 7.5rem !important; }
  .u-p-sm-775 {
    padding: 7.75rem !important; }
  .u-pt-sm-775,
  .u-py-sm-775 {
    padding-top: 7.75rem !important; }
  .u-pr-sm-775,
  .u-px-sm-775 {
    padding-right: 7.75rem !important; }
  .u-pb-sm-775,
  .u-py-sm-775 {
    padding-bottom: 7.75rem !important; }
  .u-pl-sm-775,
  .u-px-sm-775 {
    padding-left: 7.75rem !important; }
  .u-p-sm-800 {
    padding: 8rem !important; }
  .u-pt-sm-800,
  .u-py-sm-800 {
    padding-top: 8rem !important; }
  .u-pr-sm-800,
  .u-px-sm-800 {
    padding-right: 8rem !important; }
  .u-pb-sm-800,
  .u-py-sm-800 {
    padding-bottom: 8rem !important; }
  .u-pl-sm-800,
  .u-px-sm-800 {
    padding-left: 8rem !important; } }

@media (min-width: 768px) {
  .u-m-md-000 {
    margin: 0rem !important; }
  .u-mt-md-000,
  .u-my-md-000 {
    margin-top: 0rem !important; }
  .u-mr-md-000,
  .u-mx-md-000 {
    margin-right: 0rem !important; }
  .u-mb-md-000,
  .u-my-md-000 {
    margin-bottom: 0rem !important; }
  .u-ml-md-000,
  .u-mx-md-000 {
    margin-left: 0rem !important; }
  .u-m-md-025 {
    margin: 0.25rem !important; }
  .u-mt-md-025,
  .u-my-md-025 {
    margin-top: 0.25rem !important; }
  .u-mr-md-025,
  .u-mx-md-025 {
    margin-right: 0.25rem !important; }
  .u-mb-md-025,
  .u-my-md-025 {
    margin-bottom: 0.25rem !important; }
  .u-ml-md-025,
  .u-mx-md-025 {
    margin-left: 0.25rem !important; }
  .u-m-md-050 {
    margin: 0.5rem !important; }
  .u-mt-md-050,
  .u-my-md-050 {
    margin-top: 0.5rem !important; }
  .u-mr-md-050,
  .u-mx-md-050 {
    margin-right: 0.5rem !important; }
  .u-mb-md-050,
  .u-my-md-050 {
    margin-bottom: 0.5rem !important; }
  .u-ml-md-050,
  .u-mx-md-050 {
    margin-left: 0.5rem !important; }
  .u-m-md-075 {
    margin: 0.75rem !important; }
  .u-mt-md-075,
  .u-my-md-075 {
    margin-top: 0.75rem !important; }
  .u-mr-md-075,
  .u-mx-md-075 {
    margin-right: 0.75rem !important; }
  .u-mb-md-075,
  .u-my-md-075 {
    margin-bottom: 0.75rem !important; }
  .u-ml-md-075,
  .u-mx-md-075 {
    margin-left: 0.75rem !important; }
  .u-m-md-100 {
    margin: 1rem !important; }
  .u-mt-md-100,
  .u-my-md-100 {
    margin-top: 1rem !important; }
  .u-mr-md-100,
  .u-mx-md-100 {
    margin-right: 1rem !important; }
  .u-mb-md-100,
  .u-my-md-100 {
    margin-bottom: 1rem !important; }
  .u-ml-md-100,
  .u-mx-md-100 {
    margin-left: 1rem !important; }
  .u-m-md-125 {
    margin: 1.25rem !important; }
  .u-mt-md-125,
  .u-my-md-125 {
    margin-top: 1.25rem !important; }
  .u-mr-md-125,
  .u-mx-md-125 {
    margin-right: 1.25rem !important; }
  .u-mb-md-125,
  .u-my-md-125 {
    margin-bottom: 1.25rem !important; }
  .u-ml-md-125,
  .u-mx-md-125 {
    margin-left: 1.25rem !important; }
  .u-m-md-150 {
    margin: 1.5rem !important; }
  .u-mt-md-150,
  .u-my-md-150 {
    margin-top: 1.5rem !important; }
  .u-mr-md-150,
  .u-mx-md-150 {
    margin-right: 1.5rem !important; }
  .u-mb-md-150,
  .u-my-md-150 {
    margin-bottom: 1.5rem !important; }
  .u-ml-md-150,
  .u-mx-md-150 {
    margin-left: 1.5rem !important; }
  .u-m-md-175 {
    margin: 1.75rem !important; }
  .u-mt-md-175,
  .u-my-md-175 {
    margin-top: 1.75rem !important; }
  .u-mr-md-175,
  .u-mx-md-175 {
    margin-right: 1.75rem !important; }
  .u-mb-md-175,
  .u-my-md-175 {
    margin-bottom: 1.75rem !important; }
  .u-ml-md-175,
  .u-mx-md-175 {
    margin-left: 1.75rem !important; }
  .u-m-md-200 {
    margin: 2rem !important; }
  .u-mt-md-200,
  .u-my-md-200 {
    margin-top: 2rem !important; }
  .u-mr-md-200,
  .u-mx-md-200 {
    margin-right: 2rem !important; }
  .u-mb-md-200,
  .u-my-md-200 {
    margin-bottom: 2rem !important; }
  .u-ml-md-200,
  .u-mx-md-200 {
    margin-left: 2rem !important; }
  .u-m-md-225 {
    margin: 2.25rem !important; }
  .u-mt-md-225,
  .u-my-md-225 {
    margin-top: 2.25rem !important; }
  .u-mr-md-225,
  .u-mx-md-225 {
    margin-right: 2.25rem !important; }
  .u-mb-md-225,
  .u-my-md-225 {
    margin-bottom: 2.25rem !important; }
  .u-ml-md-225,
  .u-mx-md-225 {
    margin-left: 2.25rem !important; }
  .u-m-md-250 {
    margin: 2.5rem !important; }
  .u-mt-md-250,
  .u-my-md-250 {
    margin-top: 2.5rem !important; }
  .u-mr-md-250,
  .u-mx-md-250 {
    margin-right: 2.5rem !important; }
  .u-mb-md-250,
  .u-my-md-250 {
    margin-bottom: 2.5rem !important; }
  .u-ml-md-250,
  .u-mx-md-250 {
    margin-left: 2.5rem !important; }
  .u-m-md-275 {
    margin: 2.75rem !important; }
  .u-mt-md-275,
  .u-my-md-275 {
    margin-top: 2.75rem !important; }
  .u-mr-md-275,
  .u-mx-md-275 {
    margin-right: 2.75rem !important; }
  .u-mb-md-275,
  .u-my-md-275 {
    margin-bottom: 2.75rem !important; }
  .u-ml-md-275,
  .u-mx-md-275 {
    margin-left: 2.75rem !important; }
  .u-m-md-300 {
    margin: 3rem !important; }
  .u-mt-md-300,
  .u-my-md-300 {
    margin-top: 3rem !important; }
  .u-mr-md-300,
  .u-mx-md-300 {
    margin-right: 3rem !important; }
  .u-mb-md-300,
  .u-my-md-300 {
    margin-bottom: 3rem !important; }
  .u-ml-md-300,
  .u-mx-md-300 {
    margin-left: 3rem !important; }
  .u-m-md-325 {
    margin: 3.25rem !important; }
  .u-mt-md-325,
  .u-my-md-325 {
    margin-top: 3.25rem !important; }
  .u-mr-md-325,
  .u-mx-md-325 {
    margin-right: 3.25rem !important; }
  .u-mb-md-325,
  .u-my-md-325 {
    margin-bottom: 3.25rem !important; }
  .u-ml-md-325,
  .u-mx-md-325 {
    margin-left: 3.25rem !important; }
  .u-m-md-350 {
    margin: 3.5rem !important; }
  .u-mt-md-350,
  .u-my-md-350 {
    margin-top: 3.5rem !important; }
  .u-mr-md-350,
  .u-mx-md-350 {
    margin-right: 3.5rem !important; }
  .u-mb-md-350,
  .u-my-md-350 {
    margin-bottom: 3.5rem !important; }
  .u-ml-md-350,
  .u-mx-md-350 {
    margin-left: 3.5rem !important; }
  .u-m-md-375 {
    margin: 3.75rem !important; }
  .u-mt-md-375,
  .u-my-md-375 {
    margin-top: 3.75rem !important; }
  .u-mr-md-375,
  .u-mx-md-375 {
    margin-right: 3.75rem !important; }
  .u-mb-md-375,
  .u-my-md-375 {
    margin-bottom: 3.75rem !important; }
  .u-ml-md-375,
  .u-mx-md-375 {
    margin-left: 3.75rem !important; }
  .u-m-md-400 {
    margin: 4rem !important; }
  .u-mt-md-400,
  .u-my-md-400 {
    margin-top: 4rem !important; }
  .u-mr-md-400,
  .u-mx-md-400 {
    margin-right: 4rem !important; }
  .u-mb-md-400,
  .u-my-md-400 {
    margin-bottom: 4rem !important; }
  .u-ml-md-400,
  .u-mx-md-400 {
    margin-left: 4rem !important; }
  .u-m-md-425 {
    margin: 4.25rem !important; }
  .u-mt-md-425,
  .u-my-md-425 {
    margin-top: 4.25rem !important; }
  .u-mr-md-425,
  .u-mx-md-425 {
    margin-right: 4.25rem !important; }
  .u-mb-md-425,
  .u-my-md-425 {
    margin-bottom: 4.25rem !important; }
  .u-ml-md-425,
  .u-mx-md-425 {
    margin-left: 4.25rem !important; }
  .u-m-md-450 {
    margin: 4.5rem !important; }
  .u-mt-md-450,
  .u-my-md-450 {
    margin-top: 4.5rem !important; }
  .u-mr-md-450,
  .u-mx-md-450 {
    margin-right: 4.5rem !important; }
  .u-mb-md-450,
  .u-my-md-450 {
    margin-bottom: 4.5rem !important; }
  .u-ml-md-450,
  .u-mx-md-450 {
    margin-left: 4.5rem !important; }
  .u-m-md-475 {
    margin: 4.75rem !important; }
  .u-mt-md-475,
  .u-my-md-475 {
    margin-top: 4.75rem !important; }
  .u-mr-md-475,
  .u-mx-md-475 {
    margin-right: 4.75rem !important; }
  .u-mb-md-475,
  .u-my-md-475 {
    margin-bottom: 4.75rem !important; }
  .u-ml-md-475,
  .u-mx-md-475 {
    margin-left: 4.75rem !important; }
  .u-m-md-500 {
    margin: 5rem !important; }
  .u-mt-md-500,
  .u-my-md-500 {
    margin-top: 5rem !important; }
  .u-mr-md-500,
  .u-mx-md-500 {
    margin-right: 5rem !important; }
  .u-mb-md-500,
  .u-my-md-500 {
    margin-bottom: 5rem !important; }
  .u-ml-md-500,
  .u-mx-md-500 {
    margin-left: 5rem !important; }
  .u-m-md-525 {
    margin: 5.25rem !important; }
  .u-mt-md-525,
  .u-my-md-525 {
    margin-top: 5.25rem !important; }
  .u-mr-md-525,
  .u-mx-md-525 {
    margin-right: 5.25rem !important; }
  .u-mb-md-525,
  .u-my-md-525 {
    margin-bottom: 5.25rem !important; }
  .u-ml-md-525,
  .u-mx-md-525 {
    margin-left: 5.25rem !important; }
  .u-m-md-550 {
    margin: 5.5rem !important; }
  .u-mt-md-550,
  .u-my-md-550 {
    margin-top: 5.5rem !important; }
  .u-mr-md-550,
  .u-mx-md-550 {
    margin-right: 5.5rem !important; }
  .u-mb-md-550,
  .u-my-md-550 {
    margin-bottom: 5.5rem !important; }
  .u-ml-md-550,
  .u-mx-md-550 {
    margin-left: 5.5rem !important; }
  .u-m-md-575 {
    margin: 5.75rem !important; }
  .u-mt-md-575,
  .u-my-md-575 {
    margin-top: 5.75rem !important; }
  .u-mr-md-575,
  .u-mx-md-575 {
    margin-right: 5.75rem !important; }
  .u-mb-md-575,
  .u-my-md-575 {
    margin-bottom: 5.75rem !important; }
  .u-ml-md-575,
  .u-mx-md-575 {
    margin-left: 5.75rem !important; }
  .u-m-md-600 {
    margin: 6rem !important; }
  .u-mt-md-600,
  .u-my-md-600 {
    margin-top: 6rem !important; }
  .u-mr-md-600,
  .u-mx-md-600 {
    margin-right: 6rem !important; }
  .u-mb-md-600,
  .u-my-md-600 {
    margin-bottom: 6rem !important; }
  .u-ml-md-600,
  .u-mx-md-600 {
    margin-left: 6rem !important; }
  .u-m-md-625 {
    margin: 6.25rem !important; }
  .u-mt-md-625,
  .u-my-md-625 {
    margin-top: 6.25rem !important; }
  .u-mr-md-625,
  .u-mx-md-625 {
    margin-right: 6.25rem !important; }
  .u-mb-md-625,
  .u-my-md-625 {
    margin-bottom: 6.25rem !important; }
  .u-ml-md-625,
  .u-mx-md-625 {
    margin-left: 6.25rem !important; }
  .u-m-md-650 {
    margin: 6.5rem !important; }
  .u-mt-md-650,
  .u-my-md-650 {
    margin-top: 6.5rem !important; }
  .u-mr-md-650,
  .u-mx-md-650 {
    margin-right: 6.5rem !important; }
  .u-mb-md-650,
  .u-my-md-650 {
    margin-bottom: 6.5rem !important; }
  .u-ml-md-650,
  .u-mx-md-650 {
    margin-left: 6.5rem !important; }
  .u-m-md-675 {
    margin: 6.75rem !important; }
  .u-mt-md-675,
  .u-my-md-675 {
    margin-top: 6.75rem !important; }
  .u-mr-md-675,
  .u-mx-md-675 {
    margin-right: 6.75rem !important; }
  .u-mb-md-675,
  .u-my-md-675 {
    margin-bottom: 6.75rem !important; }
  .u-ml-md-675,
  .u-mx-md-675 {
    margin-left: 6.75rem !important; }
  .u-m-md-700 {
    margin: 7rem !important; }
  .u-mt-md-700,
  .u-my-md-700 {
    margin-top: 7rem !important; }
  .u-mr-md-700,
  .u-mx-md-700 {
    margin-right: 7rem !important; }
  .u-mb-md-700,
  .u-my-md-700 {
    margin-bottom: 7rem !important; }
  .u-ml-md-700,
  .u-mx-md-700 {
    margin-left: 7rem !important; }
  .u-m-md-725 {
    margin: 7.25rem !important; }
  .u-mt-md-725,
  .u-my-md-725 {
    margin-top: 7.25rem !important; }
  .u-mr-md-725,
  .u-mx-md-725 {
    margin-right: 7.25rem !important; }
  .u-mb-md-725,
  .u-my-md-725 {
    margin-bottom: 7.25rem !important; }
  .u-ml-md-725,
  .u-mx-md-725 {
    margin-left: 7.25rem !important; }
  .u-m-md-750 {
    margin: 7.5rem !important; }
  .u-mt-md-750,
  .u-my-md-750 {
    margin-top: 7.5rem !important; }
  .u-mr-md-750,
  .u-mx-md-750 {
    margin-right: 7.5rem !important; }
  .u-mb-md-750,
  .u-my-md-750 {
    margin-bottom: 7.5rem !important; }
  .u-ml-md-750,
  .u-mx-md-750 {
    margin-left: 7.5rem !important; }
  .u-m-md-775 {
    margin: 7.75rem !important; }
  .u-mt-md-775,
  .u-my-md-775 {
    margin-top: 7.75rem !important; }
  .u-mr-md-775,
  .u-mx-md-775 {
    margin-right: 7.75rem !important; }
  .u-mb-md-775,
  .u-my-md-775 {
    margin-bottom: 7.75rem !important; }
  .u-ml-md-775,
  .u-mx-md-775 {
    margin-left: 7.75rem !important; }
  .u-m-md-800 {
    margin: 8rem !important; }
  .u-mt-md-800,
  .u-my-md-800 {
    margin-top: 8rem !important; }
  .u-mr-md-800,
  .u-mx-md-800 {
    margin-right: 8rem !important; }
  .u-mb-md-800,
  .u-my-md-800 {
    margin-bottom: 8rem !important; }
  .u-ml-md-800,
  .u-mx-md-800 {
    margin-left: 8rem !important; }
  .u-p-md-000 {
    padding: 0rem !important; }
  .u-pt-md-000,
  .u-py-md-000 {
    padding-top: 0rem !important; }
  .u-pr-md-000,
  .u-px-md-000 {
    padding-right: 0rem !important; }
  .u-pb-md-000,
  .u-py-md-000 {
    padding-bottom: 0rem !important; }
  .u-pl-md-000,
  .u-px-md-000 {
    padding-left: 0rem !important; }
  .u-p-md-025 {
    padding: 0.25rem !important; }
  .u-pt-md-025,
  .u-py-md-025 {
    padding-top: 0.25rem !important; }
  .u-pr-md-025,
  .u-px-md-025 {
    padding-right: 0.25rem !important; }
  .u-pb-md-025,
  .u-py-md-025 {
    padding-bottom: 0.25rem !important; }
  .u-pl-md-025,
  .u-px-md-025 {
    padding-left: 0.25rem !important; }
  .u-p-md-050 {
    padding: 0.5rem !important; }
  .u-pt-md-050,
  .u-py-md-050 {
    padding-top: 0.5rem !important; }
  .u-pr-md-050,
  .u-px-md-050 {
    padding-right: 0.5rem !important; }
  .u-pb-md-050,
  .u-py-md-050 {
    padding-bottom: 0.5rem !important; }
  .u-pl-md-050,
  .u-px-md-050 {
    padding-left: 0.5rem !important; }
  .u-p-md-075 {
    padding: 0.75rem !important; }
  .u-pt-md-075,
  .u-py-md-075 {
    padding-top: 0.75rem !important; }
  .u-pr-md-075,
  .u-px-md-075 {
    padding-right: 0.75rem !important; }
  .u-pb-md-075,
  .u-py-md-075 {
    padding-bottom: 0.75rem !important; }
  .u-pl-md-075,
  .u-px-md-075 {
    padding-left: 0.75rem !important; }
  .u-p-md-100 {
    padding: 1rem !important; }
  .u-pt-md-100,
  .u-py-md-100 {
    padding-top: 1rem !important; }
  .u-pr-md-100,
  .u-px-md-100 {
    padding-right: 1rem !important; }
  .u-pb-md-100,
  .u-py-md-100 {
    padding-bottom: 1rem !important; }
  .u-pl-md-100,
  .u-px-md-100 {
    padding-left: 1rem !important; }
  .u-p-md-125 {
    padding: 1.25rem !important; }
  .u-pt-md-125,
  .u-py-md-125 {
    padding-top: 1.25rem !important; }
  .u-pr-md-125,
  .u-px-md-125 {
    padding-right: 1.25rem !important; }
  .u-pb-md-125,
  .u-py-md-125 {
    padding-bottom: 1.25rem !important; }
  .u-pl-md-125,
  .u-px-md-125 {
    padding-left: 1.25rem !important; }
  .u-p-md-150 {
    padding: 1.5rem !important; }
  .u-pt-md-150,
  .u-py-md-150 {
    padding-top: 1.5rem !important; }
  .u-pr-md-150,
  .u-px-md-150 {
    padding-right: 1.5rem !important; }
  .u-pb-md-150,
  .u-py-md-150 {
    padding-bottom: 1.5rem !important; }
  .u-pl-md-150,
  .u-px-md-150 {
    padding-left: 1.5rem !important; }
  .u-p-md-175 {
    padding: 1.75rem !important; }
  .u-pt-md-175,
  .u-py-md-175 {
    padding-top: 1.75rem !important; }
  .u-pr-md-175,
  .u-px-md-175 {
    padding-right: 1.75rem !important; }
  .u-pb-md-175,
  .u-py-md-175 {
    padding-bottom: 1.75rem !important; }
  .u-pl-md-175,
  .u-px-md-175 {
    padding-left: 1.75rem !important; }
  .u-p-md-200 {
    padding: 2rem !important; }
  .u-pt-md-200,
  .u-py-md-200 {
    padding-top: 2rem !important; }
  .u-pr-md-200,
  .u-px-md-200 {
    padding-right: 2rem !important; }
  .u-pb-md-200,
  .u-py-md-200 {
    padding-bottom: 2rem !important; }
  .u-pl-md-200,
  .u-px-md-200 {
    padding-left: 2rem !important; }
  .u-p-md-225 {
    padding: 2.25rem !important; }
  .u-pt-md-225,
  .u-py-md-225 {
    padding-top: 2.25rem !important; }
  .u-pr-md-225,
  .u-px-md-225 {
    padding-right: 2.25rem !important; }
  .u-pb-md-225,
  .u-py-md-225 {
    padding-bottom: 2.25rem !important; }
  .u-pl-md-225,
  .u-px-md-225 {
    padding-left: 2.25rem !important; }
  .u-p-md-250 {
    padding: 2.5rem !important; }
  .u-pt-md-250,
  .u-py-md-250 {
    padding-top: 2.5rem !important; }
  .u-pr-md-250,
  .u-px-md-250 {
    padding-right: 2.5rem !important; }
  .u-pb-md-250,
  .u-py-md-250 {
    padding-bottom: 2.5rem !important; }
  .u-pl-md-250,
  .u-px-md-250 {
    padding-left: 2.5rem !important; }
  .u-p-md-275 {
    padding: 2.75rem !important; }
  .u-pt-md-275,
  .u-py-md-275 {
    padding-top: 2.75rem !important; }
  .u-pr-md-275,
  .u-px-md-275 {
    padding-right: 2.75rem !important; }
  .u-pb-md-275,
  .u-py-md-275 {
    padding-bottom: 2.75rem !important; }
  .u-pl-md-275,
  .u-px-md-275 {
    padding-left: 2.75rem !important; }
  .u-p-md-300 {
    padding: 3rem !important; }
  .u-pt-md-300,
  .u-py-md-300 {
    padding-top: 3rem !important; }
  .u-pr-md-300,
  .u-px-md-300 {
    padding-right: 3rem !important; }
  .u-pb-md-300,
  .u-py-md-300 {
    padding-bottom: 3rem !important; }
  .u-pl-md-300,
  .u-px-md-300 {
    padding-left: 3rem !important; }
  .u-p-md-325 {
    padding: 3.25rem !important; }
  .u-pt-md-325,
  .u-py-md-325 {
    padding-top: 3.25rem !important; }
  .u-pr-md-325,
  .u-px-md-325 {
    padding-right: 3.25rem !important; }
  .u-pb-md-325,
  .u-py-md-325 {
    padding-bottom: 3.25rem !important; }
  .u-pl-md-325,
  .u-px-md-325 {
    padding-left: 3.25rem !important; }
  .u-p-md-350 {
    padding: 3.5rem !important; }
  .u-pt-md-350,
  .u-py-md-350 {
    padding-top: 3.5rem !important; }
  .u-pr-md-350,
  .u-px-md-350 {
    padding-right: 3.5rem !important; }
  .u-pb-md-350,
  .u-py-md-350 {
    padding-bottom: 3.5rem !important; }
  .u-pl-md-350,
  .u-px-md-350 {
    padding-left: 3.5rem !important; }
  .u-p-md-375 {
    padding: 3.75rem !important; }
  .u-pt-md-375,
  .u-py-md-375 {
    padding-top: 3.75rem !important; }
  .u-pr-md-375,
  .u-px-md-375 {
    padding-right: 3.75rem !important; }
  .u-pb-md-375,
  .u-py-md-375 {
    padding-bottom: 3.75rem !important; }
  .u-pl-md-375,
  .u-px-md-375 {
    padding-left: 3.75rem !important; }
  .u-p-md-400 {
    padding: 4rem !important; }
  .u-pt-md-400,
  .u-py-md-400 {
    padding-top: 4rem !important; }
  .u-pr-md-400,
  .u-px-md-400 {
    padding-right: 4rem !important; }
  .u-pb-md-400,
  .u-py-md-400 {
    padding-bottom: 4rem !important; }
  .u-pl-md-400,
  .u-px-md-400 {
    padding-left: 4rem !important; }
  .u-p-md-425 {
    padding: 4.25rem !important; }
  .u-pt-md-425,
  .u-py-md-425 {
    padding-top: 4.25rem !important; }
  .u-pr-md-425,
  .u-px-md-425 {
    padding-right: 4.25rem !important; }
  .u-pb-md-425,
  .u-py-md-425 {
    padding-bottom: 4.25rem !important; }
  .u-pl-md-425,
  .u-px-md-425 {
    padding-left: 4.25rem !important; }
  .u-p-md-450 {
    padding: 4.5rem !important; }
  .u-pt-md-450,
  .u-py-md-450 {
    padding-top: 4.5rem !important; }
  .u-pr-md-450,
  .u-px-md-450 {
    padding-right: 4.5rem !important; }
  .u-pb-md-450,
  .u-py-md-450 {
    padding-bottom: 4.5rem !important; }
  .u-pl-md-450,
  .u-px-md-450 {
    padding-left: 4.5rem !important; }
  .u-p-md-475 {
    padding: 4.75rem !important; }
  .u-pt-md-475,
  .u-py-md-475 {
    padding-top: 4.75rem !important; }
  .u-pr-md-475,
  .u-px-md-475 {
    padding-right: 4.75rem !important; }
  .u-pb-md-475,
  .u-py-md-475 {
    padding-bottom: 4.75rem !important; }
  .u-pl-md-475,
  .u-px-md-475 {
    padding-left: 4.75rem !important; }
  .u-p-md-500 {
    padding: 5rem !important; }
  .u-pt-md-500,
  .u-py-md-500 {
    padding-top: 5rem !important; }
  .u-pr-md-500,
  .u-px-md-500 {
    padding-right: 5rem !important; }
  .u-pb-md-500,
  .u-py-md-500 {
    padding-bottom: 5rem !important; }
  .u-pl-md-500,
  .u-px-md-500 {
    padding-left: 5rem !important; }
  .u-p-md-525 {
    padding: 5.25rem !important; }
  .u-pt-md-525,
  .u-py-md-525 {
    padding-top: 5.25rem !important; }
  .u-pr-md-525,
  .u-px-md-525 {
    padding-right: 5.25rem !important; }
  .u-pb-md-525,
  .u-py-md-525 {
    padding-bottom: 5.25rem !important; }
  .u-pl-md-525,
  .u-px-md-525 {
    padding-left: 5.25rem !important; }
  .u-p-md-550 {
    padding: 5.5rem !important; }
  .u-pt-md-550,
  .u-py-md-550 {
    padding-top: 5.5rem !important; }
  .u-pr-md-550,
  .u-px-md-550 {
    padding-right: 5.5rem !important; }
  .u-pb-md-550,
  .u-py-md-550 {
    padding-bottom: 5.5rem !important; }
  .u-pl-md-550,
  .u-px-md-550 {
    padding-left: 5.5rem !important; }
  .u-p-md-575 {
    padding: 5.75rem !important; }
  .u-pt-md-575,
  .u-py-md-575 {
    padding-top: 5.75rem !important; }
  .u-pr-md-575,
  .u-px-md-575 {
    padding-right: 5.75rem !important; }
  .u-pb-md-575,
  .u-py-md-575 {
    padding-bottom: 5.75rem !important; }
  .u-pl-md-575,
  .u-px-md-575 {
    padding-left: 5.75rem !important; }
  .u-p-md-600 {
    padding: 6rem !important; }
  .u-pt-md-600,
  .u-py-md-600 {
    padding-top: 6rem !important; }
  .u-pr-md-600,
  .u-px-md-600 {
    padding-right: 6rem !important; }
  .u-pb-md-600,
  .u-py-md-600 {
    padding-bottom: 6rem !important; }
  .u-pl-md-600,
  .u-px-md-600 {
    padding-left: 6rem !important; }
  .u-p-md-625 {
    padding: 6.25rem !important; }
  .u-pt-md-625,
  .u-py-md-625 {
    padding-top: 6.25rem !important; }
  .u-pr-md-625,
  .u-px-md-625 {
    padding-right: 6.25rem !important; }
  .u-pb-md-625,
  .u-py-md-625 {
    padding-bottom: 6.25rem !important; }
  .u-pl-md-625,
  .u-px-md-625 {
    padding-left: 6.25rem !important; }
  .u-p-md-650 {
    padding: 6.5rem !important; }
  .u-pt-md-650,
  .u-py-md-650 {
    padding-top: 6.5rem !important; }
  .u-pr-md-650,
  .u-px-md-650 {
    padding-right: 6.5rem !important; }
  .u-pb-md-650,
  .u-py-md-650 {
    padding-bottom: 6.5rem !important; }
  .u-pl-md-650,
  .u-px-md-650 {
    padding-left: 6.5rem !important; }
  .u-p-md-675 {
    padding: 6.75rem !important; }
  .u-pt-md-675,
  .u-py-md-675 {
    padding-top: 6.75rem !important; }
  .u-pr-md-675,
  .u-px-md-675 {
    padding-right: 6.75rem !important; }
  .u-pb-md-675,
  .u-py-md-675 {
    padding-bottom: 6.75rem !important; }
  .u-pl-md-675,
  .u-px-md-675 {
    padding-left: 6.75rem !important; }
  .u-p-md-700 {
    padding: 7rem !important; }
  .u-pt-md-700,
  .u-py-md-700 {
    padding-top: 7rem !important; }
  .u-pr-md-700,
  .u-px-md-700 {
    padding-right: 7rem !important; }
  .u-pb-md-700,
  .u-py-md-700 {
    padding-bottom: 7rem !important; }
  .u-pl-md-700,
  .u-px-md-700 {
    padding-left: 7rem !important; }
  .u-p-md-725 {
    padding: 7.25rem !important; }
  .u-pt-md-725,
  .u-py-md-725 {
    padding-top: 7.25rem !important; }
  .u-pr-md-725,
  .u-px-md-725 {
    padding-right: 7.25rem !important; }
  .u-pb-md-725,
  .u-py-md-725 {
    padding-bottom: 7.25rem !important; }
  .u-pl-md-725,
  .u-px-md-725 {
    padding-left: 7.25rem !important; }
  .u-p-md-750 {
    padding: 7.5rem !important; }
  .u-pt-md-750,
  .u-py-md-750 {
    padding-top: 7.5rem !important; }
  .u-pr-md-750,
  .u-px-md-750 {
    padding-right: 7.5rem !important; }
  .u-pb-md-750,
  .u-py-md-750 {
    padding-bottom: 7.5rem !important; }
  .u-pl-md-750,
  .u-px-md-750 {
    padding-left: 7.5rem !important; }
  .u-p-md-775 {
    padding: 7.75rem !important; }
  .u-pt-md-775,
  .u-py-md-775 {
    padding-top: 7.75rem !important; }
  .u-pr-md-775,
  .u-px-md-775 {
    padding-right: 7.75rem !important; }
  .u-pb-md-775,
  .u-py-md-775 {
    padding-bottom: 7.75rem !important; }
  .u-pl-md-775,
  .u-px-md-775 {
    padding-left: 7.75rem !important; }
  .u-p-md-800 {
    padding: 8rem !important; }
  .u-pt-md-800,
  .u-py-md-800 {
    padding-top: 8rem !important; }
  .u-pr-md-800,
  .u-px-md-800 {
    padding-right: 8rem !important; }
  .u-pb-md-800,
  .u-py-md-800 {
    padding-bottom: 8rem !important; }
  .u-pl-md-800,
  .u-px-md-800 {
    padding-left: 8rem !important; } }

@media (min-width: 992px) {
  .u-m-lg-000 {
    margin: 0rem !important; }
  .u-mt-lg-000,
  .u-my-lg-000 {
    margin-top: 0rem !important; }
  .u-mr-lg-000,
  .u-mx-lg-000 {
    margin-right: 0rem !important; }
  .u-mb-lg-000,
  .u-my-lg-000 {
    margin-bottom: 0rem !important; }
  .u-ml-lg-000,
  .u-mx-lg-000 {
    margin-left: 0rem !important; }
  .u-m-lg-025 {
    margin: 0.25rem !important; }
  .u-mt-lg-025,
  .u-my-lg-025 {
    margin-top: 0.25rem !important; }
  .u-mr-lg-025,
  .u-mx-lg-025 {
    margin-right: 0.25rem !important; }
  .u-mb-lg-025,
  .u-my-lg-025 {
    margin-bottom: 0.25rem !important; }
  .u-ml-lg-025,
  .u-mx-lg-025 {
    margin-left: 0.25rem !important; }
  .u-m-lg-050 {
    margin: 0.5rem !important; }
  .u-mt-lg-050,
  .u-my-lg-050 {
    margin-top: 0.5rem !important; }
  .u-mr-lg-050,
  .u-mx-lg-050 {
    margin-right: 0.5rem !important; }
  .u-mb-lg-050,
  .u-my-lg-050 {
    margin-bottom: 0.5rem !important; }
  .u-ml-lg-050,
  .u-mx-lg-050 {
    margin-left: 0.5rem !important; }
  .u-m-lg-075 {
    margin: 0.75rem !important; }
  .u-mt-lg-075,
  .u-my-lg-075 {
    margin-top: 0.75rem !important; }
  .u-mr-lg-075,
  .u-mx-lg-075 {
    margin-right: 0.75rem !important; }
  .u-mb-lg-075,
  .u-my-lg-075 {
    margin-bottom: 0.75rem !important; }
  .u-ml-lg-075,
  .u-mx-lg-075 {
    margin-left: 0.75rem !important; }
  .u-m-lg-100 {
    margin: 1rem !important; }
  .u-mt-lg-100,
  .u-my-lg-100 {
    margin-top: 1rem !important; }
  .u-mr-lg-100,
  .u-mx-lg-100 {
    margin-right: 1rem !important; }
  .u-mb-lg-100,
  .u-my-lg-100 {
    margin-bottom: 1rem !important; }
  .u-ml-lg-100,
  .u-mx-lg-100 {
    margin-left: 1rem !important; }
  .u-m-lg-125 {
    margin: 1.25rem !important; }
  .u-mt-lg-125,
  .u-my-lg-125 {
    margin-top: 1.25rem !important; }
  .u-mr-lg-125,
  .u-mx-lg-125 {
    margin-right: 1.25rem !important; }
  .u-mb-lg-125,
  .u-my-lg-125 {
    margin-bottom: 1.25rem !important; }
  .u-ml-lg-125,
  .u-mx-lg-125 {
    margin-left: 1.25rem !important; }
  .u-m-lg-150 {
    margin: 1.5rem !important; }
  .u-mt-lg-150,
  .u-my-lg-150 {
    margin-top: 1.5rem !important; }
  .u-mr-lg-150,
  .u-mx-lg-150 {
    margin-right: 1.5rem !important; }
  .u-mb-lg-150,
  .u-my-lg-150 {
    margin-bottom: 1.5rem !important; }
  .u-ml-lg-150,
  .u-mx-lg-150 {
    margin-left: 1.5rem !important; }
  .u-m-lg-175 {
    margin: 1.75rem !important; }
  .u-mt-lg-175,
  .u-my-lg-175 {
    margin-top: 1.75rem !important; }
  .u-mr-lg-175,
  .u-mx-lg-175 {
    margin-right: 1.75rem !important; }
  .u-mb-lg-175,
  .u-my-lg-175 {
    margin-bottom: 1.75rem !important; }
  .u-ml-lg-175,
  .u-mx-lg-175 {
    margin-left: 1.75rem !important; }
  .u-m-lg-200 {
    margin: 2rem !important; }
  .u-mt-lg-200,
  .u-my-lg-200 {
    margin-top: 2rem !important; }
  .u-mr-lg-200,
  .u-mx-lg-200 {
    margin-right: 2rem !important; }
  .u-mb-lg-200,
  .u-my-lg-200 {
    margin-bottom: 2rem !important; }
  .u-ml-lg-200,
  .u-mx-lg-200 {
    margin-left: 2rem !important; }
  .u-m-lg-225 {
    margin: 2.25rem !important; }
  .u-mt-lg-225,
  .u-my-lg-225 {
    margin-top: 2.25rem !important; }
  .u-mr-lg-225,
  .u-mx-lg-225 {
    margin-right: 2.25rem !important; }
  .u-mb-lg-225,
  .u-my-lg-225 {
    margin-bottom: 2.25rem !important; }
  .u-ml-lg-225,
  .u-mx-lg-225 {
    margin-left: 2.25rem !important; }
  .u-m-lg-250 {
    margin: 2.5rem !important; }
  .u-mt-lg-250,
  .u-my-lg-250 {
    margin-top: 2.5rem !important; }
  .u-mr-lg-250,
  .u-mx-lg-250 {
    margin-right: 2.5rem !important; }
  .u-mb-lg-250,
  .u-my-lg-250 {
    margin-bottom: 2.5rem !important; }
  .u-ml-lg-250,
  .u-mx-lg-250 {
    margin-left: 2.5rem !important; }
  .u-m-lg-275 {
    margin: 2.75rem !important; }
  .u-mt-lg-275,
  .u-my-lg-275 {
    margin-top: 2.75rem !important; }
  .u-mr-lg-275,
  .u-mx-lg-275 {
    margin-right: 2.75rem !important; }
  .u-mb-lg-275,
  .u-my-lg-275 {
    margin-bottom: 2.75rem !important; }
  .u-ml-lg-275,
  .u-mx-lg-275 {
    margin-left: 2.75rem !important; }
  .u-m-lg-300 {
    margin: 3rem !important; }
  .u-mt-lg-300,
  .u-my-lg-300 {
    margin-top: 3rem !important; }
  .u-mr-lg-300,
  .u-mx-lg-300 {
    margin-right: 3rem !important; }
  .u-mb-lg-300,
  .u-my-lg-300 {
    margin-bottom: 3rem !important; }
  .u-ml-lg-300,
  .u-mx-lg-300 {
    margin-left: 3rem !important; }
  .u-m-lg-325 {
    margin: 3.25rem !important; }
  .u-mt-lg-325,
  .u-my-lg-325 {
    margin-top: 3.25rem !important; }
  .u-mr-lg-325,
  .u-mx-lg-325 {
    margin-right: 3.25rem !important; }
  .u-mb-lg-325,
  .u-my-lg-325 {
    margin-bottom: 3.25rem !important; }
  .u-ml-lg-325,
  .u-mx-lg-325 {
    margin-left: 3.25rem !important; }
  .u-m-lg-350 {
    margin: 3.5rem !important; }
  .u-mt-lg-350,
  .u-my-lg-350 {
    margin-top: 3.5rem !important; }
  .u-mr-lg-350,
  .u-mx-lg-350 {
    margin-right: 3.5rem !important; }
  .u-mb-lg-350,
  .u-my-lg-350 {
    margin-bottom: 3.5rem !important; }
  .u-ml-lg-350,
  .u-mx-lg-350 {
    margin-left: 3.5rem !important; }
  .u-m-lg-375 {
    margin: 3.75rem !important; }
  .u-mt-lg-375,
  .u-my-lg-375 {
    margin-top: 3.75rem !important; }
  .u-mr-lg-375,
  .u-mx-lg-375 {
    margin-right: 3.75rem !important; }
  .u-mb-lg-375,
  .u-my-lg-375 {
    margin-bottom: 3.75rem !important; }
  .u-ml-lg-375,
  .u-mx-lg-375 {
    margin-left: 3.75rem !important; }
  .u-m-lg-400 {
    margin: 4rem !important; }
  .u-mt-lg-400,
  .u-my-lg-400 {
    margin-top: 4rem !important; }
  .u-mr-lg-400,
  .u-mx-lg-400 {
    margin-right: 4rem !important; }
  .u-mb-lg-400,
  .u-my-lg-400 {
    margin-bottom: 4rem !important; }
  .u-ml-lg-400,
  .u-mx-lg-400 {
    margin-left: 4rem !important; }
  .u-m-lg-425 {
    margin: 4.25rem !important; }
  .u-mt-lg-425,
  .u-my-lg-425 {
    margin-top: 4.25rem !important; }
  .u-mr-lg-425,
  .u-mx-lg-425 {
    margin-right: 4.25rem !important; }
  .u-mb-lg-425,
  .u-my-lg-425 {
    margin-bottom: 4.25rem !important; }
  .u-ml-lg-425,
  .u-mx-lg-425 {
    margin-left: 4.25rem !important; }
  .u-m-lg-450 {
    margin: 4.5rem !important; }
  .u-mt-lg-450,
  .u-my-lg-450 {
    margin-top: 4.5rem !important; }
  .u-mr-lg-450,
  .u-mx-lg-450 {
    margin-right: 4.5rem !important; }
  .u-mb-lg-450,
  .u-my-lg-450 {
    margin-bottom: 4.5rem !important; }
  .u-ml-lg-450,
  .u-mx-lg-450 {
    margin-left: 4.5rem !important; }
  .u-m-lg-475 {
    margin: 4.75rem !important; }
  .u-mt-lg-475,
  .u-my-lg-475 {
    margin-top: 4.75rem !important; }
  .u-mr-lg-475,
  .u-mx-lg-475 {
    margin-right: 4.75rem !important; }
  .u-mb-lg-475,
  .u-my-lg-475 {
    margin-bottom: 4.75rem !important; }
  .u-ml-lg-475,
  .u-mx-lg-475 {
    margin-left: 4.75rem !important; }
  .u-m-lg-500 {
    margin: 5rem !important; }
  .u-mt-lg-500,
  .u-my-lg-500 {
    margin-top: 5rem !important; }
  .u-mr-lg-500,
  .u-mx-lg-500 {
    margin-right: 5rem !important; }
  .u-mb-lg-500,
  .u-my-lg-500 {
    margin-bottom: 5rem !important; }
  .u-ml-lg-500,
  .u-mx-lg-500 {
    margin-left: 5rem !important; }
  .u-m-lg-525 {
    margin: 5.25rem !important; }
  .u-mt-lg-525,
  .u-my-lg-525 {
    margin-top: 5.25rem !important; }
  .u-mr-lg-525,
  .u-mx-lg-525 {
    margin-right: 5.25rem !important; }
  .u-mb-lg-525,
  .u-my-lg-525 {
    margin-bottom: 5.25rem !important; }
  .u-ml-lg-525,
  .u-mx-lg-525 {
    margin-left: 5.25rem !important; }
  .u-m-lg-550 {
    margin: 5.5rem !important; }
  .u-mt-lg-550,
  .u-my-lg-550 {
    margin-top: 5.5rem !important; }
  .u-mr-lg-550,
  .u-mx-lg-550 {
    margin-right: 5.5rem !important; }
  .u-mb-lg-550,
  .u-my-lg-550 {
    margin-bottom: 5.5rem !important; }
  .u-ml-lg-550,
  .u-mx-lg-550 {
    margin-left: 5.5rem !important; }
  .u-m-lg-575 {
    margin: 5.75rem !important; }
  .u-mt-lg-575,
  .u-my-lg-575 {
    margin-top: 5.75rem !important; }
  .u-mr-lg-575,
  .u-mx-lg-575 {
    margin-right: 5.75rem !important; }
  .u-mb-lg-575,
  .u-my-lg-575 {
    margin-bottom: 5.75rem !important; }
  .u-ml-lg-575,
  .u-mx-lg-575 {
    margin-left: 5.75rem !important; }
  .u-m-lg-600 {
    margin: 6rem !important; }
  .u-mt-lg-600,
  .u-my-lg-600 {
    margin-top: 6rem !important; }
  .u-mr-lg-600,
  .u-mx-lg-600 {
    margin-right: 6rem !important; }
  .u-mb-lg-600,
  .u-my-lg-600 {
    margin-bottom: 6rem !important; }
  .u-ml-lg-600,
  .u-mx-lg-600 {
    margin-left: 6rem !important; }
  .u-m-lg-625 {
    margin: 6.25rem !important; }
  .u-mt-lg-625,
  .u-my-lg-625 {
    margin-top: 6.25rem !important; }
  .u-mr-lg-625,
  .u-mx-lg-625 {
    margin-right: 6.25rem !important; }
  .u-mb-lg-625,
  .u-my-lg-625 {
    margin-bottom: 6.25rem !important; }
  .u-ml-lg-625,
  .u-mx-lg-625 {
    margin-left: 6.25rem !important; }
  .u-m-lg-650 {
    margin: 6.5rem !important; }
  .u-mt-lg-650,
  .u-my-lg-650 {
    margin-top: 6.5rem !important; }
  .u-mr-lg-650,
  .u-mx-lg-650 {
    margin-right: 6.5rem !important; }
  .u-mb-lg-650,
  .u-my-lg-650 {
    margin-bottom: 6.5rem !important; }
  .u-ml-lg-650,
  .u-mx-lg-650 {
    margin-left: 6.5rem !important; }
  .u-m-lg-675 {
    margin: 6.75rem !important; }
  .u-mt-lg-675,
  .u-my-lg-675 {
    margin-top: 6.75rem !important; }
  .u-mr-lg-675,
  .u-mx-lg-675 {
    margin-right: 6.75rem !important; }
  .u-mb-lg-675,
  .u-my-lg-675 {
    margin-bottom: 6.75rem !important; }
  .u-ml-lg-675,
  .u-mx-lg-675 {
    margin-left: 6.75rem !important; }
  .u-m-lg-700 {
    margin: 7rem !important; }
  .u-mt-lg-700,
  .u-my-lg-700 {
    margin-top: 7rem !important; }
  .u-mr-lg-700,
  .u-mx-lg-700 {
    margin-right: 7rem !important; }
  .u-mb-lg-700,
  .u-my-lg-700 {
    margin-bottom: 7rem !important; }
  .u-ml-lg-700,
  .u-mx-lg-700 {
    margin-left: 7rem !important; }
  .u-m-lg-725 {
    margin: 7.25rem !important; }
  .u-mt-lg-725,
  .u-my-lg-725 {
    margin-top: 7.25rem !important; }
  .u-mr-lg-725,
  .u-mx-lg-725 {
    margin-right: 7.25rem !important; }
  .u-mb-lg-725,
  .u-my-lg-725 {
    margin-bottom: 7.25rem !important; }
  .u-ml-lg-725,
  .u-mx-lg-725 {
    margin-left: 7.25rem !important; }
  .u-m-lg-750 {
    margin: 7.5rem !important; }
  .u-mt-lg-750,
  .u-my-lg-750 {
    margin-top: 7.5rem !important; }
  .u-mr-lg-750,
  .u-mx-lg-750 {
    margin-right: 7.5rem !important; }
  .u-mb-lg-750,
  .u-my-lg-750 {
    margin-bottom: 7.5rem !important; }
  .u-ml-lg-750,
  .u-mx-lg-750 {
    margin-left: 7.5rem !important; }
  .u-m-lg-775 {
    margin: 7.75rem !important; }
  .u-mt-lg-775,
  .u-my-lg-775 {
    margin-top: 7.75rem !important; }
  .u-mr-lg-775,
  .u-mx-lg-775 {
    margin-right: 7.75rem !important; }
  .u-mb-lg-775,
  .u-my-lg-775 {
    margin-bottom: 7.75rem !important; }
  .u-ml-lg-775,
  .u-mx-lg-775 {
    margin-left: 7.75rem !important; }
  .u-m-lg-800 {
    margin: 8rem !important; }
  .u-mt-lg-800,
  .u-my-lg-800 {
    margin-top: 8rem !important; }
  .u-mr-lg-800,
  .u-mx-lg-800 {
    margin-right: 8rem !important; }
  .u-mb-lg-800,
  .u-my-lg-800 {
    margin-bottom: 8rem !important; }
  .u-ml-lg-800,
  .u-mx-lg-800 {
    margin-left: 8rem !important; }
  .u-p-lg-000 {
    padding: 0rem !important; }
  .u-pt-lg-000,
  .u-py-lg-000 {
    padding-top: 0rem !important; }
  .u-pr-lg-000,
  .u-px-lg-000 {
    padding-right: 0rem !important; }
  .u-pb-lg-000,
  .u-py-lg-000 {
    padding-bottom: 0rem !important; }
  .u-pl-lg-000,
  .u-px-lg-000 {
    padding-left: 0rem !important; }
  .u-p-lg-025 {
    padding: 0.25rem !important; }
  .u-pt-lg-025,
  .u-py-lg-025 {
    padding-top: 0.25rem !important; }
  .u-pr-lg-025,
  .u-px-lg-025 {
    padding-right: 0.25rem !important; }
  .u-pb-lg-025,
  .u-py-lg-025 {
    padding-bottom: 0.25rem !important; }
  .u-pl-lg-025,
  .u-px-lg-025 {
    padding-left: 0.25rem !important; }
  .u-p-lg-050 {
    padding: 0.5rem !important; }
  .u-pt-lg-050,
  .u-py-lg-050 {
    padding-top: 0.5rem !important; }
  .u-pr-lg-050,
  .u-px-lg-050 {
    padding-right: 0.5rem !important; }
  .u-pb-lg-050,
  .u-py-lg-050 {
    padding-bottom: 0.5rem !important; }
  .u-pl-lg-050,
  .u-px-lg-050 {
    padding-left: 0.5rem !important; }
  .u-p-lg-075 {
    padding: 0.75rem !important; }
  .u-pt-lg-075,
  .u-py-lg-075 {
    padding-top: 0.75rem !important; }
  .u-pr-lg-075,
  .u-px-lg-075 {
    padding-right: 0.75rem !important; }
  .u-pb-lg-075,
  .u-py-lg-075 {
    padding-bottom: 0.75rem !important; }
  .u-pl-lg-075,
  .u-px-lg-075 {
    padding-left: 0.75rem !important; }
  .u-p-lg-100 {
    padding: 1rem !important; }
  .u-pt-lg-100,
  .u-py-lg-100 {
    padding-top: 1rem !important; }
  .u-pr-lg-100,
  .u-px-lg-100 {
    padding-right: 1rem !important; }
  .u-pb-lg-100,
  .u-py-lg-100 {
    padding-bottom: 1rem !important; }
  .u-pl-lg-100,
  .u-px-lg-100 {
    padding-left: 1rem !important; }
  .u-p-lg-125 {
    padding: 1.25rem !important; }
  .u-pt-lg-125,
  .u-py-lg-125 {
    padding-top: 1.25rem !important; }
  .u-pr-lg-125,
  .u-px-lg-125 {
    padding-right: 1.25rem !important; }
  .u-pb-lg-125,
  .u-py-lg-125 {
    padding-bottom: 1.25rem !important; }
  .u-pl-lg-125,
  .u-px-lg-125 {
    padding-left: 1.25rem !important; }
  .u-p-lg-150 {
    padding: 1.5rem !important; }
  .u-pt-lg-150,
  .u-py-lg-150 {
    padding-top: 1.5rem !important; }
  .u-pr-lg-150,
  .u-px-lg-150 {
    padding-right: 1.5rem !important; }
  .u-pb-lg-150,
  .u-py-lg-150 {
    padding-bottom: 1.5rem !important; }
  .u-pl-lg-150,
  .u-px-lg-150 {
    padding-left: 1.5rem !important; }
  .u-p-lg-175 {
    padding: 1.75rem !important; }
  .u-pt-lg-175,
  .u-py-lg-175 {
    padding-top: 1.75rem !important; }
  .u-pr-lg-175,
  .u-px-lg-175 {
    padding-right: 1.75rem !important; }
  .u-pb-lg-175,
  .u-py-lg-175 {
    padding-bottom: 1.75rem !important; }
  .u-pl-lg-175,
  .u-px-lg-175 {
    padding-left: 1.75rem !important; }
  .u-p-lg-200 {
    padding: 2rem !important; }
  .u-pt-lg-200,
  .u-py-lg-200 {
    padding-top: 2rem !important; }
  .u-pr-lg-200,
  .u-px-lg-200 {
    padding-right: 2rem !important; }
  .u-pb-lg-200,
  .u-py-lg-200 {
    padding-bottom: 2rem !important; }
  .u-pl-lg-200,
  .u-px-lg-200 {
    padding-left: 2rem !important; }
  .u-p-lg-225 {
    padding: 2.25rem !important; }
  .u-pt-lg-225,
  .u-py-lg-225 {
    padding-top: 2.25rem !important; }
  .u-pr-lg-225,
  .u-px-lg-225 {
    padding-right: 2.25rem !important; }
  .u-pb-lg-225,
  .u-py-lg-225 {
    padding-bottom: 2.25rem !important; }
  .u-pl-lg-225,
  .u-px-lg-225 {
    padding-left: 2.25rem !important; }
  .u-p-lg-250 {
    padding: 2.5rem !important; }
  .u-pt-lg-250,
  .u-py-lg-250 {
    padding-top: 2.5rem !important; }
  .u-pr-lg-250,
  .u-px-lg-250 {
    padding-right: 2.5rem !important; }
  .u-pb-lg-250,
  .u-py-lg-250 {
    padding-bottom: 2.5rem !important; }
  .u-pl-lg-250,
  .u-px-lg-250 {
    padding-left: 2.5rem !important; }
  .u-p-lg-275 {
    padding: 2.75rem !important; }
  .u-pt-lg-275,
  .u-py-lg-275 {
    padding-top: 2.75rem !important; }
  .u-pr-lg-275,
  .u-px-lg-275 {
    padding-right: 2.75rem !important; }
  .u-pb-lg-275,
  .u-py-lg-275 {
    padding-bottom: 2.75rem !important; }
  .u-pl-lg-275,
  .u-px-lg-275 {
    padding-left: 2.75rem !important; }
  .u-p-lg-300 {
    padding: 3rem !important; }
  .u-pt-lg-300,
  .u-py-lg-300 {
    padding-top: 3rem !important; }
  .u-pr-lg-300,
  .u-px-lg-300 {
    padding-right: 3rem !important; }
  .u-pb-lg-300,
  .u-py-lg-300 {
    padding-bottom: 3rem !important; }
  .u-pl-lg-300,
  .u-px-lg-300 {
    padding-left: 3rem !important; }
  .u-p-lg-325 {
    padding: 3.25rem !important; }
  .u-pt-lg-325,
  .u-py-lg-325 {
    padding-top: 3.25rem !important; }
  .u-pr-lg-325,
  .u-px-lg-325 {
    padding-right: 3.25rem !important; }
  .u-pb-lg-325,
  .u-py-lg-325 {
    padding-bottom: 3.25rem !important; }
  .u-pl-lg-325,
  .u-px-lg-325 {
    padding-left: 3.25rem !important; }
  .u-p-lg-350 {
    padding: 3.5rem !important; }
  .u-pt-lg-350,
  .u-py-lg-350 {
    padding-top: 3.5rem !important; }
  .u-pr-lg-350,
  .u-px-lg-350 {
    padding-right: 3.5rem !important; }
  .u-pb-lg-350,
  .u-py-lg-350 {
    padding-bottom: 3.5rem !important; }
  .u-pl-lg-350,
  .u-px-lg-350 {
    padding-left: 3.5rem !important; }
  .u-p-lg-375 {
    padding: 3.75rem !important; }
  .u-pt-lg-375,
  .u-py-lg-375 {
    padding-top: 3.75rem !important; }
  .u-pr-lg-375,
  .u-px-lg-375 {
    padding-right: 3.75rem !important; }
  .u-pb-lg-375,
  .u-py-lg-375 {
    padding-bottom: 3.75rem !important; }
  .u-pl-lg-375,
  .u-px-lg-375 {
    padding-left: 3.75rem !important; }
  .u-p-lg-400 {
    padding: 4rem !important; }
  .u-pt-lg-400,
  .u-py-lg-400 {
    padding-top: 4rem !important; }
  .u-pr-lg-400,
  .u-px-lg-400 {
    padding-right: 4rem !important; }
  .u-pb-lg-400,
  .u-py-lg-400 {
    padding-bottom: 4rem !important; }
  .u-pl-lg-400,
  .u-px-lg-400 {
    padding-left: 4rem !important; }
  .u-p-lg-425 {
    padding: 4.25rem !important; }
  .u-pt-lg-425,
  .u-py-lg-425 {
    padding-top: 4.25rem !important; }
  .u-pr-lg-425,
  .u-px-lg-425 {
    padding-right: 4.25rem !important; }
  .u-pb-lg-425,
  .u-py-lg-425 {
    padding-bottom: 4.25rem !important; }
  .u-pl-lg-425,
  .u-px-lg-425 {
    padding-left: 4.25rem !important; }
  .u-p-lg-450 {
    padding: 4.5rem !important; }
  .u-pt-lg-450,
  .u-py-lg-450 {
    padding-top: 4.5rem !important; }
  .u-pr-lg-450,
  .u-px-lg-450 {
    padding-right: 4.5rem !important; }
  .u-pb-lg-450,
  .u-py-lg-450 {
    padding-bottom: 4.5rem !important; }
  .u-pl-lg-450,
  .u-px-lg-450 {
    padding-left: 4.5rem !important; }
  .u-p-lg-475 {
    padding: 4.75rem !important; }
  .u-pt-lg-475,
  .u-py-lg-475 {
    padding-top: 4.75rem !important; }
  .u-pr-lg-475,
  .u-px-lg-475 {
    padding-right: 4.75rem !important; }
  .u-pb-lg-475,
  .u-py-lg-475 {
    padding-bottom: 4.75rem !important; }
  .u-pl-lg-475,
  .u-px-lg-475 {
    padding-left: 4.75rem !important; }
  .u-p-lg-500 {
    padding: 5rem !important; }
  .u-pt-lg-500,
  .u-py-lg-500 {
    padding-top: 5rem !important; }
  .u-pr-lg-500,
  .u-px-lg-500 {
    padding-right: 5rem !important; }
  .u-pb-lg-500,
  .u-py-lg-500 {
    padding-bottom: 5rem !important; }
  .u-pl-lg-500,
  .u-px-lg-500 {
    padding-left: 5rem !important; }
  .u-p-lg-525 {
    padding: 5.25rem !important; }
  .u-pt-lg-525,
  .u-py-lg-525 {
    padding-top: 5.25rem !important; }
  .u-pr-lg-525,
  .u-px-lg-525 {
    padding-right: 5.25rem !important; }
  .u-pb-lg-525,
  .u-py-lg-525 {
    padding-bottom: 5.25rem !important; }
  .u-pl-lg-525,
  .u-px-lg-525 {
    padding-left: 5.25rem !important; }
  .u-p-lg-550 {
    padding: 5.5rem !important; }
  .u-pt-lg-550,
  .u-py-lg-550 {
    padding-top: 5.5rem !important; }
  .u-pr-lg-550,
  .u-px-lg-550 {
    padding-right: 5.5rem !important; }
  .u-pb-lg-550,
  .u-py-lg-550 {
    padding-bottom: 5.5rem !important; }
  .u-pl-lg-550,
  .u-px-lg-550 {
    padding-left: 5.5rem !important; }
  .u-p-lg-575 {
    padding: 5.75rem !important; }
  .u-pt-lg-575,
  .u-py-lg-575 {
    padding-top: 5.75rem !important; }
  .u-pr-lg-575,
  .u-px-lg-575 {
    padding-right: 5.75rem !important; }
  .u-pb-lg-575,
  .u-py-lg-575 {
    padding-bottom: 5.75rem !important; }
  .u-pl-lg-575,
  .u-px-lg-575 {
    padding-left: 5.75rem !important; }
  .u-p-lg-600 {
    padding: 6rem !important; }
  .u-pt-lg-600,
  .u-py-lg-600 {
    padding-top: 6rem !important; }
  .u-pr-lg-600,
  .u-px-lg-600 {
    padding-right: 6rem !important; }
  .u-pb-lg-600,
  .u-py-lg-600 {
    padding-bottom: 6rem !important; }
  .u-pl-lg-600,
  .u-px-lg-600 {
    padding-left: 6rem !important; }
  .u-p-lg-625 {
    padding: 6.25rem !important; }
  .u-pt-lg-625,
  .u-py-lg-625 {
    padding-top: 6.25rem !important; }
  .u-pr-lg-625,
  .u-px-lg-625 {
    padding-right: 6.25rem !important; }
  .u-pb-lg-625,
  .u-py-lg-625 {
    padding-bottom: 6.25rem !important; }
  .u-pl-lg-625,
  .u-px-lg-625 {
    padding-left: 6.25rem !important; }
  .u-p-lg-650 {
    padding: 6.5rem !important; }
  .u-pt-lg-650,
  .u-py-lg-650 {
    padding-top: 6.5rem !important; }
  .u-pr-lg-650,
  .u-px-lg-650 {
    padding-right: 6.5rem !important; }
  .u-pb-lg-650,
  .u-py-lg-650 {
    padding-bottom: 6.5rem !important; }
  .u-pl-lg-650,
  .u-px-lg-650 {
    padding-left: 6.5rem !important; }
  .u-p-lg-675 {
    padding: 6.75rem !important; }
  .u-pt-lg-675,
  .u-py-lg-675 {
    padding-top: 6.75rem !important; }
  .u-pr-lg-675,
  .u-px-lg-675 {
    padding-right: 6.75rem !important; }
  .u-pb-lg-675,
  .u-py-lg-675 {
    padding-bottom: 6.75rem !important; }
  .u-pl-lg-675,
  .u-px-lg-675 {
    padding-left: 6.75rem !important; }
  .u-p-lg-700 {
    padding: 7rem !important; }
  .u-pt-lg-700,
  .u-py-lg-700 {
    padding-top: 7rem !important; }
  .u-pr-lg-700,
  .u-px-lg-700 {
    padding-right: 7rem !important; }
  .u-pb-lg-700,
  .u-py-lg-700 {
    padding-bottom: 7rem !important; }
  .u-pl-lg-700,
  .u-px-lg-700 {
    padding-left: 7rem !important; }
  .u-p-lg-725 {
    padding: 7.25rem !important; }
  .u-pt-lg-725,
  .u-py-lg-725 {
    padding-top: 7.25rem !important; }
  .u-pr-lg-725,
  .u-px-lg-725 {
    padding-right: 7.25rem !important; }
  .u-pb-lg-725,
  .u-py-lg-725 {
    padding-bottom: 7.25rem !important; }
  .u-pl-lg-725,
  .u-px-lg-725 {
    padding-left: 7.25rem !important; }
  .u-p-lg-750 {
    padding: 7.5rem !important; }
  .u-pt-lg-750,
  .u-py-lg-750 {
    padding-top: 7.5rem !important; }
  .u-pr-lg-750,
  .u-px-lg-750 {
    padding-right: 7.5rem !important; }
  .u-pb-lg-750,
  .u-py-lg-750 {
    padding-bottom: 7.5rem !important; }
  .u-pl-lg-750,
  .u-px-lg-750 {
    padding-left: 7.5rem !important; }
  .u-p-lg-775 {
    padding: 7.75rem !important; }
  .u-pt-lg-775,
  .u-py-lg-775 {
    padding-top: 7.75rem !important; }
  .u-pr-lg-775,
  .u-px-lg-775 {
    padding-right: 7.75rem !important; }
  .u-pb-lg-775,
  .u-py-lg-775 {
    padding-bottom: 7.75rem !important; }
  .u-pl-lg-775,
  .u-px-lg-775 {
    padding-left: 7.75rem !important; }
  .u-p-lg-800 {
    padding: 8rem !important; }
  .u-pt-lg-800,
  .u-py-lg-800 {
    padding-top: 8rem !important; }
  .u-pr-lg-800,
  .u-px-lg-800 {
    padding-right: 8rem !important; }
  .u-pb-lg-800,
  .u-py-lg-800 {
    padding-bottom: 8rem !important; }
  .u-pl-lg-800,
  .u-px-lg-800 {
    padding-left: 8rem !important; } }

@media (min-width: 1200px) {
  .u-m-xl-000 {
    margin: 0rem !important; }
  .u-mt-xl-000,
  .u-my-xl-000 {
    margin-top: 0rem !important; }
  .u-mr-xl-000,
  .u-mx-xl-000 {
    margin-right: 0rem !important; }
  .u-mb-xl-000,
  .u-my-xl-000 {
    margin-bottom: 0rem !important; }
  .u-ml-xl-000,
  .u-mx-xl-000 {
    margin-left: 0rem !important; }
  .u-m-xl-025 {
    margin: 0.25rem !important; }
  .u-mt-xl-025,
  .u-my-xl-025 {
    margin-top: 0.25rem !important; }
  .u-mr-xl-025,
  .u-mx-xl-025 {
    margin-right: 0.25rem !important; }
  .u-mb-xl-025,
  .u-my-xl-025 {
    margin-bottom: 0.25rem !important; }
  .u-ml-xl-025,
  .u-mx-xl-025 {
    margin-left: 0.25rem !important; }
  .u-m-xl-050 {
    margin: 0.5rem !important; }
  .u-mt-xl-050,
  .u-my-xl-050 {
    margin-top: 0.5rem !important; }
  .u-mr-xl-050,
  .u-mx-xl-050 {
    margin-right: 0.5rem !important; }
  .u-mb-xl-050,
  .u-my-xl-050 {
    margin-bottom: 0.5rem !important; }
  .u-ml-xl-050,
  .u-mx-xl-050 {
    margin-left: 0.5rem !important; }
  .u-m-xl-075 {
    margin: 0.75rem !important; }
  .u-mt-xl-075,
  .u-my-xl-075 {
    margin-top: 0.75rem !important; }
  .u-mr-xl-075,
  .u-mx-xl-075 {
    margin-right: 0.75rem !important; }
  .u-mb-xl-075,
  .u-my-xl-075 {
    margin-bottom: 0.75rem !important; }
  .u-ml-xl-075,
  .u-mx-xl-075 {
    margin-left: 0.75rem !important; }
  .u-m-xl-100 {
    margin: 1rem !important; }
  .u-mt-xl-100,
  .u-my-xl-100 {
    margin-top: 1rem !important; }
  .u-mr-xl-100,
  .u-mx-xl-100 {
    margin-right: 1rem !important; }
  .u-mb-xl-100,
  .u-my-xl-100 {
    margin-bottom: 1rem !important; }
  .u-ml-xl-100,
  .u-mx-xl-100 {
    margin-left: 1rem !important; }
  .u-m-xl-125 {
    margin: 1.25rem !important; }
  .u-mt-xl-125,
  .u-my-xl-125 {
    margin-top: 1.25rem !important; }
  .u-mr-xl-125,
  .u-mx-xl-125 {
    margin-right: 1.25rem !important; }
  .u-mb-xl-125,
  .u-my-xl-125 {
    margin-bottom: 1.25rem !important; }
  .u-ml-xl-125,
  .u-mx-xl-125 {
    margin-left: 1.25rem !important; }
  .u-m-xl-150 {
    margin: 1.5rem !important; }
  .u-mt-xl-150,
  .u-my-xl-150 {
    margin-top: 1.5rem !important; }
  .u-mr-xl-150,
  .u-mx-xl-150 {
    margin-right: 1.5rem !important; }
  .u-mb-xl-150,
  .u-my-xl-150 {
    margin-bottom: 1.5rem !important; }
  .u-ml-xl-150,
  .u-mx-xl-150 {
    margin-left: 1.5rem !important; }
  .u-m-xl-175 {
    margin: 1.75rem !important; }
  .u-mt-xl-175,
  .u-my-xl-175 {
    margin-top: 1.75rem !important; }
  .u-mr-xl-175,
  .u-mx-xl-175 {
    margin-right: 1.75rem !important; }
  .u-mb-xl-175,
  .u-my-xl-175 {
    margin-bottom: 1.75rem !important; }
  .u-ml-xl-175,
  .u-mx-xl-175 {
    margin-left: 1.75rem !important; }
  .u-m-xl-200 {
    margin: 2rem !important; }
  .u-mt-xl-200,
  .u-my-xl-200 {
    margin-top: 2rem !important; }
  .u-mr-xl-200,
  .u-mx-xl-200 {
    margin-right: 2rem !important; }
  .u-mb-xl-200,
  .u-my-xl-200 {
    margin-bottom: 2rem !important; }
  .u-ml-xl-200,
  .u-mx-xl-200 {
    margin-left: 2rem !important; }
  .u-m-xl-225 {
    margin: 2.25rem !important; }
  .u-mt-xl-225,
  .u-my-xl-225 {
    margin-top: 2.25rem !important; }
  .u-mr-xl-225,
  .u-mx-xl-225 {
    margin-right: 2.25rem !important; }
  .u-mb-xl-225,
  .u-my-xl-225 {
    margin-bottom: 2.25rem !important; }
  .u-ml-xl-225,
  .u-mx-xl-225 {
    margin-left: 2.25rem !important; }
  .u-m-xl-250 {
    margin: 2.5rem !important; }
  .u-mt-xl-250,
  .u-my-xl-250 {
    margin-top: 2.5rem !important; }
  .u-mr-xl-250,
  .u-mx-xl-250 {
    margin-right: 2.5rem !important; }
  .u-mb-xl-250,
  .u-my-xl-250 {
    margin-bottom: 2.5rem !important; }
  .u-ml-xl-250,
  .u-mx-xl-250 {
    margin-left: 2.5rem !important; }
  .u-m-xl-275 {
    margin: 2.75rem !important; }
  .u-mt-xl-275,
  .u-my-xl-275 {
    margin-top: 2.75rem !important; }
  .u-mr-xl-275,
  .u-mx-xl-275 {
    margin-right: 2.75rem !important; }
  .u-mb-xl-275,
  .u-my-xl-275 {
    margin-bottom: 2.75rem !important; }
  .u-ml-xl-275,
  .u-mx-xl-275 {
    margin-left: 2.75rem !important; }
  .u-m-xl-300 {
    margin: 3rem !important; }
  .u-mt-xl-300,
  .u-my-xl-300 {
    margin-top: 3rem !important; }
  .u-mr-xl-300,
  .u-mx-xl-300 {
    margin-right: 3rem !important; }
  .u-mb-xl-300,
  .u-my-xl-300 {
    margin-bottom: 3rem !important; }
  .u-ml-xl-300,
  .u-mx-xl-300 {
    margin-left: 3rem !important; }
  .u-m-xl-325 {
    margin: 3.25rem !important; }
  .u-mt-xl-325,
  .u-my-xl-325 {
    margin-top: 3.25rem !important; }
  .u-mr-xl-325,
  .u-mx-xl-325 {
    margin-right: 3.25rem !important; }
  .u-mb-xl-325,
  .u-my-xl-325 {
    margin-bottom: 3.25rem !important; }
  .u-ml-xl-325,
  .u-mx-xl-325 {
    margin-left: 3.25rem !important; }
  .u-m-xl-350 {
    margin: 3.5rem !important; }
  .u-mt-xl-350,
  .u-my-xl-350 {
    margin-top: 3.5rem !important; }
  .u-mr-xl-350,
  .u-mx-xl-350 {
    margin-right: 3.5rem !important; }
  .u-mb-xl-350,
  .u-my-xl-350 {
    margin-bottom: 3.5rem !important; }
  .u-ml-xl-350,
  .u-mx-xl-350 {
    margin-left: 3.5rem !important; }
  .u-m-xl-375 {
    margin: 3.75rem !important; }
  .u-mt-xl-375,
  .u-my-xl-375 {
    margin-top: 3.75rem !important; }
  .u-mr-xl-375,
  .u-mx-xl-375 {
    margin-right: 3.75rem !important; }
  .u-mb-xl-375,
  .u-my-xl-375 {
    margin-bottom: 3.75rem !important; }
  .u-ml-xl-375,
  .u-mx-xl-375 {
    margin-left: 3.75rem !important; }
  .u-m-xl-400 {
    margin: 4rem !important; }
  .u-mt-xl-400,
  .u-my-xl-400 {
    margin-top: 4rem !important; }
  .u-mr-xl-400,
  .u-mx-xl-400 {
    margin-right: 4rem !important; }
  .u-mb-xl-400,
  .u-my-xl-400 {
    margin-bottom: 4rem !important; }
  .u-ml-xl-400,
  .u-mx-xl-400 {
    margin-left: 4rem !important; }
  .u-m-xl-425 {
    margin: 4.25rem !important; }
  .u-mt-xl-425,
  .u-my-xl-425 {
    margin-top: 4.25rem !important; }
  .u-mr-xl-425,
  .u-mx-xl-425 {
    margin-right: 4.25rem !important; }
  .u-mb-xl-425,
  .u-my-xl-425 {
    margin-bottom: 4.25rem !important; }
  .u-ml-xl-425,
  .u-mx-xl-425 {
    margin-left: 4.25rem !important; }
  .u-m-xl-450 {
    margin: 4.5rem !important; }
  .u-mt-xl-450,
  .u-my-xl-450 {
    margin-top: 4.5rem !important; }
  .u-mr-xl-450,
  .u-mx-xl-450 {
    margin-right: 4.5rem !important; }
  .u-mb-xl-450,
  .u-my-xl-450 {
    margin-bottom: 4.5rem !important; }
  .u-ml-xl-450,
  .u-mx-xl-450 {
    margin-left: 4.5rem !important; }
  .u-m-xl-475 {
    margin: 4.75rem !important; }
  .u-mt-xl-475,
  .u-my-xl-475 {
    margin-top: 4.75rem !important; }
  .u-mr-xl-475,
  .u-mx-xl-475 {
    margin-right: 4.75rem !important; }
  .u-mb-xl-475,
  .u-my-xl-475 {
    margin-bottom: 4.75rem !important; }
  .u-ml-xl-475,
  .u-mx-xl-475 {
    margin-left: 4.75rem !important; }
  .u-m-xl-500 {
    margin: 5rem !important; }
  .u-mt-xl-500,
  .u-my-xl-500 {
    margin-top: 5rem !important; }
  .u-mr-xl-500,
  .u-mx-xl-500 {
    margin-right: 5rem !important; }
  .u-mb-xl-500,
  .u-my-xl-500 {
    margin-bottom: 5rem !important; }
  .u-ml-xl-500,
  .u-mx-xl-500 {
    margin-left: 5rem !important; }
  .u-m-xl-525 {
    margin: 5.25rem !important; }
  .u-mt-xl-525,
  .u-my-xl-525 {
    margin-top: 5.25rem !important; }
  .u-mr-xl-525,
  .u-mx-xl-525 {
    margin-right: 5.25rem !important; }
  .u-mb-xl-525,
  .u-my-xl-525 {
    margin-bottom: 5.25rem !important; }
  .u-ml-xl-525,
  .u-mx-xl-525 {
    margin-left: 5.25rem !important; }
  .u-m-xl-550 {
    margin: 5.5rem !important; }
  .u-mt-xl-550,
  .u-my-xl-550 {
    margin-top: 5.5rem !important; }
  .u-mr-xl-550,
  .u-mx-xl-550 {
    margin-right: 5.5rem !important; }
  .u-mb-xl-550,
  .u-my-xl-550 {
    margin-bottom: 5.5rem !important; }
  .u-ml-xl-550,
  .u-mx-xl-550 {
    margin-left: 5.5rem !important; }
  .u-m-xl-575 {
    margin: 5.75rem !important; }
  .u-mt-xl-575,
  .u-my-xl-575 {
    margin-top: 5.75rem !important; }
  .u-mr-xl-575,
  .u-mx-xl-575 {
    margin-right: 5.75rem !important; }
  .u-mb-xl-575,
  .u-my-xl-575 {
    margin-bottom: 5.75rem !important; }
  .u-ml-xl-575,
  .u-mx-xl-575 {
    margin-left: 5.75rem !important; }
  .u-m-xl-600 {
    margin: 6rem !important; }
  .u-mt-xl-600,
  .u-my-xl-600 {
    margin-top: 6rem !important; }
  .u-mr-xl-600,
  .u-mx-xl-600 {
    margin-right: 6rem !important; }
  .u-mb-xl-600,
  .u-my-xl-600 {
    margin-bottom: 6rem !important; }
  .u-ml-xl-600,
  .u-mx-xl-600 {
    margin-left: 6rem !important; }
  .u-m-xl-625 {
    margin: 6.25rem !important; }
  .u-mt-xl-625,
  .u-my-xl-625 {
    margin-top: 6.25rem !important; }
  .u-mr-xl-625,
  .u-mx-xl-625 {
    margin-right: 6.25rem !important; }
  .u-mb-xl-625,
  .u-my-xl-625 {
    margin-bottom: 6.25rem !important; }
  .u-ml-xl-625,
  .u-mx-xl-625 {
    margin-left: 6.25rem !important; }
  .u-m-xl-650 {
    margin: 6.5rem !important; }
  .u-mt-xl-650,
  .u-my-xl-650 {
    margin-top: 6.5rem !important; }
  .u-mr-xl-650,
  .u-mx-xl-650 {
    margin-right: 6.5rem !important; }
  .u-mb-xl-650,
  .u-my-xl-650 {
    margin-bottom: 6.5rem !important; }
  .u-ml-xl-650,
  .u-mx-xl-650 {
    margin-left: 6.5rem !important; }
  .u-m-xl-675 {
    margin: 6.75rem !important; }
  .u-mt-xl-675,
  .u-my-xl-675 {
    margin-top: 6.75rem !important; }
  .u-mr-xl-675,
  .u-mx-xl-675 {
    margin-right: 6.75rem !important; }
  .u-mb-xl-675,
  .u-my-xl-675 {
    margin-bottom: 6.75rem !important; }
  .u-ml-xl-675,
  .u-mx-xl-675 {
    margin-left: 6.75rem !important; }
  .u-m-xl-700 {
    margin: 7rem !important; }
  .u-mt-xl-700,
  .u-my-xl-700 {
    margin-top: 7rem !important; }
  .u-mr-xl-700,
  .u-mx-xl-700 {
    margin-right: 7rem !important; }
  .u-mb-xl-700,
  .u-my-xl-700 {
    margin-bottom: 7rem !important; }
  .u-ml-xl-700,
  .u-mx-xl-700 {
    margin-left: 7rem !important; }
  .u-m-xl-725 {
    margin: 7.25rem !important; }
  .u-mt-xl-725,
  .u-my-xl-725 {
    margin-top: 7.25rem !important; }
  .u-mr-xl-725,
  .u-mx-xl-725 {
    margin-right: 7.25rem !important; }
  .u-mb-xl-725,
  .u-my-xl-725 {
    margin-bottom: 7.25rem !important; }
  .u-ml-xl-725,
  .u-mx-xl-725 {
    margin-left: 7.25rem !important; }
  .u-m-xl-750 {
    margin: 7.5rem !important; }
  .u-mt-xl-750,
  .u-my-xl-750 {
    margin-top: 7.5rem !important; }
  .u-mr-xl-750,
  .u-mx-xl-750 {
    margin-right: 7.5rem !important; }
  .u-mb-xl-750,
  .u-my-xl-750 {
    margin-bottom: 7.5rem !important; }
  .u-ml-xl-750,
  .u-mx-xl-750 {
    margin-left: 7.5rem !important; }
  .u-m-xl-775 {
    margin: 7.75rem !important; }
  .u-mt-xl-775,
  .u-my-xl-775 {
    margin-top: 7.75rem !important; }
  .u-mr-xl-775,
  .u-mx-xl-775 {
    margin-right: 7.75rem !important; }
  .u-mb-xl-775,
  .u-my-xl-775 {
    margin-bottom: 7.75rem !important; }
  .u-ml-xl-775,
  .u-mx-xl-775 {
    margin-left: 7.75rem !important; }
  .u-m-xl-800 {
    margin: 8rem !important; }
  .u-mt-xl-800,
  .u-my-xl-800 {
    margin-top: 8rem !important; }
  .u-mr-xl-800,
  .u-mx-xl-800 {
    margin-right: 8rem !important; }
  .u-mb-xl-800,
  .u-my-xl-800 {
    margin-bottom: 8rem !important; }
  .u-ml-xl-800,
  .u-mx-xl-800 {
    margin-left: 8rem !important; }
  .u-p-xl-000 {
    padding: 0rem !important; }
  .u-pt-xl-000,
  .u-py-xl-000 {
    padding-top: 0rem !important; }
  .u-pr-xl-000,
  .u-px-xl-000 {
    padding-right: 0rem !important; }
  .u-pb-xl-000,
  .u-py-xl-000 {
    padding-bottom: 0rem !important; }
  .u-pl-xl-000,
  .u-px-xl-000 {
    padding-left: 0rem !important; }
  .u-p-xl-025 {
    padding: 0.25rem !important; }
  .u-pt-xl-025,
  .u-py-xl-025 {
    padding-top: 0.25rem !important; }
  .u-pr-xl-025,
  .u-px-xl-025 {
    padding-right: 0.25rem !important; }
  .u-pb-xl-025,
  .u-py-xl-025 {
    padding-bottom: 0.25rem !important; }
  .u-pl-xl-025,
  .u-px-xl-025 {
    padding-left: 0.25rem !important; }
  .u-p-xl-050 {
    padding: 0.5rem !important; }
  .u-pt-xl-050,
  .u-py-xl-050 {
    padding-top: 0.5rem !important; }
  .u-pr-xl-050,
  .u-px-xl-050 {
    padding-right: 0.5rem !important; }
  .u-pb-xl-050,
  .u-py-xl-050 {
    padding-bottom: 0.5rem !important; }
  .u-pl-xl-050,
  .u-px-xl-050 {
    padding-left: 0.5rem !important; }
  .u-p-xl-075 {
    padding: 0.75rem !important; }
  .u-pt-xl-075,
  .u-py-xl-075 {
    padding-top: 0.75rem !important; }
  .u-pr-xl-075,
  .u-px-xl-075 {
    padding-right: 0.75rem !important; }
  .u-pb-xl-075,
  .u-py-xl-075 {
    padding-bottom: 0.75rem !important; }
  .u-pl-xl-075,
  .u-px-xl-075 {
    padding-left: 0.75rem !important; }
  .u-p-xl-100 {
    padding: 1rem !important; }
  .u-pt-xl-100,
  .u-py-xl-100 {
    padding-top: 1rem !important; }
  .u-pr-xl-100,
  .u-px-xl-100 {
    padding-right: 1rem !important; }
  .u-pb-xl-100,
  .u-py-xl-100 {
    padding-bottom: 1rem !important; }
  .u-pl-xl-100,
  .u-px-xl-100 {
    padding-left: 1rem !important; }
  .u-p-xl-125 {
    padding: 1.25rem !important; }
  .u-pt-xl-125,
  .u-py-xl-125 {
    padding-top: 1.25rem !important; }
  .u-pr-xl-125,
  .u-px-xl-125 {
    padding-right: 1.25rem !important; }
  .u-pb-xl-125,
  .u-py-xl-125 {
    padding-bottom: 1.25rem !important; }
  .u-pl-xl-125,
  .u-px-xl-125 {
    padding-left: 1.25rem !important; }
  .u-p-xl-150 {
    padding: 1.5rem !important; }
  .u-pt-xl-150,
  .u-py-xl-150 {
    padding-top: 1.5rem !important; }
  .u-pr-xl-150,
  .u-px-xl-150 {
    padding-right: 1.5rem !important; }
  .u-pb-xl-150,
  .u-py-xl-150 {
    padding-bottom: 1.5rem !important; }
  .u-pl-xl-150,
  .u-px-xl-150 {
    padding-left: 1.5rem !important; }
  .u-p-xl-175 {
    padding: 1.75rem !important; }
  .u-pt-xl-175,
  .u-py-xl-175 {
    padding-top: 1.75rem !important; }
  .u-pr-xl-175,
  .u-px-xl-175 {
    padding-right: 1.75rem !important; }
  .u-pb-xl-175,
  .u-py-xl-175 {
    padding-bottom: 1.75rem !important; }
  .u-pl-xl-175,
  .u-px-xl-175 {
    padding-left: 1.75rem !important; }
  .u-p-xl-200 {
    padding: 2rem !important; }
  .u-pt-xl-200,
  .u-py-xl-200 {
    padding-top: 2rem !important; }
  .u-pr-xl-200,
  .u-px-xl-200 {
    padding-right: 2rem !important; }
  .u-pb-xl-200,
  .u-py-xl-200 {
    padding-bottom: 2rem !important; }
  .u-pl-xl-200,
  .u-px-xl-200 {
    padding-left: 2rem !important; }
  .u-p-xl-225 {
    padding: 2.25rem !important; }
  .u-pt-xl-225,
  .u-py-xl-225 {
    padding-top: 2.25rem !important; }
  .u-pr-xl-225,
  .u-px-xl-225 {
    padding-right: 2.25rem !important; }
  .u-pb-xl-225,
  .u-py-xl-225 {
    padding-bottom: 2.25rem !important; }
  .u-pl-xl-225,
  .u-px-xl-225 {
    padding-left: 2.25rem !important; }
  .u-p-xl-250 {
    padding: 2.5rem !important; }
  .u-pt-xl-250,
  .u-py-xl-250 {
    padding-top: 2.5rem !important; }
  .u-pr-xl-250,
  .u-px-xl-250 {
    padding-right: 2.5rem !important; }
  .u-pb-xl-250,
  .u-py-xl-250 {
    padding-bottom: 2.5rem !important; }
  .u-pl-xl-250,
  .u-px-xl-250 {
    padding-left: 2.5rem !important; }
  .u-p-xl-275 {
    padding: 2.75rem !important; }
  .u-pt-xl-275,
  .u-py-xl-275 {
    padding-top: 2.75rem !important; }
  .u-pr-xl-275,
  .u-px-xl-275 {
    padding-right: 2.75rem !important; }
  .u-pb-xl-275,
  .u-py-xl-275 {
    padding-bottom: 2.75rem !important; }
  .u-pl-xl-275,
  .u-px-xl-275 {
    padding-left: 2.75rem !important; }
  .u-p-xl-300 {
    padding: 3rem !important; }
  .u-pt-xl-300,
  .u-py-xl-300 {
    padding-top: 3rem !important; }
  .u-pr-xl-300,
  .u-px-xl-300 {
    padding-right: 3rem !important; }
  .u-pb-xl-300,
  .u-py-xl-300 {
    padding-bottom: 3rem !important; }
  .u-pl-xl-300,
  .u-px-xl-300 {
    padding-left: 3rem !important; }
  .u-p-xl-325 {
    padding: 3.25rem !important; }
  .u-pt-xl-325,
  .u-py-xl-325 {
    padding-top: 3.25rem !important; }
  .u-pr-xl-325,
  .u-px-xl-325 {
    padding-right: 3.25rem !important; }
  .u-pb-xl-325,
  .u-py-xl-325 {
    padding-bottom: 3.25rem !important; }
  .u-pl-xl-325,
  .u-px-xl-325 {
    padding-left: 3.25rem !important; }
  .u-p-xl-350 {
    padding: 3.5rem !important; }
  .u-pt-xl-350,
  .u-py-xl-350 {
    padding-top: 3.5rem !important; }
  .u-pr-xl-350,
  .u-px-xl-350 {
    padding-right: 3.5rem !important; }
  .u-pb-xl-350,
  .u-py-xl-350 {
    padding-bottom: 3.5rem !important; }
  .u-pl-xl-350,
  .u-px-xl-350 {
    padding-left: 3.5rem !important; }
  .u-p-xl-375 {
    padding: 3.75rem !important; }
  .u-pt-xl-375,
  .u-py-xl-375 {
    padding-top: 3.75rem !important; }
  .u-pr-xl-375,
  .u-px-xl-375 {
    padding-right: 3.75rem !important; }
  .u-pb-xl-375,
  .u-py-xl-375 {
    padding-bottom: 3.75rem !important; }
  .u-pl-xl-375,
  .u-px-xl-375 {
    padding-left: 3.75rem !important; }
  .u-p-xl-400 {
    padding: 4rem !important; }
  .u-pt-xl-400,
  .u-py-xl-400 {
    padding-top: 4rem !important; }
  .u-pr-xl-400,
  .u-px-xl-400 {
    padding-right: 4rem !important; }
  .u-pb-xl-400,
  .u-py-xl-400 {
    padding-bottom: 4rem !important; }
  .u-pl-xl-400,
  .u-px-xl-400 {
    padding-left: 4rem !important; }
  .u-p-xl-425 {
    padding: 4.25rem !important; }
  .u-pt-xl-425,
  .u-py-xl-425 {
    padding-top: 4.25rem !important; }
  .u-pr-xl-425,
  .u-px-xl-425 {
    padding-right: 4.25rem !important; }
  .u-pb-xl-425,
  .u-py-xl-425 {
    padding-bottom: 4.25rem !important; }
  .u-pl-xl-425,
  .u-px-xl-425 {
    padding-left: 4.25rem !important; }
  .u-p-xl-450 {
    padding: 4.5rem !important; }
  .u-pt-xl-450,
  .u-py-xl-450 {
    padding-top: 4.5rem !important; }
  .u-pr-xl-450,
  .u-px-xl-450 {
    padding-right: 4.5rem !important; }
  .u-pb-xl-450,
  .u-py-xl-450 {
    padding-bottom: 4.5rem !important; }
  .u-pl-xl-450,
  .u-px-xl-450 {
    padding-left: 4.5rem !important; }
  .u-p-xl-475 {
    padding: 4.75rem !important; }
  .u-pt-xl-475,
  .u-py-xl-475 {
    padding-top: 4.75rem !important; }
  .u-pr-xl-475,
  .u-px-xl-475 {
    padding-right: 4.75rem !important; }
  .u-pb-xl-475,
  .u-py-xl-475 {
    padding-bottom: 4.75rem !important; }
  .u-pl-xl-475,
  .u-px-xl-475 {
    padding-left: 4.75rem !important; }
  .u-p-xl-500 {
    padding: 5rem !important; }
  .u-pt-xl-500,
  .u-py-xl-500 {
    padding-top: 5rem !important; }
  .u-pr-xl-500,
  .u-px-xl-500 {
    padding-right: 5rem !important; }
  .u-pb-xl-500,
  .u-py-xl-500 {
    padding-bottom: 5rem !important; }
  .u-pl-xl-500,
  .u-px-xl-500 {
    padding-left: 5rem !important; }
  .u-p-xl-525 {
    padding: 5.25rem !important; }
  .u-pt-xl-525,
  .u-py-xl-525 {
    padding-top: 5.25rem !important; }
  .u-pr-xl-525,
  .u-px-xl-525 {
    padding-right: 5.25rem !important; }
  .u-pb-xl-525,
  .u-py-xl-525 {
    padding-bottom: 5.25rem !important; }
  .u-pl-xl-525,
  .u-px-xl-525 {
    padding-left: 5.25rem !important; }
  .u-p-xl-550 {
    padding: 5.5rem !important; }
  .u-pt-xl-550,
  .u-py-xl-550 {
    padding-top: 5.5rem !important; }
  .u-pr-xl-550,
  .u-px-xl-550 {
    padding-right: 5.5rem !important; }
  .u-pb-xl-550,
  .u-py-xl-550 {
    padding-bottom: 5.5rem !important; }
  .u-pl-xl-550,
  .u-px-xl-550 {
    padding-left: 5.5rem !important; }
  .u-p-xl-575 {
    padding: 5.75rem !important; }
  .u-pt-xl-575,
  .u-py-xl-575 {
    padding-top: 5.75rem !important; }
  .u-pr-xl-575,
  .u-px-xl-575 {
    padding-right: 5.75rem !important; }
  .u-pb-xl-575,
  .u-py-xl-575 {
    padding-bottom: 5.75rem !important; }
  .u-pl-xl-575,
  .u-px-xl-575 {
    padding-left: 5.75rem !important; }
  .u-p-xl-600 {
    padding: 6rem !important; }
  .u-pt-xl-600,
  .u-py-xl-600 {
    padding-top: 6rem !important; }
  .u-pr-xl-600,
  .u-px-xl-600 {
    padding-right: 6rem !important; }
  .u-pb-xl-600,
  .u-py-xl-600 {
    padding-bottom: 6rem !important; }
  .u-pl-xl-600,
  .u-px-xl-600 {
    padding-left: 6rem !important; }
  .u-p-xl-625 {
    padding: 6.25rem !important; }
  .u-pt-xl-625,
  .u-py-xl-625 {
    padding-top: 6.25rem !important; }
  .u-pr-xl-625,
  .u-px-xl-625 {
    padding-right: 6.25rem !important; }
  .u-pb-xl-625,
  .u-py-xl-625 {
    padding-bottom: 6.25rem !important; }
  .u-pl-xl-625,
  .u-px-xl-625 {
    padding-left: 6.25rem !important; }
  .u-p-xl-650 {
    padding: 6.5rem !important; }
  .u-pt-xl-650,
  .u-py-xl-650 {
    padding-top: 6.5rem !important; }
  .u-pr-xl-650,
  .u-px-xl-650 {
    padding-right: 6.5rem !important; }
  .u-pb-xl-650,
  .u-py-xl-650 {
    padding-bottom: 6.5rem !important; }
  .u-pl-xl-650,
  .u-px-xl-650 {
    padding-left: 6.5rem !important; }
  .u-p-xl-675 {
    padding: 6.75rem !important; }
  .u-pt-xl-675,
  .u-py-xl-675 {
    padding-top: 6.75rem !important; }
  .u-pr-xl-675,
  .u-px-xl-675 {
    padding-right: 6.75rem !important; }
  .u-pb-xl-675,
  .u-py-xl-675 {
    padding-bottom: 6.75rem !important; }
  .u-pl-xl-675,
  .u-px-xl-675 {
    padding-left: 6.75rem !important; }
  .u-p-xl-700 {
    padding: 7rem !important; }
  .u-pt-xl-700,
  .u-py-xl-700 {
    padding-top: 7rem !important; }
  .u-pr-xl-700,
  .u-px-xl-700 {
    padding-right: 7rem !important; }
  .u-pb-xl-700,
  .u-py-xl-700 {
    padding-bottom: 7rem !important; }
  .u-pl-xl-700,
  .u-px-xl-700 {
    padding-left: 7rem !important; }
  .u-p-xl-725 {
    padding: 7.25rem !important; }
  .u-pt-xl-725,
  .u-py-xl-725 {
    padding-top: 7.25rem !important; }
  .u-pr-xl-725,
  .u-px-xl-725 {
    padding-right: 7.25rem !important; }
  .u-pb-xl-725,
  .u-py-xl-725 {
    padding-bottom: 7.25rem !important; }
  .u-pl-xl-725,
  .u-px-xl-725 {
    padding-left: 7.25rem !important; }
  .u-p-xl-750 {
    padding: 7.5rem !important; }
  .u-pt-xl-750,
  .u-py-xl-750 {
    padding-top: 7.5rem !important; }
  .u-pr-xl-750,
  .u-px-xl-750 {
    padding-right: 7.5rem !important; }
  .u-pb-xl-750,
  .u-py-xl-750 {
    padding-bottom: 7.5rem !important; }
  .u-pl-xl-750,
  .u-px-xl-750 {
    padding-left: 7.5rem !important; }
  .u-p-xl-775 {
    padding: 7.75rem !important; }
  .u-pt-xl-775,
  .u-py-xl-775 {
    padding-top: 7.75rem !important; }
  .u-pr-xl-775,
  .u-px-xl-775 {
    padding-right: 7.75rem !important; }
  .u-pb-xl-775,
  .u-py-xl-775 {
    padding-bottom: 7.75rem !important; }
  .u-pl-xl-775,
  .u-px-xl-775 {
    padding-left: 7.75rem !important; }
  .u-p-xl-800 {
    padding: 8rem !important; }
  .u-pt-xl-800,
  .u-py-xl-800 {
    padding-top: 8rem !important; }
  .u-pr-xl-800,
  .u-px-xl-800 {
    padding-right: 8rem !important; }
  .u-pb-xl-800,
  .u-py-xl-800 {
    padding-bottom: 8rem !important; }
  .u-pl-xl-800,
  .u-px-xl-800 {
    padding-left: 8rem !important; } }

.u-list-style--disc {
  list-style: disc;
  padding-left: 30px; }

.u-list-style--decimal {
  list-style: decimal;
  padding-left: 30px; }

.u-list-style-position--inside {
  list-style-position: inside; }

.u-list-style-position--outside {
  list-style-position: outside; }

.u-link--underline {
  text-decoration: underline; }
  .u-link--underline:hover {
    text-decoration: underline; }

.u-link-white {
  color: #fff; }
  .u-link-white:hover {
    color: #fff; }
  .u-link-white.u-link__hover--darken-10:hover {
    color: #e6e6e6; }
  .u-link-white.u-link__hover--lighten-10:hover {
    color: white; }

.u-link-black {
  color: #000; }
  .u-link-black:hover {
    color: #000; }
  .u-link-black.u-link__hover--darken-10:hover {
    color: black; }
  .u-link-black.u-link__hover--lighten-10:hover {
    color: #1a1a1a; }

.u-link-gray-100 {
  color: #f5f5f5; }
  .u-link-gray-100:hover {
    color: #f5f5f5; }
  .u-link-gray-100.u-link__hover--darken-10:hover {
    color: gainsboro; }
  .u-link-gray-100.u-link__hover--lighten-10:hover {
    color: white; }

.u-link-gray-200 {
  color: #eeeeee; }
  .u-link-gray-200:hover {
    color: #eeeeee; }
  .u-link-gray-200.u-link__hover--darken-10:hover {
    color: #d5d5d5; }
  .u-link-gray-200.u-link__hover--lighten-10:hover {
    color: white; }

.u-link-gray-300 {
  color: #e0e0e0; }
  .u-link-gray-300:hover {
    color: #e0e0e0; }
  .u-link-gray-300.u-link__hover--darken-10:hover {
    color: #c7c7c7; }
  .u-link-gray-300.u-link__hover--lighten-10:hover {
    color: #fafafa; }

.u-link-gray-400 {
  color: #bdbdbd; }
  .u-link-gray-400:hover {
    color: #bdbdbd; }
  .u-link-gray-400.u-link__hover--darken-10:hover {
    color: #a4a4a4; }
  .u-link-gray-400.u-link__hover--lighten-10:hover {
    color: #d7d7d7; }

.u-link-gray-500 {
  color: #9e9e9e; }
  .u-link-gray-500:hover {
    color: #9e9e9e; }
  .u-link-gray-500.u-link__hover--darken-10:hover {
    color: #858585; }
  .u-link-gray-500.u-link__hover--lighten-10:hover {
    color: #b8b8b8; }

.u-link-gray-600 {
  color: #757575; }
  .u-link-gray-600:hover {
    color: #757575; }
  .u-link-gray-600.u-link__hover--darken-10:hover {
    color: #5c5c5c; }
  .u-link-gray-600.u-link__hover--lighten-10:hover {
    color: #8f8f8f; }

.u-link-gray-700 {
  color: #616161; }
  .u-link-gray-700:hover {
    color: #616161; }
  .u-link-gray-700.u-link__hover--darken-10:hover {
    color: #484848; }
  .u-link-gray-700.u-link__hover--lighten-10:hover {
    color: #7b7b7b; }

.u-link-gray-800 {
  color: #424242; }
  .u-link-gray-800:hover {
    color: #424242; }
  .u-link-gray-800.u-link__hover--darken-10:hover {
    color: #292929; }
  .u-link-gray-800.u-link__hover--lighten-10:hover {
    color: #5c5c5c; }

.u-link-gray-900 {
  color: #212121; }
  .u-link-gray-900:hover {
    color: #212121; }
  .u-link-gray-900.u-link__hover--darken-10:hover {
    color: #080808; }
  .u-link-gray-900.u-link__hover--lighten-10:hover {
    color: #3b3b3b; }

.u-link-primary {
  color: #821115; }
  .u-link-primary:hover {
    color: #821115; }
  .u-link-primary.u-link__hover--darken-10:hover {
    color: #550b0e; }
  .u-link-primary.u-link__hover--lighten-10:hover {
    color: #af171c; }

.u-link-secondary {
  color: #961117; }
  .u-link-secondary:hover {
    color: #961117; }
  .u-link-secondary.u-link__hover--darken-10:hover {
    color: #680c10; }
  .u-link-secondary.u-link__hover--lighten-10:hover {
    color: #c4161e; }

.u-link-gold01 {
  color: #F7E3B8; }
  .u-link-gold01:hover {
    color: #F7E3B8; }
  .u-link-gold01.u-link__hover--darken-10:hover {
    color: #f2d18a; }
  .u-link-gold01.u-link__hover--lighten-10:hover {
    color: #fcf5e6; }

.u-border-white {
  border: 1px solid #fff; }

.u-border-black {
  border: 1px solid #000; }

.u-border-gray-100 {
  border: 1px solid #f5f5f5; }

.u-border-gray-200 {
  border: 1px solid #eeeeee; }

.u-border-gray-300 {
  border: 1px solid #e0e0e0; }

.u-border-gray-400 {
  border: 1px solid #bdbdbd; }

.u-border-gray-500 {
  border: 1px solid #9e9e9e; }

.u-border-gray-600 {
  border: 1px solid #757575; }

.u-border-gray-700 {
  border: 1px solid #616161; }

.u-border-gray-800 {
  border: 1px solid #424242; }

.u-border-gray-900 {
  border: 1px solid #212121; }

.u-border-primary {
  border: 1px solid #821115; }

.u-border-secondary {
  border: 1px solid #961117; }

.u-border-gold01 {
  border: 1px solid #F7E3B8; }

html {
  image-rendering: -webkit-optimize-contrast; }

ul {
  list-style: none;
  padding-left: 0; }

button {
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 151, 252, 0.3); }
  @media (min-width: 768px) {
    button {
      -webkit-tap-highlight-color: transparent; } }

a {
  -webkit-tap-highlight-color: rgba(0, 151, 252, 0.3); }
  @media (min-width: 768px) {
    a {
      -webkit-tap-highlight-color: transparent; } }
  a:hover {
    text-decoration: none; }

body.js-useMouse button:focus,
body.js-useMouse a:focus,
body.js-useMouse select:focus,
body.js-useMouse input:focus {
  outline: none; }

html.js-menuOpened {
  height: 100%;
  overflow: hidden; }

.l-header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: #821115;
  box-shadow: 6px 6px 6px rgba(38, 10, 10, 0.3); }
  .l-header::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    z-index: 999;
    background-color: #821115;
    pointer-events: none; }
    @media (min-width: 992px) {
      .l-header::before {
        background-color: transparent; } }
  .l-header-layout {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative; }
    @media (min-width: 992px) {
      .l-header-layout {
        justify-content: space-between; } }
  .l-header-logo {
    display: inline-block;
    position: relative;
    z-index: 1001; }
    .l-header-logo-img {
      width: 120px;
      height: 42px; }
  .l-header-hamburger {
    width: 30px;
    height: 18px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 1rem;
    z-index: 1001; }
    @media (min-width: 992px) {
      .l-header-hamburger {
        display: none; } }
    .l-header-hamburger-bar {
      width: 100%;
      height: 1px;
      background-color: #fff;
      position: absolute;
      transition: top 0.3s ease-out, transform 0.3s ease-out, opacity 0.3s ease-out; }
      .l-header-hamburger-bar__top {
        top: 0;
        left: 0; }
      .l-header-hamburger-bar__middle {
        left: 0;
        top: 50%; }
      .l-header-hamburger-bar__bottom {
        left: 0;
        top: 100%; }
    .l-header-hamburger.js-menuOpened .l-header-hamburger-bar__top {
      top: 50%;
      transform: rotate(45deg); }
    .l-header-hamburger.js-menuOpened .l-header-hamburger-bar__middle {
      opacity: 0; }
    .l-header-hamburger.js-menuOpened .l-header-hamburger-bar__bottom {
      top: 50%;
      transform: rotate(-45deg); }
  .l-header-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF1DF;
    padding: 90px 0 15px 0;
    overflow: hidden;
    z-index: 990;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out; }
    .l-header-menu.js-menuOpened {
      opacity: 1;
      visibility: visible; }
    @media (min-width: 992px) {
      .l-header-menu {
        position: static;
        padding: 0;
        background-color: transparent;
        overflow: visible;
        opacity: 1;
        visibility: visible; } }
    .l-header-menu-container {
      padding: 0 15px; }
    .l-header-menu-layout {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-bottom: 0;
      justify-content: space-between; }
      @media (min-width: 992px) {
        .l-header-menu-layout {
          flex-direction: row; } }
    .l-header-menu-link {
      align-items: center;
      color: #821115;
      text-decoration: none;
      position: relative;
      line-height: 36px;
      font-size: 1.25rem;
      padding: 1rem 0; }
      @media (min-width: 992px) {
        .l-header-menu-link {
          padding: 0; } }
      .l-header-menu-link:hover {
        text-decoration: none;
        color: #821115;
        opacity: 0.8; }
      @media (min-width: 992px) {
        .l-header-menu-link {
          line-height: 72px;
          border-bottom: none;
          color: #fff; }
          .l-header-menu-link:hover {
            color: #fff; } }
      .l-header-menu-link i {
        width: 20px;
        text-align: center; }
      .l-header-menu-link__hover--underline {
        display: flex; }
        @media (min-width: 992px) {
          .l-header-menu-link__hover--underline {
            margin: 0 1rem;
            display: inline-flex; }
            .l-header-menu-link__hover--underline:before {
              content: "";
              display: block;
              width: 0;
              height: 1px;
              position: absolute;
              bottom: 0;
              left: 0;
              background-color: #fff;
              transition: all 0.25s ease-in-out;
              bottom: 25%; }
            .l-header-menu-link__hover--underline:hover:before, .l-header-menu-link__hover--underline:focus:before {
              width: 100%; } }
  .l-header-icon-bottle {
    width: 20px; }
  .l-header-goTop {
    position: fixed;
    bottom: 2%;
    right: 1rem;
    width: 40px;
    opacity: 0;
    transition: all 0.3s; }
    .l-header-goTop.js-show {
      opacity: 1; }

.l-footer {
  background-color: #961117; }

.c-btn {
  display: inline-block;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .c-btn--shadow {
    box-shadow: 3px 3px 5px #bdbdbd; }
  .c-btn--rounded {
    border-radius: 50px; }
  .c-btn--sharp {
    border-radius: 0; }
  .c-btn--block {
    display: block;
    width: 100%; }

.c-btn--contained.c-btn-white {
  background-color: #fff;
  color: #fff; }
  .c-btn--contained.c-btn-white:hover, .c-btn--contained.c-btn-white.js-btnHover {
    background-color: #e6e6e6;
    color: #fff; }
  .c-btn--contained.c-btn-white:focus, .c-btn--contained.c-btn-white.js-btnFocus {
    background-color: #e6e6e6;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5); }
  .c-btn--contained.c-btn-white:active, .c-btn--contained.c-btn-white.js-btnActive {
    background-color: #cccccc;
    color: #fff; }
  .c-btn--contained.c-btn-white:disabled, .c-btn--contained.c-btn-white.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-white {
  color: #fff;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-white:hover, .c-btn--outlined.c-btn-white.js-btnHover {
    border-color: #fff;
    background-color: #fff;
    color: #fff; }
  .c-btn--outlined.c-btn-white:focus, .c-btn--outlined.c-btn-white.js-btnFocus {
    border-color: #fff;
    background-color: #fff;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5); }
  .c-btn--outlined.c-btn-white:active, .c-btn--outlined.c-btn-white.js-btnActive {
    border-color: #e6e6e6;
    background-color: #e6e6e6;
    color: #fff; }
  .c-btn--outlined.c-btn-white:disabled, .c-btn--outlined.c-btn-white.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--contained.c-btn-black {
  background-color: #000;
  color: #fff; }
  .c-btn--contained.c-btn-black:hover, .c-btn--contained.c-btn-black.js-btnHover {
    background-color: black;
    color: #fff; }
  .c-btn--contained.c-btn-black:focus, .c-btn--contained.c-btn-black.js-btnFocus {
    background-color: black;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5); }
  .c-btn--contained.c-btn-black:active, .c-btn--contained.c-btn-black.js-btnActive {
    background-color: black;
    color: #fff; }
  .c-btn--contained.c-btn-black:disabled, .c-btn--contained.c-btn-black.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-black {
  color: #000;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-black:hover, .c-btn--outlined.c-btn-black.js-btnHover {
    border-color: #000;
    background-color: #000;
    color: #fff; }
  .c-btn--outlined.c-btn-black:focus, .c-btn--outlined.c-btn-black.js-btnFocus {
    border-color: #000;
    background-color: #000;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5); }
  .c-btn--outlined.c-btn-black:active, .c-btn--outlined.c-btn-black.js-btnActive {
    border-color: black;
    background-color: black;
    color: #fff; }
  .c-btn--outlined.c-btn-black:disabled, .c-btn--outlined.c-btn-black.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--contained.c-btn-gray-100 {
  background-color: #f5f5f5;
  color: #fff; }
  .c-btn--contained.c-btn-gray-100:hover, .c-btn--contained.c-btn-gray-100.js-btnHover {
    background-color: gainsboro;
    color: #fff; }
  .c-btn--contained.c-btn-gray-100:focus, .c-btn--contained.c-btn-gray-100.js-btnFocus {
    background-color: gainsboro;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(245, 245, 245, 0.5); }
  .c-btn--contained.c-btn-gray-100:active, .c-btn--contained.c-btn-gray-100.js-btnActive {
    background-color: #c2c2c2;
    color: #fff; }
  .c-btn--contained.c-btn-gray-100:disabled, .c-btn--contained.c-btn-gray-100.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-gray-100 {
  color: #f5f5f5;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-gray-100:hover, .c-btn--outlined.c-btn-gray-100.js-btnHover {
    border-color: #f5f5f5;
    background-color: #f5f5f5;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-100:focus, .c-btn--outlined.c-btn-gray-100.js-btnFocus {
    border-color: #f5f5f5;
    background-color: #f5f5f5;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(245, 245, 245, 0.5); }
  .c-btn--outlined.c-btn-gray-100:active, .c-btn--outlined.c-btn-gray-100.js-btnActive {
    border-color: gainsboro;
    background-color: gainsboro;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-100:disabled, .c-btn--outlined.c-btn-gray-100.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--contained.c-btn-gray-200 {
  background-color: #eeeeee;
  color: #fff; }
  .c-btn--contained.c-btn-gray-200:hover, .c-btn--contained.c-btn-gray-200.js-btnHover {
    background-color: #d5d5d5;
    color: #fff; }
  .c-btn--contained.c-btn-gray-200:focus, .c-btn--contained.c-btn-gray-200.js-btnFocus {
    background-color: #d5d5d5;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(238, 238, 238, 0.5); }
  .c-btn--contained.c-btn-gray-200:active, .c-btn--contained.c-btn-gray-200.js-btnActive {
    background-color: #bbbbbb;
    color: #fff; }
  .c-btn--contained.c-btn-gray-200:disabled, .c-btn--contained.c-btn-gray-200.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-gray-200 {
  color: #eeeeee;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-gray-200:hover, .c-btn--outlined.c-btn-gray-200.js-btnHover {
    border-color: #eeeeee;
    background-color: #eeeeee;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-200:focus, .c-btn--outlined.c-btn-gray-200.js-btnFocus {
    border-color: #eeeeee;
    background-color: #eeeeee;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(238, 238, 238, 0.5); }
  .c-btn--outlined.c-btn-gray-200:active, .c-btn--outlined.c-btn-gray-200.js-btnActive {
    border-color: #d5d5d5;
    background-color: #d5d5d5;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-200:disabled, .c-btn--outlined.c-btn-gray-200.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--contained.c-btn-gray-300 {
  background-color: #e0e0e0;
  color: #fff; }
  .c-btn--contained.c-btn-gray-300:hover, .c-btn--contained.c-btn-gray-300.js-btnHover {
    background-color: #c7c7c7;
    color: #fff; }
  .c-btn--contained.c-btn-gray-300:focus, .c-btn--contained.c-btn-gray-300.js-btnFocus {
    background-color: #c7c7c7;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(224, 224, 224, 0.5); }
  .c-btn--contained.c-btn-gray-300:active, .c-btn--contained.c-btn-gray-300.js-btnActive {
    background-color: #adadad;
    color: #fff; }
  .c-btn--contained.c-btn-gray-300:disabled, .c-btn--contained.c-btn-gray-300.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-gray-300 {
  color: #e0e0e0;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-gray-300:hover, .c-btn--outlined.c-btn-gray-300.js-btnHover {
    border-color: #e0e0e0;
    background-color: #e0e0e0;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-300:focus, .c-btn--outlined.c-btn-gray-300.js-btnFocus {
    border-color: #e0e0e0;
    background-color: #e0e0e0;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(224, 224, 224, 0.5); }
  .c-btn--outlined.c-btn-gray-300:active, .c-btn--outlined.c-btn-gray-300.js-btnActive {
    border-color: #c7c7c7;
    background-color: #c7c7c7;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-300:disabled, .c-btn--outlined.c-btn-gray-300.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--contained.c-btn-gray-400 {
  background-color: #bdbdbd;
  color: #fff; }
  .c-btn--contained.c-btn-gray-400:hover, .c-btn--contained.c-btn-gray-400.js-btnHover {
    background-color: #a4a4a4;
    color: #fff; }
  .c-btn--contained.c-btn-gray-400:focus, .c-btn--contained.c-btn-gray-400.js-btnFocus {
    background-color: #a4a4a4;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(189, 189, 189, 0.5); }
  .c-btn--contained.c-btn-gray-400:active, .c-btn--contained.c-btn-gray-400.js-btnActive {
    background-color: #8a8a8a;
    color: #fff; }
  .c-btn--contained.c-btn-gray-400:disabled, .c-btn--contained.c-btn-gray-400.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-gray-400 {
  color: #bdbdbd;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-gray-400:hover, .c-btn--outlined.c-btn-gray-400.js-btnHover {
    border-color: #bdbdbd;
    background-color: #bdbdbd;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-400:focus, .c-btn--outlined.c-btn-gray-400.js-btnFocus {
    border-color: #bdbdbd;
    background-color: #bdbdbd;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(189, 189, 189, 0.5); }
  .c-btn--outlined.c-btn-gray-400:active, .c-btn--outlined.c-btn-gray-400.js-btnActive {
    border-color: #a4a4a4;
    background-color: #a4a4a4;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-400:disabled, .c-btn--outlined.c-btn-gray-400.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--contained.c-btn-gray-500 {
  background-color: #9e9e9e;
  color: #fff; }
  .c-btn--contained.c-btn-gray-500:hover, .c-btn--contained.c-btn-gray-500.js-btnHover {
    background-color: #858585;
    color: #fff; }
  .c-btn--contained.c-btn-gray-500:focus, .c-btn--contained.c-btn-gray-500.js-btnFocus {
    background-color: #858585;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(158, 158, 158, 0.5); }
  .c-btn--contained.c-btn-gray-500:active, .c-btn--contained.c-btn-gray-500.js-btnActive {
    background-color: #6b6b6b;
    color: #fff; }
  .c-btn--contained.c-btn-gray-500:disabled, .c-btn--contained.c-btn-gray-500.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-gray-500 {
  color: #9e9e9e;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-gray-500:hover, .c-btn--outlined.c-btn-gray-500.js-btnHover {
    border-color: #9e9e9e;
    background-color: #9e9e9e;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-500:focus, .c-btn--outlined.c-btn-gray-500.js-btnFocus {
    border-color: #9e9e9e;
    background-color: #9e9e9e;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(158, 158, 158, 0.5); }
  .c-btn--outlined.c-btn-gray-500:active, .c-btn--outlined.c-btn-gray-500.js-btnActive {
    border-color: #858585;
    background-color: #858585;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-500:disabled, .c-btn--outlined.c-btn-gray-500.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--contained.c-btn-gray-600 {
  background-color: #757575;
  color: #fff; }
  .c-btn--contained.c-btn-gray-600:hover, .c-btn--contained.c-btn-gray-600.js-btnHover {
    background-color: #5c5c5c;
    color: #fff; }
  .c-btn--contained.c-btn-gray-600:focus, .c-btn--contained.c-btn-gray-600.js-btnFocus {
    background-color: #5c5c5c;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(117, 117, 117, 0.5); }
  .c-btn--contained.c-btn-gray-600:active, .c-btn--contained.c-btn-gray-600.js-btnActive {
    background-color: #424242;
    color: #fff; }
  .c-btn--contained.c-btn-gray-600:disabled, .c-btn--contained.c-btn-gray-600.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-gray-600 {
  color: #757575;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-gray-600:hover, .c-btn--outlined.c-btn-gray-600.js-btnHover {
    border-color: #757575;
    background-color: #757575;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-600:focus, .c-btn--outlined.c-btn-gray-600.js-btnFocus {
    border-color: #757575;
    background-color: #757575;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(117, 117, 117, 0.5); }
  .c-btn--outlined.c-btn-gray-600:active, .c-btn--outlined.c-btn-gray-600.js-btnActive {
    border-color: #5c5c5c;
    background-color: #5c5c5c;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-600:disabled, .c-btn--outlined.c-btn-gray-600.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--contained.c-btn-gray-700 {
  background-color: #616161;
  color: #fff; }
  .c-btn--contained.c-btn-gray-700:hover, .c-btn--contained.c-btn-gray-700.js-btnHover {
    background-color: #484848;
    color: #fff; }
  .c-btn--contained.c-btn-gray-700:focus, .c-btn--contained.c-btn-gray-700.js-btnFocus {
    background-color: #484848;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(97, 97, 97, 0.5); }
  .c-btn--contained.c-btn-gray-700:active, .c-btn--contained.c-btn-gray-700.js-btnActive {
    background-color: #2e2e2e;
    color: #fff; }
  .c-btn--contained.c-btn-gray-700:disabled, .c-btn--contained.c-btn-gray-700.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-gray-700 {
  color: #616161;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-gray-700:hover, .c-btn--outlined.c-btn-gray-700.js-btnHover {
    border-color: #616161;
    background-color: #616161;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-700:focus, .c-btn--outlined.c-btn-gray-700.js-btnFocus {
    border-color: #616161;
    background-color: #616161;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(97, 97, 97, 0.5); }
  .c-btn--outlined.c-btn-gray-700:active, .c-btn--outlined.c-btn-gray-700.js-btnActive {
    border-color: #484848;
    background-color: #484848;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-700:disabled, .c-btn--outlined.c-btn-gray-700.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--contained.c-btn-gray-800 {
  background-color: #424242;
  color: #fff; }
  .c-btn--contained.c-btn-gray-800:hover, .c-btn--contained.c-btn-gray-800.js-btnHover {
    background-color: #292929;
    color: #fff; }
  .c-btn--contained.c-btn-gray-800:focus, .c-btn--contained.c-btn-gray-800.js-btnFocus {
    background-color: #292929;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.5); }
  .c-btn--contained.c-btn-gray-800:active, .c-btn--contained.c-btn-gray-800.js-btnActive {
    background-color: #0f0f0f;
    color: #fff; }
  .c-btn--contained.c-btn-gray-800:disabled, .c-btn--contained.c-btn-gray-800.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-gray-800 {
  color: #424242;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-gray-800:hover, .c-btn--outlined.c-btn-gray-800.js-btnHover {
    border-color: #424242;
    background-color: #424242;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-800:focus, .c-btn--outlined.c-btn-gray-800.js-btnFocus {
    border-color: #424242;
    background-color: #424242;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(66, 66, 66, 0.5); }
  .c-btn--outlined.c-btn-gray-800:active, .c-btn--outlined.c-btn-gray-800.js-btnActive {
    border-color: #292929;
    background-color: #292929;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-800:disabled, .c-btn--outlined.c-btn-gray-800.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--contained.c-btn-gray-900 {
  background-color: #212121;
  color: #fff; }
  .c-btn--contained.c-btn-gray-900:hover, .c-btn--contained.c-btn-gray-900.js-btnHover {
    background-color: #080808;
    color: #fff; }
  .c-btn--contained.c-btn-gray-900:focus, .c-btn--contained.c-btn-gray-900.js-btnFocus {
    background-color: #080808;
    color: #fff;
    outline: none;
    box-shadow: 0 0 0 4px rgba(33, 33, 33, 0.5); }
  .c-btn--contained.c-btn-gray-900:active, .c-btn--contained.c-btn-gray-900.js-btnActive {
    background-color: black;
    color: #fff; }
  .c-btn--contained.c-btn-gray-900:disabled, .c-btn--contained.c-btn-gray-900.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

.c-btn--outlined.c-btn-gray-900 {
  color: #212121;
  border-color: #9e9e9e; }
  .c-btn--outlined.c-btn-gray-900:hover, .c-btn--outlined.c-btn-gray-900.js-btnHover {
    border-color: #212121;
    background-color: #212121;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-900:focus, .c-btn--outlined.c-btn-gray-900.js-btnFocus {
    border-color: #212121;
    background-color: #212121;
    color: #fff;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(33, 33, 33, 0.5); }
  .c-btn--outlined.c-btn-gray-900:active, .c-btn--outlined.c-btn-gray-900.js-btnActive {
    border-color: #080808;
    background-color: #080808;
    color: #fff; }
  .c-btn--outlined.c-btn-gray-900:disabled, .c-btn--outlined.c-btn-gray-900.js-btnDisabled {
    opacity: 0.3;
    pointer-events: none; }

@media (min-width: 992px) {
  .c-title-main-01 .c-title-main-subtitle {
    max-width: 960px; } }

@media (min-width: 992px) {
  .c-title-main-04 .c-title-main-title {
    width: 100%;
    max-width: 770px; } }

.c-title-main-title {
  max-width: 400px; }

.c-title-h3 {
  font-size: 1.5rem;
  font-weight: normal;
  position: relative; }
  .c-title-h3::before {
    content: "";
    width: 51px;
    height: 2px;
    background-color: #333;
    position: absolute;
    bottom: -1rem;
    left: 0; }

.c-title-winner {
  background: linear-gradient(180deg, #8F181E 0%, #C11F27 100%); }

.c-articleTable {
  width: 100%; }
  .c-articleTable a {
    color: #000;
    text-decoration: none; }
  .c-articleTable thead tr {
    background-color: #fff; }
    .c-articleTable thead tr th {
      vertical-align: middle; }
      .c-articleTable thead tr th > * {
        padding: 0.75rem;
        display: inline-block; }
  .c-articleTable tbody tr {
    background-color: #fff; }
    .c-articleTable tbody tr th > *,
    .c-articleTable tbody tr td > * {
      padding: 0.75rem;
      display: inline-block;
      margin-bottom: 0; }
  .c-articleTable-date {
    width: 120px;
    position: relative; }
  .c-articleTable-rwd {
    width: 100%; }
    .c-articleTable-rwd a {
      color: #FFF1DF;
      text-decoration: none; }
    .c-articleTable-rwd thead {
      /* 手機版隱藏表頭列 */
      display: none; }
      @media (min-width: 768px) {
        .c-articleTable-rwd thead {
          display: table-header-group; } }
      .c-articleTable-rwd thead tr th {
        vertical-align: middle; }
        .c-articleTable-rwd thead tr th > * {
          padding: 0.75rem;
          display: inline-block;
          margin-bottom: 0; }
    .c-articleTable-rwd tbody tr {
      display: block;
      margin-bottom: 3rem;
      /*手機版推出空間用*/ }
      @media (min-width: 768px) {
        .c-articleTable-rwd tbody tr {
          display: table-row; } }
      .c-articleTable-rwd tbody tr th,
      .c-articleTable-rwd tbody tr td {
        display: table;
        /*手機版讓他們改成橫式排列*/
        width: 100%;
        word-break: break-all; }
        .c-articleTable-rwd tbody tr th > *,
        .c-articleTable-rwd tbody tr td > * {
          padding: 0.75rem;
          display: inline-block;
          letter-spacing: 2px; }
        @media (min-width: 768px) {
          .c-articleTable-rwd tbody tr th,
          .c-articleTable-rwd tbody tr td {
            display: table-cell;
            width: auto; } }
        .c-articleTable-rwd tbody tr th[data-th],
        .c-articleTable-rwd tbody tr td[data-th] {
          /* 把表頭寫成data-th之後，利用Before的方式插入在td內*/ }
          .c-articleTable-rwd tbody tr th[data-th]:before,
          .c-articleTable-rwd tbody tr td[data-th]:before {
            display: table-cell;
            content: "" attr(data-th);
            font-weight: 500;
            width: 6rem;
            height: 100%;
            vertical-align: middle;
            padding: 0.75rem;
            word-break: break-all;
            font-weight: bold;
            color: #821115; }
            @media (min-width: 768px) {
              .c-articleTable-rwd tbody tr th[data-th]:before,
              .c-articleTable-rwd tbody tr td[data-th]:before {
                display: none; } }
  .c-articleTable--bordered__all.c-articleTable thead tr th,
  .c-articleTable--bordered__all.c-articleTable thead tr td,
  .c-articleTable--bordered__all.c-articleTable tbody tr th,
  .c-articleTable--bordered__all.c-articleTable tbody tr td {
    border: 1px solid #000;
    border-right: 0;
    border-bottom: 0; }
    .c-articleTable--bordered__all.c-articleTable thead tr th:last-child,
    .c-articleTable--bordered__all.c-articleTable thead tr td:last-child,
    .c-articleTable--bordered__all.c-articleTable tbody tr th:last-child,
    .c-articleTable--bordered__all.c-articleTable tbody tr td:last-child {
      border-right: 1px solid #000; }
    .c-articleTable--bordered__all.c-articleTable thead tr th[data-th],
    .c-articleTable--bordered__all.c-articleTable thead tr td[data-th],
    .c-articleTable--bordered__all.c-articleTable tbody tr th[data-th],
    .c-articleTable--bordered__all.c-articleTable tbody tr td[data-th] {
      /*把表頭寫成data-th之後，利用Before的方式插入在td內*/ }
      .c-articleTable--bordered__all.c-articleTable thead tr th[data-th]:before,
      .c-articleTable--bordered__all.c-articleTable thead tr td[data-th]:before,
      .c-articleTable--bordered__all.c-articleTable tbody tr th[data-th]:before,
      .c-articleTable--bordered__all.c-articleTable tbody tr td[data-th]:before {
        border-right: 1px solid #000; }
  .c-articleTable--bordered__all.c-articleTable tbody tr:last-child > * {
    border-bottom: 1px solid #000; }
  @media (min-width: 992px) {
    .c-articleTable--bordered__all.c-articleTable .c-articleTable-date::before {
      content: "";
      border-right: 1px solid #000;
      height: 100%;
      width: 1px;
      position: absolute;
      right: -2px;
      top: 0;
      z-index: 5; } }
  .c-articleTable--bordered__all.c-articleTable-rwd thead tr th,
  .c-articleTable--bordered__all.c-articleTable-rwd thead tr td,
  .c-articleTable--bordered__all.c-articleTable-rwd tbody tr th,
  .c-articleTable--bordered__all.c-articleTable-rwd tbody tr td {
    border: 1px solid #000;
    border-bottom: 0; }
    @media (min-width: 992px) {
      .c-articleTable--bordered__all.c-articleTable-rwd thead tr th,
      .c-articleTable--bordered__all.c-articleTable-rwd thead tr td,
      .c-articleTable--bordered__all.c-articleTable-rwd tbody tr th,
      .c-articleTable--bordered__all.c-articleTable-rwd tbody tr td {
        border-right: 0; } }
    .c-articleTable--bordered__all.c-articleTable-rwd thead tr th:last-child,
    .c-articleTable--bordered__all.c-articleTable-rwd thead tr td:last-child,
    .c-articleTable--bordered__all.c-articleTable-rwd tbody tr th:last-child,
    .c-articleTable--bordered__all.c-articleTable-rwd tbody tr td:last-child {
      border-right: 1px solid #000;
      border-bottom: 1px solid #000; }
      @media (min-width: 992px) {
        .c-articleTable--bordered__all.c-articleTable-rwd thead tr th:last-child,
        .c-articleTable--bordered__all.c-articleTable-rwd thead tr td:last-child,
        .c-articleTable--bordered__all.c-articleTable-rwd tbody tr th:last-child,
        .c-articleTable--bordered__all.c-articleTable-rwd tbody tr td:last-child {
          border-bottom: 0px; } }
    .c-articleTable--bordered__all.c-articleTable-rwd thead tr th[data-th],
    .c-articleTable--bordered__all.c-articleTable-rwd thead tr td[data-th],
    .c-articleTable--bordered__all.c-articleTable-rwd tbody tr th[data-th],
    .c-articleTable--bordered__all.c-articleTable-rwd tbody tr td[data-th] {
      /* 把表頭寫成data-th之後，利用Before的方式插入在td內*/ }
      .c-articleTable--bordered__all.c-articleTable-rwd thead tr th[data-th]:before,
      .c-articleTable--bordered__all.c-articleTable-rwd thead tr td[data-th]:before,
      .c-articleTable--bordered__all.c-articleTable-rwd tbody tr th[data-th]:before,
      .c-articleTable--bordered__all.c-articleTable-rwd tbody tr td[data-th]:before {
        border-right: 1px solid #000; }
  .c-articleTable--bordered__all.c-articleTable-rwd tbody tr th, .c-articleTable--bordered__all.c-articleTable-rwd tbody tr th:last-child,
  .c-articleTable--bordered__all.c-articleTable-rwd tbody tr td,
  .c-articleTable--bordered__all.c-articleTable-rwd tbody tr td:last-child {
    border-bottom: 0px; }
  @media (min-width: 992px) {
    .c-articleTable--bordered__all.c-articleTable-rwd tbody tr:last-child th,
    .c-articleTable--bordered__all.c-articleTable-rwd tbody tr:last-child td {
      border-bottom: 1px solid #000; } }
  .c-articleTable--bordered__all.c-articleTable-rwd tbody tr:last-child th:last-child,
  .c-articleTable--bordered__all.c-articleTable-rwd tbody tr:last-child td:last-child {
    border-bottom: 1px solid #000; }
  @media (min-width: 319px) {
    .c-articleTable--bordered__all.c-articleTable-rwd .c-articleTable-date::before {
      content: "";
      border-right: 1px solid #000;
      height: 100%;
      width: 1px;
      position: absolute;
      right: -2px;
      top: 0;
      z-index: 5; } }
  .c-articleTable--bordered__bottom thead tr th {
    border-bottom: 1px solid #616161;
    padding-top: 0.5rem;
    font-size: 1.375rem; }
  .c-articleTable--bordered__bottom tbody tr th,
  .c-articleTable--bordered__bottom tbody tr td {
    border-bottom: 1px solid #616161; }

@media (min-width: 576px) {
  .c-winnerTable thead tr th:last-child {
    width: 140px; } }

@media (min-width: 992px) {
  .c-winnerTable thead tr th:last-child {
    width: 230px; } }

.c-winnerTable thead tr th, .c-winnerTable thead tr td, .c-winnerTable tbody tr th, .c-winnerTable tbody tr td {
  color: #FFF1DF;
  border-bottom: 1px solid #FFF1DF; }

.c-winnerTable tbody tr th[data-th], .c-winnerTable tbody tr td[data-th] {
  /* 把表頭寫成data-th之後，利用Before的方式插入在td內*/ }
  .c-winnerTable tbody tr th[data-th]:before, .c-winnerTable tbody tr td[data-th]:before {
    display: table-cell;
    content: "" attr(data-th);
    font-weight: 500;
    width: 4rem;
    height: 100%;
    vertical-align: middle;
    padding: 0.75rem;
    word-break: break-all;
    font-weight: bold;
    color: #FFF1DF; }
    @media (min-width: 768px) {
      .c-winnerTable tbody tr th[data-th]:before, .c-winnerTable tbody tr td[data-th]:before {
        display: none; } }

.p-kv {
  background: -moz-linear-gradient(7.5% -29.27% -25.9deg, #691218 0%, #a5151b 50%, #691218 100%);
  background: -webkit-linear-gradient(-25.9deg, #691218 0%, #a5151b 50%, #691218 100%);
  background: -webkit-gradient(linear, 7.5% -29.27%, 101.72% 102.07%, color-stop(0, #691218), color-stop(0.5, #a5151b), color-stop(1, #691218));
  background: -o-linear-gradient(-25.9deg, #691218 0%, #a5151b 50%, #691218 100%);
  background: -ms-linear-gradient(-25.9deg, #691218 0%, #a5151b 50%, #691218 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#691218', endColorstr='#691218' ,GradientType=0)";
  background: linear-gradient(115.9deg, #691218 0%, #a5151b 50%, #691218 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#691218', endColorstr='#691218', GradientType=1);
  height: 500px;
  position: relative;
  margin-top: 72px;
  overflow: hidden; }
  @media (max-width: 400px) {
    .p-kv {
      height: 440px; } }

.p-kvPatten {
  background-image: url("../img/bgPatten.png");
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .p-kvPatten-02 {
    opacity: 0.6; }

.p-kvContent-tiger {
  width: 50%;
  position: absolute;
  right: 0;
  top: -26%; }

.p-kvContent-tiger02 {
  width: 50%;
  position: absolute;
  right: 0;
  top: -43%; }

.p-kvContent-tigerSlogan {
  width: 596px;
  position: relative;
  left: -1rem; }

.p-kvContent-hairSlogan {
  width: 549px;
  position: relative;
  left: 1.5rem; }
  @media (min-width: 768px) {
    .p-kvContent-hairSlogan {
      left: 3.4rem; } }

.p-kvFirework {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .p-kvFirework-img {
    position: absolute;
    transform: translate(-50%, -50%) scale(0); }
  .p-kvFirework-01 {
    width: 17%;
    top: 0%;
    left: 16%;
    animation-name: fireWork;
    animation-duration: 3s;
    animation-delay: 2s;
    animation-iteration-count: infinite; }
    @media (max-width: 400px) {
      .p-kvFirework-01 {
        width: 37%; } }
  .p-kvFirework-02 {
    width: 20%;
    top: 50%;
    left: 0;
    animation-name: fireWork;
    animation-duration: 3s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite; }
    @media (max-width: 400px) {
      .p-kvFirework-02 {
        width: 40%; } }
  .p-kvFirework-03 {
    width: 20%;
    top: -5%;
    left: 41%;
    animation-name: fireWork;
    animation-duration: 3s;
    animation-delay: 5s;
    animation-iteration-count: infinite; }
    @media (max-width: 400px) {
      .p-kvFirework-03 {
        width: 40%;
        top: 29%;
        left: 75%; } }
  .p-kvFirework-04 {
    width: 10%;
    top: 25%;
    left: 25%;
    animation-name: fireWork;
    animation-duration: 3s;
    animation-delay: 0s;
    animation-iteration-count: infinite; }
    @media (max-width: 400px) {
      .p-kvFirework-04 {
        width: 30%;
        top: 32%;
        left: 33%; } }
    @media (min-width: 992px) {
      .p-kvFirework-04 {
        top: 40%; } }
  .p-kvFirework-05 {
    width: 7%;
    top: 80%;
    left: 18%;
    animation-name: fireWork;
    animation-duration: 3s;
    animation-delay: 1s;
    animation-iteration-count: infinite; }
    @media (max-width: 400px) {
      .p-kvFirework-05 {
        width: 37%;
        top: 89%;
        left: -5%; } }
  .p-kvFirework-06 {
    top: 0;
    width: 10%;
    right: 26%;
    animation-name: fireWork;
    animation-duration: 3s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite; }
    @media (max-width: 400px) {
      .p-kvFirework-06 {
        width: 30%;
        right: 10%; } }
  .p-kvFirework-07 {
    top: 12%;
    width: 16%;
    right: 2%;
    animation-name: fireWork;
    animation-duration: 3s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite; }
    @media (max-width: 400px) {
      .p-kvFirework-07 {
        width: 36%;
        right: -38%; } }
  .p-kvFirework-08 {
    right: 13%;
    width: 9%;
    bottom: -14%;
    animation-name: fireWork;
    animation-duration: 3s;
    animation-delay: 0.8s;
    animation-iteration-count: infinite; }
    @media (max-width: 400px) {
      .p-kvFirework-08 {
        width: 29%;
        bottom: -23%; } }
  .p-kvFirework-09 {
    bottom: -8%;
    width: 13%;
    right: -5%;
    animation-name: fireWork;
    animation-duration: 3s;
    animation-delay: 5s;
    animation-iteration-count: infinite; }
    @media (max-width: 400px) {
      .p-kvFirework-09 {
        width: 33%;
        right: -36%; } }

.p-patten {
  position: absolute; }
  .p-patten-01 {
    width: 80px;
    top: -40px;
    left: 0px; }
    @media (min-width: 576px) {
      .p-patten-01 {
        width: 120px;
        top: -60px;
        left: -24px; } }
    @media (min-width: 768px) {
      .p-patten-01 {
        width: 120px;
        top: -60px;
        left: -24px; } }
    @media (min-width: 992px) {
      .p-patten-01 {
        width: 150px;
        top: -70px;
        left: -24px; } }
    @media (min-width: 1200px) {
      .p-patten-01 {
        top: -90px;
        left: -38px; } }
  .p-patten-02 {
    width: 80px;
    top: -25px;
    right: 0px; }
    @media (min-width: 576px) {
      .p-patten-02 {
        width: 120px;
        top: -30px;
        right: -24px; } }
    @media (min-width: 768px) {
      .p-patten-02 {
        width: 120px;
        top: -40px;
        right: -24px; } }
    @media (min-width: 992px) {
      .p-patten-02 {
        width: 150px;
        top: -50px;
        right: -24px; } }
    @media (min-width: 1200px) {
      .p-patten-02 {
        top: -50px;
        right: -38px; } }

.p-deco {
  position: absolute; }
  .p-deco-cloud-01 {
    width: 222px;
    left: -80px;
    top: 0; }
  .p-deco-cloud-02 {
    width: 222px;
    right: -22%;
    top: 47%; }
  .p-deco-cloud-03 {
    width: 222px;
    top: 77%;
    right: -12%; }
    @media (max-width: 575px) {
      .p-deco-cloud-03 {
        top: 96%; } }
    @media (min-width: 1200px) {
      .p-deco-cloud-03 {
        right: 1rem; } }
  .p-deco-cloud-04 {
    width: 222px;
    right: 1rem;
    bottom: 1rem; }
    @media (min-width: 1200px) {
      .p-deco-cloud-04 {
        bottom: 15%; } }
  .p-deco-firework-01 {
    width: 135px;
    right: -20%;
    top: 40px; }
  .p-deco-firework-02 {
    width: 135px;
    left: -20%;
    top: 40px; }
  .p-deco-firework-03 {
    width: 135px;
    left: 1rem;
    top: -25%; }
  .p-deco-firework-04 {
    width: 150px;
    left: -10%;
    top: -32%; }
  .p-deco-firework-05 {
    width: 161px;
    left: -41%;
    top: 0%; }
  .p-deco-firework-06 {
    width: 150px;
    right: -31%;
    top: -39%; }
  .p-deco-firework-07 {
    width: 107px;
    right: -47%;
    top: 22%; }

.p-method {
  line-height: 2; }
  .p-method-gift {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1; }
    .p-method-gift-01 {
      width: 15%;
      right: 20px; }
    .p-method-gift-02 {
      width: 30%;
      bottom: -5px; }
    .p-method-gift-03 {
      width: 35%;
      right: -10px; }
    .p-method-gift-04 {
      width: 18%;
      right: 15px; }

.p-notice-content {
  height: 16rem;
  line-height: 2; }
  .p-notice-content.js-active {
    height: auto; }

.p-notice-arrow {
  width: 20px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -30px; }
  .p-notice-arrow.js-active {
    transform: translateX(-50%) rotate(180deg); }

.p-polygon-bottom {
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
  z-index: 1; }
  @media (min-width: 576px) {
    .p-polygon-bottom {
      top: calc(100% - 1px); } }
  @media (max-width: 450px) {
    .p-polygon-bottom {
      top: calc(100% - 3px); } }
  @media (max-width: 400px) {
    .p-polygon-bottom {
      top: calc(100% - 4px); } }
  @media (max-width: 380px) {
    .p-polygon-bottom {
      top: calc(100% - 6px); } }

.p-polygon-top {
  position: absolute;
  bottom: calc(100% - 1px);
  left: 0;
  z-index: 1; }

.p-video {
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;
  height: 100%; }

.v-tab {
  flex-wrap: nowrap;
  white-space: nowrap; }
  .v-tab .nav-link {
    border-radius: 0;
    font-size: 1.25rem;
    font-weight: bold;
    color: #FFF1DF;
    border: 1px solid #FFF1DF; }
    @media (max-width: 380px) {
      .v-tab .nav-link {
        padding: .5rem .75rem; } }
    .v-tab .nav-link:hover {
      color: #821115;
      background: linear-gradient(91.05deg, #FDE8D5 1.01%, #CAA93F 98.66%); }
  .v-tab .nav-link.active,
  .v-tab .show > .nav-link {
    color: #821115;
    background: linear-gradient(91.05deg, #FDE8D5 1.01%, #CAA93F 98.66%); }

@-moz-document url-prefix() {}
