// 針對 html 標籤做基本設定
html{
  image-rendering: -webkit-optimize-contrast;
}
ul {
  list-style: none;
  padding-left: 0;
}
button {
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(rgb(0, 151, 252), 0.3);
  @include min-md {
    -webkit-tap-highlight-color: transparent;
  }
}
a {
  -webkit-tap-highlight-color: rgba(rgb(0, 151, 252), 0.3);
  @include min-md {
    -webkit-tap-highlight-color: transparent;
  }
  &:hover {
    text-decoration: none;
  }
}
