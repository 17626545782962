.c- {
    &articleTable {
        width: 100%;

        a {
            color: $black;
            text-decoration: none;
        }

        thead {
            tr {
                background-color: $white;

                th {
                    vertical-align: middle;

                    >* {
                        padding: 0.75rem;
                        display: inline-block;
                        
                    }
                }
            }
        }

        tbody {
            tr {
                background-color: $white;

                th,
                td {
                    >* {
                        padding: 0.75rem;
                        display: inline-block;
                        margin-bottom: 0;
                        
                    }
                }
            }
        }

        &-date {
            width: 120px;
            position: relative;
        }

        &-rwd {
            // background-color: $white;
            width: 100%;

            a {
                color: $third;
                text-decoration: none;
            }

            thead {
                /* 手機版隱藏表頭列 */
                display: none;

                @include min-md {
                    display: table-header-group;
                }

                tr {
                    th {
                        vertical-align: middle;

                        >* {
                            padding: 0.75rem;
                            display: inline-block;
                            
                            margin-bottom: 0;
                        }
                    }
                }
            }

            tbody {

                tr {
                    display: block;
                    margin-bottom: 3rem;
                    /*手機版推出空間用*/

                    @include min-md {
                        display: table-row;
                    }

                    th,
                    td {
                        display: table;
                        /*手機版讓他們改成橫式排列*/
                        width: 100%;
                        word-break: break-all;

                        >* {
                            padding: 0.75rem;
                            display: inline-block;
                            letter-spacing: 2px;
                            
                        }

                        @include min-md {
                            display: table-cell;
                            width: auto;
                        }

                        &[data-th] {

                            /* 把表頭寫成data-th之後，利用Before的方式插入在td內*/
                            &:before {
                                display: table-cell;
                                content: ""attr(data-th);
                                font-weight: 500;
                                width: 6rem;
                                height: 100%;
                                vertical-align: middle;
                                padding: 0.75rem;
                                word-break: break-all;
                                font-weight: bold;
                                color: $primary;

                                @include min-md {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        &--bordered {
            &__all {
                &.c-articleTable {

                    thead,
                    tbody {
                        tr {

                            th,
                            td {
                                border: 1px solid $black;
                                border-right: 0;
                                border-bottom: 0;

                                &:last-child {
                                    border-right: 1px solid $black;
                                }

                                &[data-th] {

                                    /*把表頭寫成data-th之後，利用Before的方式插入在td內*/
                                    &:before {
                                        border-right: 1px solid $black;
                                    }
                                }
                            }
                        }
                    }

                    tbody {
                        tr:last-child {
                            >* {
                                border-bottom: 1px solid $black;
                            }
                        }
                    }

                    .c-articleTable-date {
                        @media (min-width:992px) {
                            &::before {
                                content: "";
                                border-right: 1px solid $black;
                                height: 100%;
                                width: 1px;
                                position: absolute;
                                right: -2px;
                                top: 0;
                                z-index: 5;
                            }
                        }
                    }
                }

                &.c-articleTable-rwd {

                    thead,
                    tbody {
                        tr {

                            th,
                            td {
                                border: 1px solid $black;
                                border-bottom: 0;

                                @include min-lg {
                                    border-right: 0;
                                }

                                &:last-child {
                                    border-right: 1px solid $black;
                                    border-bottom: 1px solid $black;

                                    @include min-lg {
                                        border-bottom: 0px;
                                    }
                                }

                                &[data-th] {

                                    /* 把表頭寫成data-th之後，利用Before的方式插入在td內*/
                                    &:before {
                                        border-right: 1px solid $black;
                                    }
                                }
                            }
                        }
                    }

                    tbody {
                        tr {

                            th,
                            td {

                                &,
                                &:last-child {
                                    border-bottom: 0px;
                                }
                            }

                            &:last-child {

                                th,
                                td {
                                    @include min-lg {
                                        border-bottom: 1px solid $black;
                                    }

                                    &:last-child {
                                        border-bottom: 1px solid $black;
                                    }
                                }
                            }
                        }
                    }

                    .c-articleTable-date {
                        @media (min-width:319px) {
                            &::before {
                                content: "";
                                border-right: 1px solid $black;
                                height: 100%;
                                width: 1px;
                                position: absolute;
                                right: -2px;
                                top: 0;
                                z-index: 5;
                            }
                        }
                    }
                }
            }

            &__bottom {

                thead {
                    tr {
                        th {
                            border-bottom: 1px solid $gray-700;
                            padding-top: 0.5rem;
                            font-size: $font-22;
                        }
                    }
                }

                tbody {
                    tr {

                        th,
                        td {
                            border-bottom: 1px solid $gray-700;
                        }
                    }
                }
            }
        }


    }
    &winnerTable{
        thead{
            tr{
                th:last-child{
                    @include min-sm{
                        width: 140px;
                    }
                    @include min-lg{
                        width: 230px;
                    }
                }
            }
        }
        thead,tbody{
            tr{
                th,td {
                    color: $third;
                    border-bottom: 1px solid $third;
                }
            }
        }
        tbody{
            tr{
                th,td{
                    &[data-th] {
                        /* 把表頭寫成data-th之後，利用Before的方式插入在td內*/
                        &:before {
                            display: table-cell;
                            content: ""attr(data-th);
                            font-weight: 500;
                            width: 4rem;
                            height: 100%;
                            vertical-align: middle;
                            padding: 0.75rem;
                            word-break: break-all;
                            font-weight: bold;
                            color: $third;

                            @include min-md {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}