.c- {
    &title {
        &-main{
            &-01{
                .c-title-main-subtitle{
                    @include min-lg{
                        max-width: 960px;
                    }
                }
            }
            &-04{
                .c-title-main-title{
                    @include min-lg{
                        width: 100%;
                        max-width: 770px;
                    }
                }
            }
        }
        &-main-title{
            max-width: 400px;
        }
        &-h3 {
            font-size: $font-24;
            font-weight: normal;
            position: relative;

            &::before {
                content: "";
                width: 51px;
                height: 2px;
                background-color: #333;
                position: absolute;
                bottom: -1rem;
                left: 0;
            }
        }
        &-winner{
            background: linear-gradient(180deg, #8F181E 0%, #C11F27 100%);
        }
    }
}