//page .p-{className}
.p- {
    &kv {
        background: -moz-linear-gradient(7.5% -29.27% -25.9deg, rgba(105, 18, 24, 1) 0%, rgba(165, 21, 27, 1) 50%, rgba(105, 18, 24, 1) 100%);
        background: -webkit-linear-gradient(-25.9deg, rgba(105, 18, 24, 1) 0%, rgba(165, 21, 27, 1) 50%, rgba(105, 18, 24, 1) 100%);
        background: -webkit-gradient(linear, 7.5% -29.27%, 101.72% 102.07%, color-stop(0, rgba(105, 18, 24, 1)), color-stop(0.5, rgba(165, 21, 27, 1)), color-stop(1, rgba(105, 18, 24, 1)));
        background: -o-linear-gradient(-25.9deg, rgba(105, 18, 24, 1) 0%, rgba(165, 21, 27, 1) 50%, rgba(105, 18, 24, 1) 100%);
        background: -ms-linear-gradient(-25.9deg, rgba(105, 18, 24, 1) 0%, rgba(165, 21, 27, 1) 50%, rgba(105, 18, 24, 1) 100%);
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#691218', endColorstr='#691218' ,GradientType=0)";
        background: linear-gradient(115.9deg, rgba(105, 18, 24, 1) 0%, rgba(165, 21, 27, 1) 50%, rgba(105, 18, 24, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#691218', endColorstr='#691218', GradientType=1);
        height: 500px;
        position: relative;
        margin-top: 72px;
        overflow: hidden;

        @include max-400 {
            height: 440px;
        }
    }

    &kvPatten {
        background-image: url('../img/bgPatten.png');
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &-02{
            opacity: 0.6;
        }
    }

    &kvContent {
        &-tiger {
            width: 50%;
            position: absolute;
            right: 0;
            top: -26%;
        }

        &-tiger02 {
            width: 50%;
            position: absolute;
            right: 0;
            top: -43%;
        }

        &-tigerSlogan {
            width: 596px;
            position: relative;
            left: -1rem;
        }

        &-hairSlogan {
            width: 549px;
            position: relative;
            left: 1.5rem;

            @include min-md {
                left: 3.4rem;
            }
        }
    }

    &kvFirework {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &-img {
            position: absolute;
            transform: translate(-50%, -50%) scale(0);
        }

        &-01 {
            width: 17%;
            top: 0%;
            left: 16%;
            @include fireWork($delay: 2s);

            @include max-400 {
                width: 37%;
            }
        }

        &-02 {
            width: 20%;
            top: 50%;
            left: 0;
            @include fireWork($delay: .5s);

            @include max-400 {
                width: 40%;
            }
        }

        &-03 {
            width: 20%;
            top: -5%;
            left: 41%;
            @include fireWork($delay: 5s);

            @include max-400 {
                width: 40%;
                top: 29%;
                left: 75%;
            }
        }

        &-04 {
            width: 10%;
            top: 25%;
            left: 25%;
            @include fireWork($delay: 0s);

            @include max-400 {
                width: 30%;
                top: 32%;
                left: 33%;
            }

            @include min-lg {
                top: 40%;
            }
        }

        &-05 {
            width: 7%;
            top: 80%;
            left: 18%;
            @include fireWork($delay: 1s);

            @include max-400 {
                width: 37%;
                top: 89%;
                left: -5%;
            }
        }

        &-06 {
            top: 0;
            width: 10%;
            right: 26%;
            @include fireWork($delay: .2s);

            @include max-400 {
                width: 30%;
                right: 10%;
            }
        }

        &-07 {
            top: 12%;
            width: 16%;
            right: 2%;
            @include fireWork($delay: .5s);

            @include max-400 {
                width: 36%;
                right: -38%;
            }
        }

        &-08 {
            right: 13%;
            width: 9%;
            bottom: -14%;
            @include fireWork($delay: .8s);

            @include max-400 {
                width: 29%;
                bottom: -23%;
            }
        }

        &-09 {
            bottom: -8%;
            width: 13%;
            right: -5%;
            @include fireWork($delay: 5s);

            @include max-400 {
                width: 33%;
                right: -36%;
            }
        }
    }

    &patten {
        position: absolute;

        &-01 {
            width: 80px;
            top: -40px;
            left: 0px;
            @include min-sm {
                width: 120px;
                top: -60px;
                left: -24px;
            }

            @include min-md {
                width: 120px;
                top: -60px;
                left: -24px;
            }
            @include min-lg {
                width: 150px;
                top: -70px;
                left: -24px;
            }
            @include min-xl {
                top: -90px;
                left: -38px;
            }
        }

        &-02 {
            width: 80px;
            top: -25px;
            right: 0px;
            @include min-sm {
                width: 120px;
                top: -30px;
                right: -24px;
            }

            @include min-md {
                width: 120px;
                top: -40px;
                right: -24px;
            }
            @include min-lg {
                width: 150px;
                top: -50px;
                right: -24px;
            }
            @include min-xl {
                top: -50px;
                right: -38px;
            }
        }
    }

    &deco {
        position: absolute;

        &-cloud-01 {
            width: 222px;
            left: -80px;
            top: 0;
        }

        &-cloud-02 {
            width: 222px;
            right: -22%;
            top: 47%;           
        }

        &-cloud-03 {
            width: 222px;
            // top: 47%;
            top: 77%;
            right: -12%;
            @include max-sm {
                top: 96%;
            }

            @include min-xl {
                right: 1rem;
            }
        }

        &-cloud-04 {
            width: 222px;
            right: 1rem;
            bottom: 1rem;

            @include min-xl {
                bottom: 15%;
            }
        }

        &-firework-01 {
            width: 135px;
            right: -20%;
            top: 40px;
        }

        &-firework-02 {
            width: 135px;
            left: -20%;
            top: 40px;
        }

        &-firework-03 {
            width: 135px;
            left: 1rem;
            // top: -6%;
            top: -25%;
        }

        &-firework-04 {
            width: 150px;
            left: -10%;
            top: -32%;
        }

        &-firework-05 {
            width: 161px;
            left: -41%;
            top: 0%;
        }

        &-firework-06 {
            width: 150px;
            right: -31%;
            top: -39%;
        }

        &-firework-07 {
            width: 107px;
            right: -47%;
            top: 22%;
        }
    }

    &method {
        line-height: 2;
        &-gift {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 1;

            &-01 {
                width: 15%;
                right: 20px;
            }

            &-02 {
                width: 30%;
                bottom: -5px;

            }

            &-03 {
                width: 35%;
                right: -10px;

            }

            &-04 {
                width: 18%;
                right: 15px;

            }
        }
    }
    &notice {
        &-content {
            height: 16rem;
            line-height: 2;
            // transition: all 3s;

            &.js-active {
                height: auto;
            }
        }

        &-arrow {
            width: 20px;
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            bottom: -30px;

            &.js-active {
                transform: translateX(-50%) rotate(180deg);
            }
        }
    }

    &polygon {
        &-bottom {
            position: absolute;
            top: calc(100% - 2px);
            left: 0;
            z-index: 1;
            @include min-sm{
                top: calc(100% - 1px);
            }
            @media (max-width:450px) {
                top: calc(100% - 3px);
            }
            @include max-400{
                top: calc(100% - 4px);
            }
            @include max-380{
                top: calc(100% - 6px);
            }
        }

        &-top {
            position: absolute;
            bottom: calc(100% - 1px);
            left: 0;
            z-index: 1;
        }
    }

    &video {
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        left: 50%;
        width: 100%;
        height: 100%;
    }
}