// vendor 第三方元件修改
.v- {
    &tab {
        flex-wrap: nowrap;
        white-space: nowrap;

        .nav-link {
            border-radius: 0;
            font-size: $font-20;
            font-weight: bold;
            color: $third;
            border: 1px solid $third;
            @include max-380{
                padding: .5rem .75rem;
            }

            &:hover {
                color: $primary;
                background: linear-gradient(91.05deg, #FDE8D5 1.01%, #CAA93F 98.66%);
            }
        }

        .nav-link.active,
        .show>.nav-link {
            color: $primary;
            background: linear-gradient(91.05deg, #FDE8D5 1.01%, #CAA93F 98.66%);
        }
    }
}