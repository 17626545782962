// ------ 圓角
@mixin Radius($radius) {
	-ms-border-radius: $radius;
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

// ------ 線性漸層
@mixin GL($args...) {
	background: -o-linear-gradient($args);
	background: -moz-linear-gradient($args);
	background: -webkit-linear-gradient($args);
	background: -ms-linear-gradient($args);
	background: linear-gradient($args);
	-pie-background: linear-gradient($args);
}

// ------ 徑向漸層
@mixin GR($inner...) {
	background: -webkit-gradient($inner);
	background: -webkit-radial-gradient($inner);
	background: -moz-radial-gradient($inner);
	background: -o-radial-gradient($inner);
}

// ------ 區塊陰影
@mixin shadow($shadow...) {
	-webkit-box-shadow: ($shadow);
	-ms-box-shadow: ($shadow);
	box-shadow: ($shadow);
}

// ------ 漸變時間差
@mixin TS($transition...) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-o-transition: $transition;
	-ms-transform: $transition;
	transition: $transition;
}

@mixin TS-d($Seconds) {
	-webkit-transition-delay: $Seconds;
	-moz-transition-delay: $Seconds;
	-o-transition-delay: $Seconds;
	transition-delay: $Seconds;
}

// ------ 變形屬性
@mixin TF($transform) {
	-moz-transform: ($transform);
	-webkit-transform: ($transform);
	-o-transform: ($transform);
	-ms-transform: ($transform);
	transform: ($transform);
}

// ------ 變形軸心
@mixin TF-o($transform-origin) {
	-moz-transform-origin: ($transform-origin);
	-webkit-transform-origin: ($transform-origin);
	-o-transform-origin: ($transform-origin);
	-ms-transform-origin: ($transform-origin);
	transform-origin: ($transform-origin);
}

// ------ 動畫
@mixin animation($animate) {
	-webkit-animation: ($animate);
	/* Safari 4+ */
	-moz-animation: ($animate);
	/* Fx 5+ */
	-o-animation: ($animate);
	/* Opera 12+ */
	animation: ($animate);
	/* IE 10+, Fx 29+ */
}

// ------ 動畫影格
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}

	@-moz-keyframes #{$name} {
		@content;
	}

	// @-ms-keyframes #{$name} {
	//    @content;
	// }
	@keyframes #{$name} {
		@content;
	}
}

// ------ 濾鏡效果
@mixin filter-display($filter) {
	-webkit-filter: ($filter);
	/* Safari 6.0 - 9.0 */
	filter: $filter;
}

// ------ 變形形狀
@mixin clip-path($clip) {
	-webkit-clip-path: $clip;
	clip-path: $clip;
}

// ------ 預設表單樣式
@mixin appearance {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

// ------ 預設表單下拉選單箭頭
@mixin selection {
	@include appearance;

	&::selection {
		display: none;
	}

	&::-moz-selection {
		display: none;
	}

	&::-ms-expand {
		display: none;
	}
}

@mixin placeholder {

	/* Chrome, Firefox, Opera, Safari 10.1+ */
	&::placeholder {
		@content;
	}

	/* Internet Explorer 10-11 */
	&:-ms-input-placeholder {
		@content;
	}

	/* Microsoft Edge */
	&::-ms-input-placeholder {
		@content;
	}
}

@mixin WM($mode) {
	-webkit-writing-mode: $mode;
	writing-mode: $mode;
}

// ------ FlexboxLayout
@mixin flexbox($display: flex, $direction: row, $wrap: nowrap) {
	@if $display=='flex' {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flexbox;
		display: flex;
	}

	@else if $display=='inline-flex' {
		display: -webkit-inline-box;
		display: -webkit-inline-flex;
		display: -moz-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}

	-webkit-flex-flow: $direction $wrap;
	-moz-flex-flow: $direction $wrap;
	-ms-flex-flow: $direction $wrap;
	-o-flex-flow: $direction $wrap;
	flex-flow: $direction $wrap;

	* {
		-ms-flex: 0 1 auto;
	}
}

@mixin justify-content($layout) {
	-webkit-justify-content: $layout;
	-moz-justify-content: $layout;
	-o-flex-justify-content: $layout;
	justify-content: $layout;

	@if $layout=='flex-start' {
		-ms-flex-pack: start;
	}

	@else if $layout=='flex-end' {
		-ms-flex-pack: end;
	}

	@else if $layout=='center' {
		-ms-flex-pack: center;
	}

	@else if $layout=='space-between' {
		-ms-flex-pack: justify;
	}

	@else if $layout=='space-around' {
		-ms-flex-pack: distribute;
	}
}

@mixin align-item($layout) {
	-webkit-align-items: $layout;
	align-items: $layout;

	@if $layout=='flex-start' {
		-webkit-box-align: start;
		-ms-flex-align: start;
	}

	@else if $layout=='flex-end' {
		-webkit-box-align: end;
		-ms-flex-align: end;
	}

	@else if $layout=='center' {
		-webkit-box-align: center;
		-ms-flex-align: center;
	}

	@else if $layout=='baseline' {
		-webkit-box-align: baseline;
		-ms-flex-align: baseline;
	}

	@else if $layout=='stretch' {
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
	}
}

//取消list style + 設定自訂list icon
@mixin custom-list-style {
	list-style-type: none;
	padding: 0;

	li {
		position: relative;
		padding: 3px 0px 3px 25px;

		&:before {
			content: '';
			position: absolute;
			top: 5px;
			left: 0px;
			width: 18px;
			height: 18px;
		}
	}
}

//添加before afteer + 基底增加postion
@mixin add-beforeAfter($position: relative) {
	position: $position;

	&::before,
	&::after {
		content: '';
		position: absolute;
		@content;
	}
}

@keyframes fireWork {
	0% {
		transform: translate(-50%, -50%) scale(0);
	}

	50% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}

	100% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 0;
	}
}

@mixin fireWork($delay: 2s) {
	animation-name: fireWork;
	animation-duration: 3s;
	animation-delay: $delay;
	animation-iteration-count: infinite;
}